export default {
  SHARED_FOOTER: {
    WHO_WE_ARE: 'Who we are?',
    LAW_PARTNER: 'Our legal partner',
    LEGAL_BACKGROUND: 'Legal background',
    CONTACT: 'Contact',
    MISSION_STATEMENT: 'Technology That Drives Account Receivable Success',
    COPYRIGHT: '© Copyright 2024 Legal Labs Kft. | 1054 Budapest, Honvéd utca 8. | Registration number: 01-09-382985',
    TOS: 'Terms & Conditions',
    PRIVACY_POLICY: 'Privacy notice',
    IMPRINT: 'Impressum',
  },
};
