import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationDialogComponent } from '../../dialogs/communication-dialog/communication-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { InitStepService } from "../init-step.service";
import { CommunicationStepData } from "../../models/communication/communication-step-data";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class CommunicationFormService extends FormService {
  communicationStepData: CommunicationStepData;
  form: FormGroup;

  get emailAddressFormControl() { return this.form?.get('emailAddressFormControl') as FormControl; }
  get descriptionFormControl() { return this.form?.get('descriptionFormControl') as FormControl; }
  get toneOfVoiceFormControl() { return this.form?.get('toneOfVoiceFormControl') as FormControl; }
  get isPaymentPlanAllowedFormControl() { return this.form?.get('isPaymentPlanAllowedFormControl') as FormControl; }

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private initStepService: InitStepService,
    private snackbar: MatSnackBar,
  ) {
    // set initial parent basic data
    super(helpersService, dialog);
    this.setText(
      'Kommunikáció',
      'Hogyan kommunikáljunk az adósoddal?'
    );
    this.urlName = 'communication';

    // set communication step data
    this.communicationStepData = this.initStepService.communicationStepData;
    this.initStepService.getCommunicationStepDataListener()
      .subscribe(async communicationStepData => {
        this.communicationStepData = communicationStepData;
      });
  }

  override buildForm() {
    this.form = this.formBuilder.group({
      emailAddressFormControl: [
        this.communicationStepData.email_address ?? '',
        [Validators.required, Validators.email]
      ],
      descriptionFormControl: [
        this.communicationStepData.description ?? '',
        [Validators.required]
      ],
      toneOfVoiceFormControl: [
        this.communicationStepData.tone_of_voice ?? 'formal',
        [Validators.required]
      ],
      isPaymentPlanAllowedFormControl: [
        this.communicationStepData.is_payment_plan_allowed ?? false,
        [Validators.required]
      ],
    });
  }

  // override
  async persistData() {
    this.updateData();

    try {
      await this.initStepService.saveCommunicationStepData();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }
  }

  updateData() {
    this.communicationStepData.email_address = this.emailAddressFormControl.value ?? '';
    this.communicationStepData.description = this.descriptionFormControl.value ?? '';
    this.communicationStepData.tone_of_voice = this.toneOfVoiceFormControl.value ?? '';
    this.communicationStepData.is_payment_plan_allowed = this.isPaymentPlanAllowedFormControl.value ?? false;
  }

  // override
  setMenuTexts() {
    this.dropdownTitle = this.emailAddressFormControl.value;
    this.dropdownItems = [
      this.emailAddressFormControl.value,
      this.descriptionFormControl.value,
    ];
  }

  // override
  openDialog() {
    return this.dialog.open(CommunicationDialogComponent, {
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });
  }
}
