import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';
import { PayeeCompletedSearchBoxComponent } from './payee-completed-search-box.component';


@NgModule({
  declarations: [
    PayeeCompletedSearchBoxComponent,
  ],
  imports: [
    CommonModule,
    PayeeIconModule,
  ],
  exports: [
    PayeeCompletedSearchBoxComponent,
  ],
})
export class PayeeCompletedSearchBoxModule { }
