import { Injectable } from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import {
  SzamlazzhuInvoiceStepData
} from 'src/app/aa-new-form/form-steps/init-step/models/szamlazzhu-invoice/szamlazzhu-invoice-step-data';
import { GetInitDataDto } from 'src/app/aa-new-form/form-steps/init-step/models/common/get-init-data-dto';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { RedirectService } from '../redirect/redirect.service';
import { filter } from 'rxjs/operators';

export type GetKsziInfoResponse = {
  invoices_recieved: number;
  invoices_pending: number;
  minutes_left: number;
};

export type SyncInvoicesResponse = Pick<GetInitDataDto, 'claims_and_legal_base_step'>

export type SzamlazzhuSsoResponse = {
  access_token: string;
  expires_in: number;
};

export type GetSsoTokenResponse = {
  sso_token: string;
};

export type GetInvoicesParams = {
  sort_by?: string;
  sort_direction?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SzamlazzhuService {

  constructor(
    private clientsService: ClientsService,
    private authService: AuthService,
    private redirectTo: RedirectService,
  ) { }

  async getKsziInfo(clientId = this.clientsService.selectedClientId): Promise<GetKsziInfoResponse> {
    if (!clientId) {
      const client = await this.clientsService.selectedClientSubject
        .pipe(filter(v => !!v))
        .toPromise()
      clientId = client.id;
    }
    const url = `${environment.baseUrl}/api/kszi/${clientId}/info`;
    const result = await axios.get<GetKsziInfoResponse>(url);
    return result.data;
  }

  async getInvoices(caseId: string, params: GetInvoicesParams): Promise<SzamlazzhuInvoiceStepData> {
    const url = `${environment.baseUrl}/api/payee-case/${caseId}/szamlazzhu/invoice`;
    const result = await axios.get<SzamlazzhuInvoiceStepData>(url, { params });
    return result.data;
  }

  /**
   * @param caseId draft case id (uuid)
   * @param invoices EVERY invoice_id associated with the case
   * @returns
   */
  async syncInvoices(caseId: string, invoice_ids: string[]): Promise<SyncInvoicesResponse> {
    const url = `${environment.baseUrl}/api/payee-case/${caseId}/szamlazzhu/sync-invoices`;
    const result = await axios.post<SyncInvoicesResponse>(
      url,
      {
        invoice_ids,
      },
    );
    return result.data;
  }

  async authenticate(ssotoken: string): Promise<void> {
    const url = `${environment.baseUrl}/api/auth/szamlazzhu/login-with-sso-token`;
    const result = await axios.post<SzamlazzhuSsoResponse>(url, { ssotoken });

    this.authService.clearTokens();
    this.authService.setPersistance('session');
    this.authService.setTokens({
      access_token: result.data.access_token,
      expires_in: result.data.expires_in,
      id_token: null,
      refresh_token: null,
    });
    this.authService.setOrigin('PLUGIN_SZAMLAZZHU');
  }

  async redirectToSzamlazzhuLogin(): Promise<void> {
    const ssotoken = await this.getSsoToken();

    const url = new URL(`${environment.szamlazzhu.url}/szamla/`);
    url.searchParams.set('action', 'ssologin');

    this.redirectTo.post({ ssotoken }, url.toString());
  }

    /**
   * According the Szamlazzhu terminology it means: get an SSO token from szamlazzhu to start login process.
   * SSO token is a short term key, with this token (and with APP_KEY) can you initialize the login.
   */
    private async getSsoToken(): Promise<string> {
      const url = `${environment.baseUrl}/api/auth/szamlazzhu/get-sso-token`;
      const result = await axios.get<GetSsoTokenResponse>(url);
      return result.data.sso_token;
    }
}
