import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app-dev-payee';

  private readonly destroy = new Subject<void>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translate.addLangs(['hu']);
    this.translate.setDefaultLang('hu');
    this.translate.use('hu');

    // this.translate.addLangs(['hu', 'en']);
    // this.translate.setDefaultLang('hu');

    // const cachedLang = localStorage.getItem('lang');
    // const browserLang = this.translate.getBrowserLang();
    // const langRegExp = /en|hu/;
    // if (cachedLang && cachedLang.match(langRegExp)) {
    //   this.translate.use(cachedLang);
    // } else if (browserLang.match(langRegExp)) {
    //   this.translate.use(browserLang);
    // } else {
    //   this.translate.use('hu');
    // }

    // this.translate.onLangChange
    //   .pipe(takeUntil(this.destroy))
    //   .subscribe({
    //     next: event => {
    //       localStorage.setItem('lang', event.lang);
    //     }
    //   });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
