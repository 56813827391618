<div class="loading-wrapper flex column vcenter hcenter">
  <img
    src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg"
    width="149px"
  />
  <lottie-player 
    src="/assets/shared/global-images/payee-loading.json" 
    background="transparent" speed="1" autoplay loop
    style="width: 320x; height: 320px; margin-top: -60px;"
  >
  </lottie-player>
  <!-- <img
    src="assets/shared/global-images/big-svgs/pacman-like.svg"
    height="95px"
    class="loading-animation"
  /> -->
</div>
