import {getEmptyRepresentative, Representative} from "./representative";
import {Address, getEmptyAddress} from "./address";

export interface RepresentativeData {
  representative: Representative,
  address: Address
}

export function getEmptyRepresentativeData(): RepresentativeData {
  return {
    representative: getEmptyRepresentative(),
    address: getEmptyAddress(),
  };
}
