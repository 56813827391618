import {Component, OnInit} from '@angular/core';
import {DebtorFormService} from '../../../services/form-services/debtor-form-service';
import {GroupTypeEnum} from "../../../models/common/group-type-enum";
import {CompanySearchAutocompleteItem} from "../../../models/common/company-search-autocomplete-item";

@Component({
  selector: 'app-debtor-data-box',
  templateUrl: './debtor-data-box.component.html',
  styleUrls: ['./debtor-data-box.component.scss']
})
export class DebtorDataBoxComponent implements OnInit {
  isDebtorEditing = false;
  isRepEditing = false;

  constructor(
    public debtorFormService: DebtorFormService,
  ) { }

  ngOnInit() {
    this.isDebtorEditing = !this.debtorFormService.form.valid;
    this.isRepEditing = this.debtorFormService.representativeFormGroup.invalid;
  }

  async onIndividualSelected(name: string) {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.INDIVIDUAL_WITHOUT_TAX_NUMBER);
    this.debtorFormService.deleteDebtor();
    this.debtorFormService.deleteRepresentative();
    this.debtorFormService.nameFormControl.patchValue(name);

    this.isDebtorEditing = true;
  }

  async onHouseSelected(name: string) {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER);
    this.debtorFormService.deleteDebtor();
    this.debtorFormService.deleteRepresentative();
    this.debtorFormService.nameFormControl.patchValue(name);

    this.isDebtorEditing = true;
    this.isRepEditing = true;
  }

  async onCompanySearchItemSelected(autocompleteItem: CompanySearchAutocompleteItem) {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.OTHER_WITH_TAX_NUMBER);
    this.debtorFormService.deleteDebtor();
    this.debtorFormService.deleteRepresentative();
    this.debtorFormService.nameFormControl.patchValue(autocompleteItem.name);
    this.debtorFormService.taxNumberFormControl.patchValue(autocompleteItem.tax_number);
    this.debtorFormService.countryFormControl.patchValue(autocompleteItem.country);
    this.debtorFormService.postcodeFormControl.patchValue(autocompleteItem.postcode);
    this.debtorFormService.settlementFormControl.patchValue(autocompleteItem.settlement);
    this.debtorFormService.streetFormControl.patchValue(autocompleteItem.street);

    this.isDebtorEditing = false;
  }

  async onCompanyRepresentativeSelected(autocompleteItem: CompanySearchAutocompleteItem) {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER);
    this.debtorFormService.deleteRepresentative();
    this.debtorFormService.repNameFormControl.patchValue(autocompleteItem.name);
    this.debtorFormService.repTaxNumberFormControl.patchValue(autocompleteItem.tax_number);
    this.debtorFormService.repCountryFormControl.patchValue(autocompleteItem.country);
    this.debtorFormService.repPostcodeFormControl.patchValue(autocompleteItem.postcode);
    this.debtorFormService.repSettlementFormControl.patchValue(autocompleteItem.settlement);
    this.debtorFormService.repStreetFormControl.patchValue(autocompleteItem.street);

    this.isRepEditing = false;
  }

  async onIndividualRepresentativeSelected(name: string) {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER);
    this.debtorFormService.deleteRepresentative();
    this.debtorFormService.repNameFormControl.patchValue(name);

    this.isRepEditing = true;
  }

  onDelete() {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.OTHER_WITH_TAX_NUMBER);
    this.debtorFormService.deleteDebtor();

    this.isDebtorEditing = true;
    this.isRepEditing = true;
  }

  onRepresentativeDelete() {
    this.debtorFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER);
    this.debtorFormService.deleteRepresentative();

    this.isRepEditing = true;
  }

  onEdit() {
    this.isDebtorEditing = true;
  }

  onRepresentativeEdit() {
    this.isRepEditing = true;
  }

  isDeletable() {
    return (this.debtorFormService.isIndividualWithoutTaxNumber
      || this.debtorFormService.isHouseDebtor
      || !this.isDebtorEditing
    ); // && !this.formHelperService.isSzamlazzhu;
  }

  isSearchDisabled() {
    return this.debtorFormService.isIndividualWithoutTaxNumber
      || this.debtorFormService.isHouseDebtor;
      //|| this.formHelperService.isSzamlazzhu;
  }
}
