import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { InvoiceDialogComponent } from '../../dialogs/invoice-dialog/invoice-dialog.component';
import { SzamazzhuInvoiceGroup, SzamlazzhuInvoiceStepData } from '../../models/szamlazzhu-invoice/szamlazzhu-invoice-step-data';
import { InitStepService } from '../init-step.service';
import { FormService } from './form-service';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';

export type SzamlazzhuInvoiceValue = {
  invoice: SzamazzhuInvoiceGroup;
  selected: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class SzamlazzhuInvoiceFormService extends FormService {
  form: FormArray;
  allSelected = false;
  get szamlazzhuInvoiceStepData(): SzamlazzhuInvoiceStepData { return this.initStepService.szamlazzhuInvoiceStepData; };

  get formValue(): SzamlazzhuInvoiceValue[] { return this.form?.value ?? []; }

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private initStepService: InitStepService,
    private szamlazzhuService: SzamlazzhuService,
  ) {
    super(helpersService, dialog);
    this.setText(
      'Számlák kiválasztása',
      'Válaszd ki a számlákat'
    );
    this.urlName = 'invoice-wizard';

    this.form = this.formBuilder.array([], [this.selectedInvoicesValidator]);
  }

  buildForm() {
    this.form.clear();

    for (const invoice of this.szamlazzhuInvoiceStepData.invoices) {
      const selected = !!this.initStepService.claimsAndLegalBaseStepData?.claims?.find(c => c.ref_id === invoice.invoice.invoice_id);

      this.form.push(
        this.formBuilder.control({
          invoice,
          selected,
        }),
      );
    }

    this.form.setValidators([this.selectedInvoicesValidator]);
  }

  getInvoiceFormControl(invoice: SzamazzhuInvoiceGroup) {
    return this.form.controls.find(c => {
      const value = c.value as SzamlazzhuInvoiceValue;
      return value.invoice.invoice.invoice_id === invoice.invoice.invoice_id;
    });
  }

  setAllInvoiceFormControlValue(selected: boolean) {
    this.allSelected = selected;

    this.form.controls.forEach((control) => {
      const value = control.value;
      value.selected = selected;

      control.patchValue(value);
    });

    this.form.updateValueAndValidity();
  }

  isInvoiceAlreadySelected(invoice: SzamazzhuInvoiceGroup): boolean {
    return !!this.getInvoiceFormControl(invoice)?.value.selected;
  }

  toggleInvoice(invoice: SzamazzhuInvoiceGroup) {
    if (this.form.disabled) {
      return;
    }

    const selected = this.isInvoiceAlreadySelected(invoice);
    if (selected) {
      this.allSelected = false;
    }
    this.getInvoiceFormControl(invoice)?.patchValue({
      invoice,
      selected: !selected,
    });

    this.form.updateValueAndValidity();
  }

  setMenuTexts() {
    this.dropdownTitle = '';
    for (const value of this.formValue.filter(v => v.selected)) {
      this.dropdownTitle += `${value.invoice.invoice.invoice_number}\n`;
    }
    this.dropdownTitle = this.dropdownTitle.replace(/\n$/, "");
    this.dropdownItems = [this.dropdownTitle];
  }

  openDialog() {
    return this.dialog.open(InvoiceDialogComponent, {
      position: {
        top: '50px',
      },
      disableClose: true,
      autoFocus: false,
    });
  }

  async persistData() {
    const response = await this.szamlazzhuService.syncInvoices(
      this.initStepService.getCaseUuid(),
      this.formValue.filter(v => v.selected).map(v => v.invoice.invoice.invoice_id),
    );

    this.initStepService.setClaimsAndLegalBaseStepData(response.claims_and_legal_base_step);
  }

  private selectedInvoicesValidator = () => {
    for (const { selected } of this.formValue) {
      if (selected) {
        return null;
      }
    }

    return { noInvoiceSelected: true };
  };
}
