import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-get-password-link-success',
  templateUrl: './get-password-link-success.component.html',
  styleUrls: ['./get-password-link-success.component.scss']
})
export class GetPasswordLinkSuccessComponent implements OnInit, OnDestroy {
  loading = false;
  emailSent = false;

  readonly email: string;
  private readonly destroy = new Subject<void>();

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.email = this.route.snapshot.queryParams.email;

    if (!this.email) {
      this.router.navigateByUrl('/login');
    }
  }

  ngOnInit(): void {
    this.translate.get('BROWSER_TITLES.PASSWORD_RESET')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async resendForgotPasswordEmail() {
    if (this.loading || this.emailSent) {
      return;
    }

    try {
      this.loading = true;
      this.emailSent = true;
      await this.authService.sendPasswordResetEmail({
        email: this.email,
      });
      this.snackbar.open(
        this.translate.instant('GET_PASSWORD_RESET_LINK_SUCCESS.EMAIL_SENT'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } finally {
      this.loading = false;
      setTimeout(() => this.emailSent = false, 15000);
    }
  }
}
