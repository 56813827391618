<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">Nyelv</div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Adja meg, hogy milyen nyelven kommunikáljunk
    {{ debtorFormService.debtorStepData?.debtor_data?.debtor?.name ?? "" }}
    adósával
  </div>

  <!--language data box-->
  <div class="top-margin-10">
    <app-language-data-box></app-language-data-box>
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="languageFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
