import { Component } from '@angular/core';
import { LanguageFormService } from '../../../services/form-services/language-form-service';

@Component({
  selector: 'app-language-data-box',
  templateUrl: './language-data-box.component.html',
  styleUrls: ['./language-data-box.component.scss']
})
export class LanguageDataBoxComponent {
  get languageOptions() { return this.languageFormService.languageOptions; }
  get languageOptionLabels() { return this.languageFormService.languageOptionLabels; }
  get languageOptionIcons() { return this.languageFormService.languageOptionIcons; }

  constructor(
    public languageFormService: LanguageFormService,
  ) {
  }

  readonly languageOptionDisplayWith = (option: any) => this.languageOptionLabels[option];
}
