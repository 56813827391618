import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-form-other-data',
  templateUrl: './form-other-data.component.html',
  styleUrls: ['./form-other-data.component.scss']
})
export class FormOtherDataComponent {
  @Input() motherNameFormControl: FormControl;
  @Input() birthPlaceFormControl: FormControl;
  @Input() birthDateFormControl: FormControl;

  max = moment().subtract(1, 'day');

  constructor() { }
}
