<div class="small-dialog-container">
  <!--success icon-->
  <div class="success-icon">
    <payee-icon [name]="'success-icon'"></payee-icon>
  </div>

  <!--title-->
  <div class="small-dialog-title top-margin-20">
    {{data.title}}
  </div>

  <!--description-->
  <div class="small-dialog-description top-margin-15">
    {{data.description}}
  </div>

  <!--button-->
  <div [mat-dialog-close] class="top-margin-30">
    <button payee-button width="wide" height="tall">
      <div class="arrow-button flex vcenter hcenter">
        <span>Tovább az ügyeimhez</span>
        <payee-icon name="arrow-right-white"></payee-icon>
      </div>
    </button>
  </div>
</div>
