import { Component, OnInit } from '@angular/core';
import { ClaimFormService } from '../../services/form-services/claim-form-service';
import { Title } from '@angular/platform-browser';
import { DebtorFormService } from "../../services/form-services/debtor-form-service";
import { environment } from '../../../../../../environments/environment';
import { InitStepService } from '../../services/init-step.service';

@Component({
  selector: 'app-claim-dialog',
  templateUrl: './claim-dialog.component.html',
  styleUrls: ['./claim-dialog.component.scss']
})
export class ClaimDialogComponent implements OnInit {
  get isAddClaimButtonVisible() {
    return !this.initStepService.isSzamlazzHu && this.claimFormService.claimFormArray.valid;
  }

  constructor(
    public claimFormService: ClaimFormService,
    public debtorFormService: DebtorFormService,
    private titleService: Title,
    private initStepService: InitStepService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Alapkövetelés - Payee');
  }

  onAddNewClaimButtonClick() {
    if (/*!this.formHelperService.isSzamlazzhu && */ this.claimFormService.claimFormArray.valid) { // szamlazzhu
      this.claimFormService.areErrorMessagesShown = false;
      this.claimFormService.addClaim();
    } else {
      this.claimFormService.areErrorMessagesShown = true;
    }
  }

  onClose() {
    this.claimFormService.openExitDialog();
  }

  async onSave() {
    await this.claimFormService.saveData();
  }

  readonly environment = environment;
}
