import { Component } from '@angular/core';
import { UserGuideService } from '../user-guide.service';
import { ActivatedRoute } from '@angular/router';

export interface CustomNavigationItem {
  name: string;
  prefixIcon?: string;
  suffixIcon?: string;
  chapterId: string;
  children?: Array<CustomNavigationItem>;
  open?: boolean;
  divider?: boolean;
}

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent {
  navList: CustomNavigationItem[] = [
    { name: '1. Bevezetés', chapterId: '1', prefixIcon: 'grid-view' },
    {
      name: '2. Termékek bemutatása', chapterId: '2', prefixIcon: 'grid-view', suffixIcon: 'down-chevron',
      children: [
        { name: '2.1 Fizetési felszólítás', chapterId: '2.1', prefixIcon: 'particulates' },
        { name: '2.2 Ügyvédi levél', chapterId: '2.2', prefixIcon: 'particulates' },
        { name: '2.3 Fizetési meghagyás', chapterId: '2.2', prefixIcon: 'particulates' }
      ],
    },
    {
      name: '3. Ügyfél felület', chapterId: '3', prefixIcon: 'grid-view', suffixIcon: 'down-chevron',
      children: [
        { name: '3.1 Bejelentkezés', chapterId: '3.1', prefixIcon: 'particulates' },
        { name: '3.1.1 Teszt oldal', chapterId: '3.1.1' },
        { name: '3.1.2 Éles oldal', chapterId: '3.1.1' },
        { name: '3.2 Számlák oldal', chapterId: '3.2', prefixIcon: 'particulates' },
        { name: '3.2.1 Számlák listázása, szűrése', chapterId: '3.2.1' },
        { name: '3.2.2 Ügy indítása', chapterId: '3.2.2' },
        { name: '3.2.3 Kontakt lista', chapterId: '3.2.3' },
        { name: '3.2.4 Befizetések rögzítése', chapterId: '3.2.4' },
        { name: '3.3 Ügyek oldal', chapterId: '3.3', prefixIcon: 'particulates' },
        { name: '3.3.1 Ügyek listázása, szűrése', chapterId: '3.3.1' },
        { name: '3.3.2 Ügy megtekintése', chapterId: '3.3.2' },
        { name: '3.3.3 Ügy megtekintése / Események', chapterId: '3.3.3' },
        { name: '3.3.4 Ügy megtekintése / Részletek', chapterId: '3.3.4' },
        { name: '3.3.5 Ügyek műveletei', chapterId: '3.3.5' },
        { name: '3.4 Ügyindítás', chapterId: '3.4', prefixIcon: 'particulates' },
        { name: '3.4.1 Adatmegadás', chapterId: '3.4.1' },
        { name: '3.4.2 Összegzés oldal', chapterId: '3.4.2' },
        { name: '3.5 Havi riport', chapterId: '3.5', prefixIcon: 'particulates' },
        { name: '3.5.1 Összegző riport', chapterId: '3.5.1' },
        { name: '3.5.2 Számla riport', chapterId: '3.5.2' },
        { name: '3.6 További funkciók', chapterId: '3.6', prefixIcon: 'particulates' },
        { name: '3.6.1 Hitelező választása', chapterId: '3.6.1' },
        { name: '3.6.2 Hitelezői fiók beállítások', chapterId: '3.6.2' },
        { name: '3.7 Kijelentkezés', chapterId: '3.7', prefixIcon: 'particulates' }
      ],
    },
    {
      name: '4. Ügyvédi felület', chapterId: '4', prefixIcon: 'grid-view', suffixIcon: 'down-chevron',
      children: [
        { name: '4.1 Bejelentkezés', chapterId: '4.1', prefixIcon: 'particulates' },
        { name: '4.1.1 Teszt oldal', chapterId: '4.1.1' },
        { name: '4.1.2 Éles oldal', chapterId: '4.1.2' },
        { name: '4.2 Összes ügy', chapterId: '4.2', prefixIcon: 'particulates' },
        { name: '4.2.1 Ügyek listázása, szűrése', chapterId: '4.2.1' },
        { name: '4.2.2 Ügy megtekintése', chapterId: '4.2.2' },
        { name: '4.2.3 Ügy megtekintése / Események', chapterId: '4.2.3' },
        { name: '4.2.4 Ügy megtekintése / Részletek', chapterId: '4.2.4' },
        { name: '4.2.5 Ügyek műveletei', chapterId: '4.2.5' },
        { name: '4.3 Kijelentkezés', chapterId: '4.3', prefixIcon: 'particulates' }
      ],
    },
    {
      name: '5. Admin felület', chapterId: '5', prefixIcon: 'grid-view', suffixIcon: 'down-chevron',
      children: [
        { name: '5.1 Bejelentkezés', chapterId: '5.1', prefixIcon: 'particulates' },
        { name: '5.2 Összes ügy', chapterId: '5.2', prefixIcon: 'particulates' },
        { name: '5.3 Hitelezők', chapterId: '5.3', prefixIcon: 'particulates' },
        { name: '5.4 Jogosultságok', chapterId: '5.4', prefixIcon: 'particulates' },
        { name: '5.5 Kijelentkezés', chapterId: '5.5', prefixIcon: 'particulates' }
      ],
    },
  ];

  selected: string[] = [this.navList[0].name];

  constructor(
    public userGuideService: UserGuideService,
    private route: ActivatedRoute,
  ) {
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      const parts = fragment.split('.');
      const index = +parts[0] - 1;
      const sub = this.navList[index]?.children?.find(l => l.chapterId === fragment);
      if (!!sub) {
        this.selected = [this.navList[index].name, sub.name];
      } else if (!!this.navList[index]) {
        this.selected = [this.navList[index].name];
      }
    }
  }

  async redirect(event: any) {
    this.selected[0] = event.selectedIndex;
    this.selected[1] = event.selectedSubIndex;
    this.userGuideService.anchorChapter(event.item.chapterId);
  }
}
