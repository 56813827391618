<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">
    {{ clientFormService.title }}
  </div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Másik hitelezői fiók választására az ügyindítás előtt van lehetőséged.
  </div>

  <!--form-->
  <div class="top-margin-30 bottom-margin-30">
    <app-client-data-box></app-client-data-box>
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="clientFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
