<app-header-app xLink="/login"></app-header-app>
<app-form-template
  title="{{ 'PASSWORD_RESET.TITLE' | translate }}"
  [formGroup]="passwordForm"
  (submit)="setNewPassword()"
>
  <payee-password-form-field width="wide" formControlName="password">
    <span payee-label>
      {{ "PASSWORD_RESET.PASSWORD_LABEL" | translate }}
    </span>
    <ng-container payee-error *ngIf="password.dirty">
      <mat-error *ngIf="password.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
      <mat-error *ngIf="password.hasError('minlength')">
        {{ "PASSWORD_RESET.PASSWORD_MINLENGTH" | translate }}
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <payee-password-form-field
    width="wide"
    formControlName="passwordConfirmation"
  >
    <span payee-label>
      {{ "PASSWORD_RESET.PASSWORD_CONFIRMATION_LABEL" | translate }}
    </span>
    <ng-container payee-error *ngIf="passwordConfirmation.dirty">
      <mat-error *ngIf="passwordConfirmation.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
      <mat-error *ngIf="passwordConfirmation.hasError('passwordsDontMatch')">
        {{ "PASSWORD_RESET.PASSWORDS_DONT_MATCH" | translate }}
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <button payee-button width="wide" [disabled]="loading">
    <ng-container *ngIf="!loading">
      {{ "PASSWORD_RESET.SUBMIT_BUTTON" | translate }}
    </ng-container>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>
