import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-szamlazzhu-sso-response',
	templateUrl: './szamlazzhu-sso-response.component.html',
	styleUrls: ['./szamlazzhu-sso-response.component.scss']
})
export class SzamlazzhuSsoResponseComponent implements OnInit {
	private token: string;
	private error: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private szamlazzhuService: SzamlazzhuService,
	) { }

	async ngOnInit() {
		// @todo @ivan - majd boviteni a production mode-al!
		const params = this.route.snapshot.queryParams;
		this.token = params.ssotoken;
		this.error = params.error;

		if (this.error) {
			return this.router.navigate(['sso/szamlazzhu/error'], { state: { error: this.error } });
		}

		if (this.token) {
			try {
				await this.szamlazzhuService.authenticate(this.token);
        return this.router.navigateByUrl(environment.afterLoginPath)
			} catch (e) {
				console.error('Error while logging in with szamlazzhu ssotoken', e);
				return this.router.navigate(['sso/szamlazzhu/error']);
			}
		} else {
			console.error('No token');
			return this.router.navigate(['sso/szamlazzhu/error']);
		}
	}
}
