import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type CountryItem = {
  iso: string;
  name: string;
};

export type PartyTypeItem = {
  id: string;
  label: string;
  type: 'ind' | 'org';
};

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  async getPartyTypes(): Promise<PartyTypeItem[]> {
    const url = `${environment.baseUrl}/api/enum/party-types`;
    const result = await axios.get(url);
    return result.data.party_types;
  }

  async getCountries(): Promise<CountryItem[]> {
    // For now only HU is allowed
    return [
      {
        iso: 'HU',
        name: 'Magyarország',
      }
    ];
  }
}
