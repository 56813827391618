<app-header-app xLink="/signup">
  <div class="flex gdpr-box">
    <payee-icon name="gdpr"></payee-icon>
  </div>
</app-header-app>
<app-form-template
  title="{{ 'SIGNUP_FINALIZATION.COMPLETE_REGISTRATION' | translate }}"
  [formGroup]="registerForm"
  (submit)="finalizeSignup()"
>
  <div class="input-label" color="black">
    {{ "SIGNUP_FINALIZATION.REGISTER_WITH_EMAIL" | translate }}&nbsp;
    <strong>{{ email }}</strong>
  </div>
  <payee-form-field width="wide">
    <span payee-label>{{ "SIGNUP_FINALIZATION.LAST_NAME" | translate }}</span>
    <!-- TODO: tooltip -->
    <input payee-input formControlName="lastName" />
    <ng-container payee-error *ngIf="lastName.dirty">
      <mat-error *ngIf="lastName.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
    </ng-container>
  </payee-form-field>

  <payee-form-field width="wide">
    <span payee-label>{{ "SIGNUP_FINALIZATION.FIRST_NAME" | translate }}</span>
    <!-- TODO: tooltip -->
    <input payee-input formControlName="firstName" />
    <ng-container payee-error *ngIf="firstName.dirty">
      <mat-error *ngIf="firstName.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
    </ng-container>
  </payee-form-field>

  <payee-password-form-field width="wide" formControlName="password">
    <span payee-label>{{ "SIGNUP_FINALIZATION.PASSWORD" | translate }}</span>
    <ng-container payee-error *ngIf="password.dirty">
      <mat-error *ngIf="password.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
      <mat-error *ngIf="password.hasError('minlength')">
        {{ "SIGNUP_FINALIZATION.PASSWORD_MIN_LENGTH" | translate }}
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <app-custom-checkbox formControlName="newsletter">
    <div class="input-label-small" color="black">
      {{ "SIGNUP_FINALIZATION.NEWSLETTER_OPT_IN" | translate }}
    </div>
  </app-custom-checkbox>

  <button payee-button width="wide" height="tall" [disabled]="loading">
    <div *ngIf="!loading" class="flex vcenter hcenter">
      <span>{{ "SIGNUP_FINALIZATION.CONTINUE_TO_ACCOUNT" | translate }}</span>
      <payee-icon name="arrow-right-white"></payee-icon>
    </div>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>
