import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomCheckboxComponent
    },
  ]
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  checked = false;

  touched = false;
  disabled = false;

  @Input() increment: number;

  onChange = (checked: boolean) => { };

  onTouched = () => { };

  onCheck() {
    this.markAsTouched();
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onChange(this.checked);
    }
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
