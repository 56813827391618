import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { BankAccount, Claim, Client, Debtor, ProductType, Summary } from "./form.resources";
import { debounceTime, distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import moment, { isMoment, Moment } from "moment";
import { FormService } from "./form.service";
import { AxiosError } from "axios";
import { errorResponse } from "src/types/error-response";
import * as ibantools from 'ibantools';
import { merge } from "rxjs";

export type FormName =
  'debtor'
  | 'claims'
  | 'claim'
  | 'client'
  | 'bank-account'
  | 'summary';

export class FormFactory {
  constructor(
    private formService: FormService,
    private fb: FormBuilder,
    private data?: unknown,
  ) { }

  getForm(step: FormName): AbstractControl {
    switch (step) {
      case 'debtor': return this.getDebtorForm();
      case 'claims': return this.getClaimsForm();
      case 'claim': return this.getClaimForm();
      case 'client': return this.getClientForm();
      case 'bank-account': return this.getBankAccountForm();
      case 'summary': return this.getSummaryForm();
    }
  }

  private getClaimsForm(): FormArray {
    const data = this.data as Claim[];

    const form = this.fb.array(
      data.map(claim => this.getClaimForm(claim)),
      [Validators.required, this.totalOriginalAmountValidator],
    );

    return form;
  }

  private getClaimForm(claim?: Claim): FormGroup {
    const maxDueDate = moment().subtract(1, 'day').endOf('day');
    const form = this.fb.group({
      file: [claim?.file, Validators.required],
      original_amount: [
        claim?.original_amount,
        [
          Validators.required,
          this.numberValidator,
          Validators.min(1),
          Validators.max(30_000_000),
        ],
      ],
      due_date_at: [
        claim?.due_date_at ? moment(claim.due_date_at) : moment().subtract(1, 'day'),
        [Validators.required, this.maxDateValidator(maxDueDate)],
      ],

      // data for convenience
      id: claim?.id,
      is_deletable: claim?.is_deletable ?? true,
      is_editable: claim?.is_editable ?? true,
    });

    const original_amount = form.get('original_amount');
    original_amount.valueChanges
      .pipe(
        debounceTime(0),
        startWith(original_amount.value),
      )
      .subscribe({
        next: value => {
          if (typeof value === 'number') {
            if (value === 0 || Number.isNaN(value)) {
              original_amount.setValue(null);
            }
            return;
          }
          if (!value) {
            return;
          }

          if (typeof value === 'string') {
            original_amount.setValue(+value.replace(/\D/g, ''));
          } else {
            console.warn('Unexpected type');
            original_amount.setValue(null);
          }
        },
      });

    form.statusChanges
      .pipe(
        debounceTime(1000),
        filter(() => form.valid),
      )
      .subscribe({
        next: async () => {
          try {
            const value = form.value as Claim;
            const due_date_at = isMoment(value.due_date_at)
              ? value.due_date_at.format('YYYY-MM-DD')
              : value.due_date_at;

            const file = value.file instanceof File ? value.file : undefined;
            const params: Claim = {
              ...value,
              due_date_at,
              file,
            };

            const claim = await this.formService.upsertClaim(params);
            if (!value.id) {
              form.patchValue(
                {
                  id: claim.id,
                },
                {
                  emitEvent: false,
                  onlySelf: true,
                }
              );
            }
          } catch (error) {
            console.error('Error while saving claim', error);
            if (error instanceof AxiosError) {
              if (error.response?.status === 413) {
                form.markAsDirty();
                form.get('file').setErrors({
                  unknown: 'Túl nagy a fájl',
                });
                return;
              } else if (error.response?.status === 422) {
                const errorData = errorResponse.safeParse(error.response?.data);
                if (errorData.success) {
                  Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                    form.get(path).setErrors({
                      unknown: errors[0],
                    });
                  });
                }
              }
            }
          }
        }
      });

    return form;
  }

  private getDebtorForm(): FormGroup {
    const data = this.data as Debtor;
    const rep = data.representative;

    const form = this.fb.group({
      debtor_type: [data.debtor_type, [Validators.required, Validators.pattern(/ind|org/)]],
      name: [data.name, [Validators.required, Validators.maxLength(255)]],
      party_type_id: [data.party_type_id, Validators.required],
      // @todo required if org and not house
      tax_number: data.tax_number,
      address: this.fb.group({
        country_iso: [data.address.country_iso || 'HU', Validators.required],
        postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
        settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
        street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
      }),
      // @todo possibly array
      representative: this.fb.group({
        name: [rep?.name, [Validators.required, Validators.maxLength(255)]],
        address: this.fb.group({
          country_iso: [rep?.address?.country_iso || 'HU', [Validators.required]],
          postcode: [rep?.address?.postcode, [Validators.required, Validators.maxLength(255)]],
          settlement: [rep?.address?.settlement, [Validators.required, Validators.maxLength(255)]],
          street: [rep?.address?.street, [Validators.required, Validators.maxLength(255)]],
        }),
      }),
    });

    const representative = form.get('representative');
    const party_type_id = form.get('party_type_id');
    const tax_number = form.get('tax_number');

    form.valueChanges
      .pipe(startWith(data))
      .subscribe({
        next: (v: Debtor) => {
          if (v.debtor_type === 'ind') {
            representative.disable({ onlySelf: true, emitEvent: false });
            party_type_id.disable({ onlySelf: true, emitEvent: false });
            tax_number.disable({ onlySelf: true, emitEvent: false });
          } else {
            representative.enable({ onlySelf: true, emitEvent: false });
            party_type_id.enable({ onlySelf: true, emitEvent: false });
            tax_number.enable({ onlySelf: true, emitEvent: false });
          }
          form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        },
      });

    form.statusChanges
      .pipe(
        debounceTime(1000),
        filter(() => form.valid),
      )
      .subscribe({
        next: async () => {
          try {
            await this.formService.upsertDebtor(form.value);
          } catch (error) {
            console.error('Error while saving debtor', error);
            if (error instanceof AxiosError) {
              if (error.response?.status === 422) {
                const errorData = errorResponse.safeParse(error.response?.data);
                if (errorData.success) {
                  Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                    form.get(path).setErrors({
                      unknown: errors[0],
                    });
                  });
                }
              }
            }
          }
        }
      });

    return form;
  }

  private getClientForm(): FormGroup {
    const data = this.data as Client;
    const rep = data.representative;

    const form = this.fb.group({
      client_type: [data.client_type, [Validators.required, Validators.pattern(/org|house/)]],
      name: [data.name, [Validators.required, Validators.maxLength(255)]],
      party_type_id: [data.party_type_id, Validators.required],
      // @todo required if org and not house
      tax_number: data.tax_number,
      address: this.fb.group({
        country_iso: [data.address.country_iso || 'HU', Validators.required],
        postcode: [data.address.postcode, [Validators.required, Validators.maxLength(255)]],
        settlement: [data.address.settlement, [Validators.required, Validators.maxLength(255)]],
        street: [data.address.street, [Validators.required, Validators.maxLength(255)]],
      }),
      birth_date: [
        moment(data.birth_date).isValid()
          ? moment(data.birth_date)
          : moment(),
        [
          Validators.required,
          this.maxDateValidator(moment().subtract(1, 'day').endOf('day'))
        ],
      ],
      birth_place: [data.birth_place, [Validators.required, Validators.maxLength(255)]],
      mother_name: [data.mother_name, [Validators.required, Validators.maxLength(255)]],
      // @todo possibly array
      representative: this.fb.group({
        name: [rep?.name, [Validators.required, Validators.maxLength(255)]],
        address: this.fb.group({
          country_iso: [rep?.address?.country_iso || 'HU', [Validators.required]],
          postcode: [rep?.address?.postcode, [Validators.required, Validators.maxLength(255)]],
          settlement: [rep?.address?.settlement, [Validators.required, Validators.maxLength(255)]],
          street: [rep?.address?.street, [Validators.required, Validators.maxLength(255)]],
        }),
      }),
    });

    const party_type_id = form.get('party_type_id');
    const representative = form.get('representative');
    const birth_date = form.get('birth_date');
    const birth_place = form.get('birth_place');
    const mother_name = form.get('mother_name');

    form.valueChanges
      .pipe(startWith(data))
      .subscribe({
        next: (v: Client) => {
          // PartyType::SOLE_ENTREPRENEUR_ID
          if (v.party_type_id === '2-a') {
            representative.disable({ onlySelf: true, emitEvent: false });
            birth_date.enable({ onlySelf: true, emitEvent: false });
            birth_place.enable({ onlySelf: true, emitEvent: false });
            mother_name.enable({ onlySelf: true, emitEvent: false });
          } else {
            representative.enable({ onlySelf: true, emitEvent: false });
            birth_date.disable({ onlySelf: true, emitEvent: false });
            birth_place.disable({ onlySelf: true, emitEvent: false });
            mother_name.disable({ onlySelf: true, emitEvent: false });
          }

          if (v.client_type === 'org') {
            party_type_id.enable({ onlySelf: true, emitEvent: false });
          } else {
            party_type_id.disable({ onlySelf: true, emitEvent: false });
          }
          // Update validity to avoid errors
          form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        },
      });

    form.statusChanges
      .pipe(
        debounceTime(1000),
        filter(() => form.valid),
      )
      .subscribe({
        next: async () => {
          try {
            const value = form.value as Client;

            const birth_date = isMoment(value.birth_date)
              ? value.birth_date.format('YYYY-MM-DD')
              : value.birth_date;

            value.birth_date = birth_date;

            await this.formService.updateClient(value);
          } catch (error) {
            console.error('Error while saving client', error);
            if (error instanceof AxiosError) {
              if (error.response?.status === 422) {
                const errorData = errorResponse.safeParse(error.response?.data);
                if (errorData.success) {
                  Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                    form.get(path).setErrors({
                      unknown: errors[0],
                    });
                  });
                }
              }
            }
          }
        }
      });

    return form;
  }

  private getBankAccountForm(): FormGroup {
    const data = this.data as BankAccount;

    const form = this.fb.group({
      account_holder_name: [data.account_holder_name, [Validators.required, Validators.maxLength(255)]],
      account_number: [data.iban || data.account_number, [Validators.required, Validators.maxLength(255)]],
      bic: [data.bic, [Validators.required, Validators.maxLength(255)]],
    });

    form.statusChanges
      .pipe(
        debounceTime(1000),
        filter(() => form.valid),
      )
      .subscribe({
        next: async () => {
          try {
            const value = form.value as Omit<BankAccount, 'iban'>;

            const electronicFormatted = ibantools.electronicFormatIBAN(value.account_number);
            const iban = ibantools.isValidIBAN(electronicFormatted) ? electronicFormatted : null;
            const bban = !!iban ? null : electronicFormatted.replace('-', '')
              .padEnd(24, '0')
              .replace(/(\d{8})(\d{8})(\d{8})/, '$1-$2-$3');
            const params: BankAccount = {
              ...value,
              iban,
              account_number: bban,
            };

            await this.formService.upsertBankAccount(params);

            if (bban) {
              form.get('account_number').patchValue(bban, { emitEvent: false });
            }
          } catch (error) {
            console.error('Error while saving bank account', error);
            if (error instanceof AxiosError) {
              if (error.response?.status === 422) {
                const errorData = errorResponse.safeParse(error.response?.data);
                if (errorData.success) {
                  Object.entries(errorData.data.errors).forEach(([path, errors]) => {
                    form.get(path).setErrors({
                      unknown: errors[0],
                    });
                  });
                }
              }
            }
          }
        }
      });

    return form;
  }

  private getSummaryForm(): FormGroup {
    const data = this.data as Summary;

    const form = this.fb.group({
      product_type: data.product.type,
      debtor: this.fb.group({
        email: data.debtor.email,
      }),
      payee_case: this.fb.group({
        is_start_case_price_claim_added: { value: data.payee_case.is_start_case_price_claim_added, disabled: true },
        is_interest_added: data.payee_case.is_interest_added,
        is_flat_rate_cost_claim_added: data.payee_case.is_flat_rate_cost_claim_added,
      }),
    });

    const debtorEmail = form.get('debtor.email');

    const defaultForms = data.product.forms[data.product.type];
    debtorEmail.setValidators(defaultForms.includes('debtor_form')
      ? [Validators.required, Validators.email]
      : []);
    debtorEmail.updateValueAndValidity();
    form.get('payee_case.is_flat_rate_cost_claim_added').setValidators(defaultForms.includes('flat_rate_cost_added_form')
      ? [Validators.required]
      : []);
    form.get('payee_case.is_flat_rate_cost_claim_added').updateValueAndValidity();
    form.get('payee_case.is_interest_added').setValidators(defaultForms.includes('interest_added_form')
      ? [Validators.required]
      : []);
    form.get('payee_case.is_interest_added').updateValueAndValidity();

    this.formService.saving
      .pipe(distinctUntilChanged(), debounceTime(0))
      .subscribe({
        next: saving => {
          if (saving > 0) {
            form.disable({ emitEvent: false });
          } else {
            form.enable({ emitEvent: false });
          }
        },
      });

    merge(
      form.get('payee_case.is_flat_rate_cost_claim_added').valueChanges.pipe(map(v => ({ is_flat_rate_cost_claim_added: v }))),
      form.get('payee_case.is_interest_added').valueChanges.pipe(map(v => ({ is_interest_added: v }))),
    )
      .subscribe({
        next: async (v) => {
          try {
            await this.formService.selectOptionalClaims({
              is_flat_rate_cost_claim_added: form.value.payee_case.is_flat_rate_cost_claim_added,
              is_interest_added: form.value.payee_case.is_interest_added,
              ...v,
            });
          } catch (error) {
            console.error('Error while saving optional claims', error);
          }
        },
      });

    form.get('product_type').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({
        next: async (product_type: ProductType) => {
          try {
            await this.formService.changeProduct(product_type);
          } catch (error) {
            console.error('Error while changing product', error);
          }

          const forms = data.product.forms[product_type];
          debtorEmail.setValidators(forms.includes('debtor_form')
            ? [Validators.required]
            : []);
          debtorEmail.updateValueAndValidity({ emitEvent: false });
          form.get('payee_case.is_flat_rate_cost_claim_added').setValidators(forms.includes('flat_rate_cost_added_form')
            ? [Validators.required]
            : []);
          form.get('payee_case.is_flat_rate_cost_claim_added').updateValueAndValidity({ emitEvent: false });
          form.get('payee_case.is_interest_added').setValidators(forms.includes('interest_added_form')
            ? [Validators.required]
            : []);
          form.get('payee_case.is_interest_added').updateValueAndValidity({ emitEvent: false });
        }
      });

    debtorEmail.valueChanges
      .pipe(
        debounceTime(1000),
        filter(() => debtorEmail.valid),
      )
      .subscribe({
        next: async email => {
          try {
            await this.formService.upsertDebtorContacts(email);
          } catch (error) {
            console.error('Error while upserting debtor contacts', error);
            if (error instanceof AxiosError) {
              const errorData = errorResponse.safeParse(error);
              if (errorData.success) {
                debtorEmail.setErrors({
                  unknown: errorData.data.message,
                });
              }
            }
          }
        }
      });

    return form;
  }

  private maxDateValidator(maxDate: Moment): ValidatorFn {
    return control => {
      let value = control.value as Moment | undefined;
      if (!value) {
        return null;
      }

      if (!isMoment(value)) {
        value = moment(value);
        if (!value.isValid()) {
          return {
            maxdate: {
              expected: maxDate.format('YYYY-MM-DD'),
              actual: 'invalid-date',
            },
          };
        }
      }

      if (value.isBefore(maxDate)) {
        return null;
      }
      return {
        maxdate: {
          expected: maxDate.format('YYYY-MM-DD'),
          actual: value.format('YYYY-MM-DD'),
        },
      };
    };
  }

  private readonly numberValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    if (!value || typeof value === 'number') {
      return null;
    }

    if (typeof value === 'string' && /^\d+$/.test(value)) {
      return null;
    }

    return {
      notnumber: true,
    };
  };

  private readonly totalOriginalAmountValidator: ValidatorFn = (arr: FormArray) => {
    const original_amount = arr.controls.reduce((sum, claim) => {
      return parseInt(claim.value.original_amount) + sum;
    }, 0);
    if (original_amount > 30_000_000) {
      return {
        max: {
          max: 30_000_000,
          actual: original_amount,
        },
      };
    }
    return null;
  };
}