import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-form-selector-header',
  templateUrl: './form-selector-header.component.html',
  styleUrls: ['./form-selector-header.component.scss']
})
export class FormSelectorHeaderComponent {

  constructor(private router: Router) { }

  async onLogoClick() {
    await this.router.navigate(['/user/cases']);
  }

  async onExitButtonClick() {
    await this.router.navigate(['/user/cases']);
  }

  readonly environment = environment;
}
