<form [formGroup]="form" [class.valid]="form.valid">
  <div class="title flex vcenter">
    <span *ngIf="file.value" class="grow1">{{ index + 1 }}. követelés</span>
    <span *ngIf="!file.value" class="grow1" color="blue-king">
      Töltsön fel egy számlát vagy egyenlegközlőt
    </span>
    <button
      *ngIf="isDeleteable"
      payee-icon-button
      color="white"
      (click)="deleteClaim()"
    >
      <payee-icon name="trash-red"></payee-icon>
    </button>
  </div>
  <section *ngIf="!file.value">
    <payee-dropzone
      class="file-upload"
      title=""
      description="Kérjük, minden számlához külön követelést adjon hozzá"
      [multiple]="false"
      (dropped)="fileDropped($event)"
    ></payee-dropzone>
  </section>
  <section *ngIf="file.value" class="flex vcenter uploaded-file">
    <payee-icon name="upload-paper" class="file-icon"></payee-icon>
    <a
      *ngIf="isFileUploaded"
      class="file-name"
      [href]="file.value?.storage_url"
      target="_blank"
    >
      {{ file.value?.file_name }}
    </a>
    <span *ngIf="!isFileUploaded" class="file-name">
      {{ file.value?.name }}
    </span>
    <button payee-icon-button color="white" (click)="file.patchValue(null)">
      <payee-icon name="trash-red"></payee-icon>
    </button>
  </section>
  <div *ngIf="file.invalid && file.dirty" color="alert-red">
    <span *ngIf="file.hasError('required')">Fájl feltöltése kötelező!</span>
    <span *ngIf="file.hasError('unknown')">{{ file.errors.unknown }}</span>
  </div>

  <div class="top-margin-15">
    <payee-form-field>
      <span payee-label>Követelés összege</span>
      <input payee-input formControlName="original_amount" />
      <ng-container
        payee-error
        *ngIf="original_amount.invalid && original_amount.dirty"
      >
        <span *ngIf="original_amount.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="original_amount.hasError('max')">
          <!-- TODO: currency -->
          A követelések összege nem haladhatja meg a
          {{
            original_amount.errors.max.max | number : "1.0" : "hu"
          }}
          forintot
        </span>
        <span *ngIf="original_amount.hasError('unknown')">{{ original_amount.errors.unknown }}</span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field>
      <span payee-label>Fizetési határidő</span>
      <payee-datepicker
        formControlName="due_date_at"
        [max]="maxDueDate"
      ></payee-datepicker>
      <ng-container
        payee-error
        *ngIf="due_date_at.invalid && due_date_at.dirty"
      >
        <span *ngIf="due_date_at.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="due_date_at.hasError('max')">
          A fizetési határidő még nem járt le!
        </span>
        <span *ngIf="due_date_at.hasError('unknown')">{{ due_date_at.errors.unknown }}</span>
      </ng-container>
    </payee-form-field>
  </div>
</form>
