import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer.component';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';

@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    CommonModule,
    PayeeIconModule,
    TranslateModule,
  ],
  exports: [
    FooterComponent,
  ],
})
export class FooterModule { }
