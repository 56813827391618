import {Debtor, getEmptyDebtor} from "./debtor";
import {Address, getEmptyAddress} from "../common/address";

export interface DebtorData {
  debtor: Debtor,
  address: Address
}

export function getEmptyDebtorData(): DebtorData {
  return {
    debtor: getEmptyDebtor(),
    address: getEmptyAddress()
  }
}
