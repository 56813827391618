<!--country-->
<div>
  <payee-dropdown
    [formControl]="countryFormControl"
    height="tall"
    width="widest"
  >
    <!--styling-->
    <ng-template #menuDisplayTemplate let-selected>
      <div class="flex vcenter" *ngIf="selected">
        <span class="grow1">{{ selected }}</span>
        <payee-icon class="flag-margin" [name]="'hu-flag-circle'"></payee-icon>
      </div>
    </ng-template>
    <!--error messages-->
    <ng-container payee-error *ngIf="countryFormControl.dirty">
      <div *ngIf="countryFormControl.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </div>
    </ng-container>
  </payee-dropdown>
</div>

<!--account holder name-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <!--input-->
    <input
      payee-input
      [formControl]="accountHolderNameFormControl"
      [placeholder]="'BANK_ACCOUNT_FORM.ACCOUNT_HOLDER_NAME' | translate"
    />
    <!--error messages-->
    <ng-container payee-error *ngIf="accountHolderNameFormControl?.dirty">
      <div *ngIf="accountHolderNameFormControl.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--bank account number-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <!--input-->
    <input
      payee-input
      [formControl]="accountNumberFormControl"
      [placeholder]="'BANK_ACCOUNT_FORM.BANK_ACCOUNT_NUMBER' | translate"
    />
    <!--error messages-->
    <ng-container payee-error *ngIf="accountNumberFormControl?.dirty">
      <div *ngIf="accountNumberFormControl.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </div>
      <div
        *ngIf="
          accountNumberFormControl.hasError('pattern') &&
          accountNumberFormControl.value
        "
      >
        {{ "ERROR_MESSAGES.INVALID_FORMAT" | translate }}
      </div>
    </ng-container>
  </payee-form-field>
</div>
