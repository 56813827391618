<div class="company-info-box-container">
  <!--tick icon-->
  <div class="left-column">
    <payee-icon [name]="'checkmark-darker-green-circle'"></payee-icon>
  </div>
  <!--description-->
  <div class="middle-column">
    <div class="local-main-title">{{ name }}</div>
    <div class="local-description top-margin-5">{{ address }}</div>
    <div class="local-description top-margin-5">{{ taxNumber }}</div>
    <div class="local-description top-margin-5">{{ additionalData }}</div>
    <div
      *ngIf="!disabled && isEditable"
      class="blue-cta top-margin-5"
      (click)="onEditButtonClick()"
    >
      Szerkesztés
    </div>
  </div>
  <!--delete icon-->
  <div class="right-column">
    <payee-icon
      *ngIf="!disabled && isDeletable"
      [name]="'trash-red'"
      class="trash-icon"
      (click)="onDeleteButtonClick()"
    ></payee-icon>
  </div>
</div>
