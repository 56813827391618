<app-header-empty></app-header-empty>
<app-form-template title="{{ 'REGISTER.REGISTER_FREE' | translate }}" [formGroup]="registerForm" [submit]="register">
  <div class="form-content flex column">
    <payee-form-field width="wide">
      <span payee-label>{{ 'REGISTER.EMAIL_PROMPT' | translate }}</span>
      <input payee-input formControlName="email">
      <ng-container payee-error *ngIf="email.dirty">
        <mat-error *ngIf="email.hasError('email')">
          {{ 'ERROR_MESSAGES.INVALID_EMAIL' | translate }}
        </mat-error>
        <mat-error *ngIf="email.hasError('required')">
          {{ 'ERROR_MESSAGES.FIELD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="email.hasError('used')">
          {{ 'REGISTER.EMAIL_ALREADY_REGISTERED' | translate }}
        </mat-error>
      </ng-container>
    </payee-form-field>

    <app-custom-checkbox formControlName="ppAndAszf">
      <div class="input-label-small">
        <span color="black">{{ 'REGISTER.TERMS_CONDITIONS_AGREE_PREFIX' | translate }}&nbsp;</span>
        <a href="https://www.payee.tech/legal/terms-and-conditions" target="_blank" color="black">
          <u>{{ 'REGISTER.TERMS_CONDITIONS' | translate }}</u>
        </a>
        <span color="black">{{ 'REGISTER.AND' | translate }}&nbsp;</span>
        <a href="https://www.payee.tech/legal/privacy-policy" target="_blank" color="black">
          <u>{{ 'REGISTER.PRIVACY_POLICY' | translate }}</u>&nbsp;
        </a>
      </div>
    </app-custom-checkbox>

    <mat-error *ngIf="ppAndAszf.dirty && ppAndAszf.hasError('required')">
      {{ 'REGISTER.TERMS_CONDITIONS_AGREE_ERROR' | translate }}
    </mat-error>

    <mat-error *ngIf="registerForm.hasError('unknown')">
      {{ registerForm.getError('unknown') }}
    </mat-error>

    <button
      payee-button
      width="wide"
      height="tall"
      [disabled]="loading"
    >
      <div *ngIf="!loading" class="flex vcenter hcenter">
        <span>{{ 'REGISTER.CONTINUE' | translate }}</span>
        <payee-icon name="arrow-right-white" [height]="'16px'"></payee-icon>
      </div>
      <div class="bw-theme flex hcenter" *ngIf="loading">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
        </mat-progress-spinner>
      </div>
    </button>

    <div class="flex vcenter signup-separator">
      <payee-horizontal-line></payee-horizontal-line>
      <div class="input-label" color="grey-medium" style="align-self: auto; font-size: 14px;">
        {{ 'REGISTER.OR' | translate }}
      </div>
      <payee-horizontal-line></payee-horizontal-line>
    </div>

    <a routerLink="/sso/szamlazzhu">
      <button type="button" payee-button width="wide" color="white" [isBorderButton]="true">
        {{ 'REGISTER.REGISTER_WITH_SZAMLAZZ' | translate }}
      </button>
    </a>

    <payee-horizontal-line></payee-horizontal-line>

    <div class="flex hcenter">
      <span class="input-label" color="grey-dark">{{ 'REGISTER.ALREADY_REGISTERED' | translate }}</span>&nbsp;
      <a color="blue-payee" routerLink="/login">{{ 'REGISTER.LOGIN' | translate }}</a>
    </div>
  </div>
</app-form-template>
