import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimsFormComponent } from './claims-form.component';
import { ClaimFormComponent } from './claim-form/claim-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeDropzoneModule } from 'src/app/shared/payee-dropzone/payee-dropzone.module';
import { MatPaginatorModule } from '@angular/material/paginator';



@NgModule({
  declarations: [
    ClaimsFormComponent,
    ClaimFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PayeeButtonsModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    PayeeDropzoneModule,
    MatPaginatorModule,
  ]
})
export class ClaimsFormModule { }
