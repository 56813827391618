<div class="small-dialog-container">
  <!--success icon-->
  <payee-icon name="success-icon" class="success-icon" width="80" height="80"></payee-icon>

  <!--title-->
  <div class="small-dialog-title top-margin-20">
    Elküldtük a díjbekérőt és az utalási adatokat emailben
  </div>

  <!--description-->
  <div class="small-dialog-description top-margin-15">
    Miután átutaltad az összeget, 24 órán belül feldolgozzuk a befizetésedet és
    elindul az ügyed.
  </div>

  <form [formGroup]="form" class="flex column copy-form top-margin-10">
    <payee-form-field width="wide">
      <span payee-label>Utalandó összeg</span>
      <span payee-suffix style="margin-right: 30px">{{ data.currency_iso }}</span>
      <payee-copy-form-field
        formControlName="transfer_amount"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>Kedvezményezett</span>
      <payee-copy-form-field
        formControlName="account_holder_name"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>Számlaszám</span>
      <payee-copy-form-field
        formControlName="account_number"
      ></payee-copy-form-field>
    </payee-form-field>
    <payee-form-field width="wide">
      <span payee-label>Közlemény</span>
      <payee-copy-form-field
        formControlName="payee_case_reference_id"
      ></payee-copy-form-field>
    </payee-form-field>
  </form>

  <button
    mat-dialog-close
    class="top-margin-30"
    payee-button
    width="wide"
    height="tall"
  >
    <div class="arrow-button flex vcenter hcenter">
      <span>Tovább az ügyeimhez</span>
      <payee-icon name="arrow-right-white"></payee-icon>
    </div>
  </button>
</div>
