import {Component} from '@angular/core';
import {BankAccountFormService} from '../../../services/form-services/bank-account-form-service';

@Component({
  selector: 'app-bank-account-data-box',
  templateUrl: './bank-account-data-box.component.html',
  styleUrls: ['./bank-account-data-box.component.scss']
})
export class BankAccountDataBoxComponent {
  constructor(
    public bankAccountFormService: BankAccountFormService,
  ) { }
}
