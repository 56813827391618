<div class="small-dialog-container">
  <!--success icon-->
  <div class="success-icon">
    <payee-icon [name]="'success-icon'"></payee-icon>
  </div>

  <!--title-->
  <div class="small-dialog-title top-margin-20">
    Elküldtük a díjbekérőt és az utalási adatokat emailben
  </div>

  <!--description-->
  <div class="small-dialog-description top-margin-15">
    Miután átutaltad az összeget, 24 órán belül feldolgozzuk a befizetésedet és elindul az ügyed.
  </div>

  <!--amount of money to be transferred-->
  <div class="transfer-data-textbox top-margin-30">
    <fieldset class="text-wrapper">
      <legend>Utalandó összeg</legend>
      <span>{{totalFees | number:'1.0':'hu'}} HUF</span>
    </fieldset>
    <button class="copy-icon-wrapper"
            #tooltip1="matTooltip"
            matTooltip="Másolva"
            [matTooltipDisabled]="copyTooltip1Disabled"
            [matTooltipPosition]="'before'"
            (click)="copyText(totalFees.toString(), 1, tooltip1)">
      <payee-icon [name]="'copy-white'" width="24px"></payee-icon>
    </button>
  </div>

  <!--beneficiary name-->
  <div class="transfer-data-textbox top-margin-20">
    <fieldset class="text-wrapper">
      <legend>Kedvezményezett</legend>
      <span>Legal Labs Kft.</span>
    </fieldset>
    <button class="copy-icon-wrapper"
            #tooltip2="matTooltip"
            matTooltip="Másolva"
            [matTooltipDisabled]="copyTooltip2Disabled"
            [matTooltipPosition]="'before'"
            (click)="copyText('Legal Labs Kft.', 2, tooltip2)">
      <payee-icon [name]="'copy-white'" width="24px"></payee-icon>
    </button>
  </div>

  <!--bank account number-->
  <div class="transfer-data-textbox top-margin-20">
    <fieldset class="text-wrapper">
      <legend>Számlaszám</legend>
      <span>12600016-19259526-84463815</span>
    </fieldset>
    <button class="copy-icon-wrapper"
            #tooltip3="matTooltip"
            matTooltip="Másolva"
            [matTooltipDisabled]="copyTooltip3Disabled"
            [matTooltipPosition]="'before'"
            (click)="copyText('126000161925952684463815', 3, tooltip3)">
      <payee-icon [name]="'copy-white'" width="24px"></payee-icon>
    </button>
  </div>

  <!--note-->
  <div class="transfer-data-textbox top-margin-20">
    <!--<fieldset class="text-wrapper">
      <legend>Közlemény</legend>
      <span>{{caseFormDataService.caseData.payeeCaseReferenceId}}</span>
    </fieldset>
    <button class="copy-icon-wrapper"
            #tooltip4="matTooltip"
            matTooltip="Másolva"
            [matTooltipDisabled]="copyTooltip4Disabled"
            [matTooltipPosition]="'before'"
            (click)="copyText(caseFormDataService.caseData.payeeCaseReferenceId, 4, tooltip4)">
      <payee-icon [name]="'copy-white'" width="24px"></payee-icon>
    </button>-->
  </div>

  <!--button-->
  <div [mat-dialog-close] class="top-margin-30">
    <button payee-button width="wide" height="tall">
      <div class="arrow-button flex vcenter hcenter">
        <span>Tovább az ügyeimhez</span>
        <payee-icon name="arrow-right-white"></payee-icon>
      </div>
    </button>
  </div>
</div>
