import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import i18n from './translate';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private static translationsLoaded = false;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if (!FooterComponent.translationsLoaded) {
      for (const [lang, translation] of Object.entries(i18n)) {
        this.translate.setTranslation(lang, translation, true);
      }
      FooterComponent.translationsLoaded = true;
    }
  }
}
