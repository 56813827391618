export interface Representative {
  name: string,
  tax_number: string,
}

export function getEmptyRepresentative(): Representative {
  return {
    name: '',
    tax_number: '',
  };
}
