import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormService } from "../services/form-services/form-service";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent implements OnChanges {
  @Input() formServices: FormService[] = [];

  private readonly onDestroySubject = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private titleService: Title,
  ) {
  }

  ngOnChanges(): void {
    this.setActiveStep();
  }

  onStepDialogOpen(formService: FormService) {
    const dialogRef = formService.openDialog();

    this.changeDialogUrl(formService.urlName);

    dialogRef.backdropClick()
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        formService.openExitDialog();
      });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(() => {
        formService.valueChanged = false;

        this.setMenuTexts();
        this.setActiveStep();
        this.setDefaultDataEntryUrlSettings();

        this.onDestroySubject.next();
        this.onDestroySubject.complete();
        formService.onDestroySubject.next();
        formService.onDestroySubject.complete();
      });
  }

  setActiveStep() {
    for (const form of this.formServices) {
      form.isActive = false;
      form.setData();
    }

    setTimeout(() => {
      for (const formService of this.formServices) {
        if (!formService.form.valid) {
          formService.isActive = true;
          return;
        }
      }
    }, 0);
  }

  setMenuTexts() {
    for (const data of this.formServices) {
      data.setMenuTexts();
    }
  }

  changeStepUrl(stepName: string) { // without navigation
    const unchangedPart = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1);
    const queryParamsString = this.getQueryParams();

    this.location.replaceState(unchangedPart + stepName + queryParamsString);
  }

  changeDialogUrl(dialogName: string) { // without navigation
    const unchangedPart = this.router.url.substring(0, this.router.url.lastIndexOf('?')) || this.router.url;
    const queryParamsString = this.getQueryParams();

    this.location.replaceState(unchangedPart + '/' + dialogName + queryParamsString);
  }

  setDefaultDataEntryUrlSettings() {
    this.changeStepUrl('data-entry');
    this.titleService.setTitle('Adatmegadás – Payee');
  }

  getQueryParams() {
    const params = window.location.href.split('?')[1] || '';
    return params ? `?${params}` : '';
  }
}
