import { Component, Input } from '@angular/core';
import { UserGuideService } from '../user-guide.service';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent {
  @Input() chapterId: string = '1';
  @Input() chapterName: string = 'Give a name to this chapter';

  get headerWeight() {
    const parts = this.chapterId.split('.');
    return parts.length;
  }

  constructor(
    public userGuideService: UserGuideService
  ) { }

}
