<form [formGroup]="form">
  <div class="form-group-label top-margin-15">Mi az adós neve?</div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="name"
        placeholder="Írja be a személy teljes nevét, aki tartozik"
      />
      <ng-container *ngIf="name.invalid && name.dirty" payee-error>
        <span *ngIf="name.hasError('required')">A mező kitöltése kötelező</span>
        <span *ngIf="name.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="form-group-label top-margin-15">Hol érhetjük el az adóst?</div>
  <form formGroupName="address">
    <div class="top-margin-15">
      <payee-dropdown
        formControlName="country_iso"
        [options]="countryOptions"
        width="widest"
        height="tall"
        [displayWith]="countryDisplayWith"
      >
        <ng-template #menuDisplayTemplate>
          <span>{{ debtorCountry.value?.name }}</span>
        </ng-template>
      </payee-dropdown>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="postcode"
          placeholder="Irányítószám"
        />
        <ng-container *ngIf="postcode.invalid && postcode.dirty" payee-error>
          <span *ngIf="postcode.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="postcode.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="settlement"
          placeholder="Település"
        />
        <ng-container *ngIf="settlement.invalid && settlement.dirty" payee-error>
          <span *ngIf="settlement.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="settlement.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="street"
          placeholder="Utca és házszám"
        />
        <ng-container *ngIf="street.invalid && street.dirty" payee-error>
          <span *ngIf="street.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="street.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>
  </form>
</form>
