<div class="container">
  <div class="content">
    <div class="plus-icon">
      <payee-icon [name]="'plus-black'" width="12px"></payee-icon>
    </div>
    <div class="text">
      Járulékos követelések hozzáadása a teljes követeléshez
    </div>
  </div>
</div>
