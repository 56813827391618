import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import {
  ChangePasswordDialogComponent
} from 'src/app/dialogs/profile/change-password-dialog/change-password-dialog.component';
import {
  ChangePasswordSuccessDialogComponent
} from 'src/app/dialogs/profile/change-password-success-dialog/change-password-success-dialog.component';
import { ClientsService, SelectedClient } from 'src/app/services/clients/clients.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import {
  CreateClientDialogComponent
} from '../../../dialogs/client/create-client-dialog/create-client-dialog.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private saveCounter = 0;
  get saving() { return this.saveCounter > 0; }

  canChangePassword = true;
  loading = true;

  has2FA = false;
  userInitials: string;

  clients: SelectedClient[] = [];

  userForm: FormGroup;

  private readonly destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private usersService: UsersService,
    private clientsService: ClientsService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  get lastName() { return this.userForm.get('lastName'); }
  get firstName() { return this.userForm.get('firstName'); }
  get email() { return this.userForm.get('email'); }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
    });

    this.canChangePassword = this.authService.origin !== 'PLUGIN_SZAMLAZZHU';

    this.userForm.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(777))
      .subscribe({
        next: async change => {
          this.setUserInitials(change.lastName, change.firstName);
          if (this.userForm.valid) {
            ++this.saveCounter;
            await this.usersService.updateUser({
              name: `${change.lastName} ${change.firstName}`,
            });
            setTimeout(() => --this.saveCounter, 2500);
          } else {
            this.userForm.markAsDirty();
          }
        },
      });

    this.usersService.userSubject
      .pipe(takeUntil(this.destroy), filter(user => !!user))
      .subscribe({
        next: user => {
          this.has2FA = user.has_2fa;

          let firstName = '';
          let lastName = '';

          if (!user.name) {
            this.setUserInitials('', '');
          } else {
            [lastName, firstName] = user.name.split(' ', 2);
            this.setUserInitials(lastName, firstName);
          }

          this.userForm.patchValue({
            lastName,
            firstName,
            email: user.email,
          }, { emitEvent: false });
        },
      });

    this.clientsService.clientsSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: clients => {
          this.loading = false;
          this.clients = clients;
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  changePassword(): void {
    if (!this.canChangePassword) {
      return;
    }

    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      autoFocus: false,
      position: {
        top: '50px',
      }
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(async result => {
        if (result) {
          this.dialog.open(ChangePasswordSuccessDialogComponent, {
            autoFocus: false,
            position: {
              top: '50px',
            }
          });
        }
      });
  }

  navigateToClientProfile(client: SelectedClient): void {
    this.clientsService.setSelectedClient(client);
    this.router.navigateByUrl('user/client-profile');
  }

  onAddNewClientButtonClick(): void {
    this.dialog.open(CreateClientDialogComponent, {
      autoFocus: true,
      position: {
        top: '50px',
      }
    });
  }

  async enable2FA(): Promise<void> {
    await this.authService.enable2FA();
  }

  private setUserInitials(lastName: string = '', firstName: string = ''): void {
    const lastNameInitial = lastName[0] ?? '';
    const firstNameInitial = firstName[0] ?? '';
    this.userInitials = `${lastNameInitial}${firstNameInitial}`;
  }
}
