<payee-popup title="{{ 'DISCARD_CASE_DIALOG.TITLE' | translate }}">
  <div class="img-box flex vcenter hcenter">
    <img
      src="assets/shared/global-images/icons/trash-grey.svg"
      height="71.43px"
    />
  </div>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      color="alert-red"
      width="wide"
      height="tall"
      (click)="submit()"
    >
      {{ "DISCARD_CASE_DIALOG.CONFIRM_DELETE" | translate }}
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button cancel-button"
      payee-button
      [isBorderButton]="true"
      [mat-dialog-close]="false"
      color="white"
      width="wide"
      height="tall"
    >
      {{ "DISCARD_CASE_DIALOG.CANCEL" | translate }}
    </button>
  </ng-template>
</payee-popup>
