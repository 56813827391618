<!--mother's name-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <input payee-input [formControl]="motherNameFormControl" [placeholder]="'Anyja neve'">
    <ng-container payee-error *ngIf="motherNameFormControl.dirty">
      <div *ngIf="motherNameFormControl.hasError('required')">A mező kitöltése kötelező!</div>
    </ng-container>
  </payee-form-field>
</div>

<!--place of birth-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <input payee-input [formControl]="birthPlaceFormControl" [placeholder]="'Születési hely'">
    <ng-container payee-error *ngIf="birthPlaceFormControl.dirty">
      <div *ngIf="birthPlaceFormControl.hasError('required')">A mező kitöltése kötelező!</div>
    </ng-container>
  </payee-form-field>
</div>

<!--date of birth-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <payee-datepicker
      [formControl]="birthDateFormControl"
      [placeholder]="'Születési idő'"
      [max]="max">
    </payee-datepicker>
    <ng-container payee-error *ngIf="birthDateFormControl.dirty">
      <div *ngIf="birthDateFormControl.hasError('required')">A mező kitöltése kötelező!</div>
    </ng-container>
  </payee-form-field>
</div>
