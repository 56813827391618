import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeToggleComponent } from './payee-toggle.component';
import { PayeeIconModule } from '../payee-icon/payee-icon.module';


@NgModule({
  declarations: [
    PayeeToggleComponent,
  ],
  imports: [
    CommonModule,
    PayeeIconModule,
  ],
  exports: [
    PayeeToggleComponent,
  ],
})
export class PayeeToggleModule { }
