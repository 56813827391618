<app-header-app xLink="/login"></app-header-app>
<app-form-template>
  <ng-template #titleTemplate>
    <div class="flex vcenter column title-wrapper">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green-big.svg"
        alt="checkmark-green-circle-big"
      />
      <h1>{{ "GET_PASSWORD_RESET_LINK_SUCCESS.TITLE" | translate }}</h1>
    </div>
  </ng-template>

  <span class="description">
    {{ "GET_PASSWORD_RESET_LINK_SUCCESS.DESCRIPTION" | translate }}
  </span>

  <payee-horizontal-line></payee-horizontal-line>

  <div class="flex vcenter hcenter">
    <span>{{ "GET_PASSWORD_RESET_LINK_SUCCESS.EMAIL_NOT_RECEIVED" | translate }}</span
    >&nbsp;
    <a
      class="reset-link"
      color="blue-payee"
      (click)="resendForgotPasswordEmail()"
    >
      {{ "GET_PASSWORD_RESET_LINK_SUCCESS.RESEND_LINK" | translate }}
    </a>
    <ng-container *ngIf="loading">
      &nbsp;
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
      </mat-progress-spinner>
    </ng-container>
  </div>
</app-form-template>
