import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CasesService } from 'src/app/services/cases/cases.service';

@Component({
  selector: 'app-discard-case-dialog',
  templateUrl: './discard-case-dialog.component.html',
  styleUrls: ['./discard-case-dialog.component.scss']
})
export class DiscardCaseDialogComponent {
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<DiscardCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id: string },
    private casesService: CasesService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  async submit(): Promise<void> {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      await this.casesService.deleteCase(this.data.id);
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.snackbar.open(
        this.translate.instant('ERROR_MESSAGES.UNKNOWN_ERROR'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } finally {
      this.loading = false;
    }
  }
}
