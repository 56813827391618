import { Component } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent {
  sampleListData = [
    { primary: "Hong Kong", secondary: "Just now" },
    { primary: "Stockholm", secondary: "3m ago" },
    { primary: "São Paulo", secondary: "34m ago" },
    { primary: "Saint Petersburg", secondary: "1h 58m ago", divider: false },
  ];

  tableSampleData: any[] = [
    {
      order: '92330',
      city: 'Hong Kong',
      fulfilled: 'Yes',
      description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit',
      total: 94.24
    },
    {
      order: '23638',
      city: 'Stockholm',
      fulfilled: 'No',
      description: 'Sed do eiusmod tempor incididunt ut labore',
      total: 373.08
    },
    {
      order: '82070',
      city: 'São Paulo',
      fulfilled: 'Yes',
      description: 'Et dolore magna aliqua consectetur',
      total: 837.56
    },
    {
      order: '36301',
      city: 'Saint Petersburg',
      fulfilled: 'Yes',
      description: 'Ut enim ad minim veniam quis nostrud exercitation',
      total: 128.86
    },
    {
      order: '45230',
      city: 'Toronto',
      fulfilled: 'Yes',
      description: 'Ullamco laboris nisi ut aliquip ex ea commodo consequat',
      total: 238.01
    }
  ];

  constructor() { }
}
