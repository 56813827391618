import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-error-snackbar',
  templateUrl: './form-error-snackbar.component.html',
  styleUrls: ['./form-error-snackbar.component.scss']
})
export class FormErrorSnackbarComponent {

  constructor(
    private snackBarRef: MatSnackBarRef<FormErrorSnackbarComponent>,
  ) {}

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
