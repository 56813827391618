<form class="flex bank-account-box" [formGroup]="bankAccountForm">
  <payee-form-field width="wide">
    <input payee-input [formControl]="accountHolderName" />
    <span payee-label>{{ "CLIENT_BANK_ACCOUNT_EDIT.BENEFICIARY_NAME_LABEL" | translate }}</span>
    <mat-error
      payee-error
      *ngIf="accountHolderName.dirty && !accountHolderName.value"
    >
      {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
    </mat-error>
  </payee-form-field>

  <payee-form-field width="wide">
    <input
      payee-input
      [formControl]="accountNumber"
      (input)="formatBankAccount(accountNumber)"
    />
    <span payee-label>{{ "CLIENT_BANK_ACCOUNT_EDIT.BBAN_IBAN_LABEL" | translate }}</span>
    <mat-error
      payee-error
      *ngIf="accountNumber.dirty && accountNumber.hasError('required')"
    >
      {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
    </mat-error>
    <mat-error
      payee-error
      *ngIf="accountNumber.value && accountNumber.errors?.pattern"
    >
      {{ "ERROR_MESSAGES.INVALID_FORMAT" | translate }}
    </mat-error>
  </payee-form-field>

  <payee-form-field width="wide">
    <input payee-input [formControl]="bankName" />
    <span payee-label>{{ "CLIENT_BANK_ACCOUNT_EDIT.BANK_NAME_LABEL" | translate }}</span>
    <mat-error
      payee-error
      *ngIf="bankName.dirty && bankName.hasError('required')"
    >
      {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
    </mat-error>
  </payee-form-field>
</form>

<div class="save-icon flex vcenter" [class.show]="saveCount > 0">
  <img src="assets/shared/global-images/icons/dot-green.svg" />
  <span>{{ "AUTOSAVE" | translate }}</span>
</div>
