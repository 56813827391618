<div class="wrapper">
  <app-header title="{{ 'CASES.HEADER.TITLE' | translate }}"></app-header>
  <div class="content">
    <div class="tabs">
      <mat-tab-group
        [selectedIndex]="selectedTab"
        animationDuration="0ms"
        (selectedTabChange)="navigateToTab($event)"
      >
        <mat-tab [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showDrafts"
            >
              <span class="tab-label">
                {{ "CASES.TABLE_SELECTOR.DRAFT" | translate }}
              </span>
              <div class="case-counter">{{ draftCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info">
            {{ "CASES.TABLE_SELECTOR.DRAFT_INFO" | translate }}
          </payee-info>
        </mat-tab>
        <mat-tab [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showOngoing"
            >
              <span class="tab-label">{{
                "CASES.TABLE_SELECTOR.ONGOING" | translate
              }}</span>
              <div class="case-counter">{{ ongoingCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info">
            {{ "CASES.TABLE_SELECTOR.ONGOING_INFO" | translate }}
          </payee-info>
        </mat-tab>
        <mat-tab [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showClosed"
            >
              <span class="tab-label">{{ "CASES.TABLE_SELECTOR.CLOSED" | translate }}</span>
              <div class="case-counter">{{ closedCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info">
            {{ "CASES.TABLE_SELECTOR.CLOSED_INFO" | translate }}
          </payee-info>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="toolbar flex vcenter">
      <div class="flex grow1 vcenter">
        <payee-dropdown
          width="short"
          [options]="filterTypeOptions"
          [displayWith]="filterTypeLabel"
          [formControl]="filterType"
        >
        </payee-dropdown>
        <payee-form-field appearance="outline">
          <input
            payee-input
            placeholder="{{ 'CASES.FILTER.PLACEHOLDER' | translate }}"
            [formControl]="filter"
            [matAutocomplete]="auto"
          />
        </payee-form-field>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <ng-container *ngIf="filterType.value === 'debtorName'">
            <mat-option
              *ngFor="let debtor of searchedDebtorNames"
              (click)="onDebtorFilterSelected(debtor)"
              [value]="debtor"
            >
              {{ debtor }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </div>
    <ng-container *ngIf="!loading && hasCases">
      <app-cases-table
        [data]="tableData"
        (pageSizeChange)="setPageSize($event)"
        [pageSize]="pageSize"
        (pageIndexChange)="setPageIndex($event)"
        [pageIndex]="pageIndex"
        [length]="length"
        (deleted)="onDeleted()"
        (filterCase)="filterCase($event)"
      ></app-cases-table>
    </ng-container>
    <div class="loading-spinner alt-theme" *ngIf="loading">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="100"
      ></mat-progress-spinner>
    </div>
    <div
      class="nocases flex grow1 column vcenter"
      *ngIf="!loading && !hasCases"
    >
      <span class="no-cases-text" color="grey-dark">{{ 'CASES.NO_CASES.TITLE' | translate }}</span>
    </div>
  </div>
</div>
