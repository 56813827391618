import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-password-success',
  templateUrl: './new-password-success.component.html',
  styleUrls: ['./new-password-success.component.scss']
})
export class NewPasswordSuccessComponent implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();

  constructor(
    private titleService: Title,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.translate.get('BROWSER_TITLES.PASSWORD_RESET')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
