<h2>Adós adatai</h2>
<section
  *ngIf="!collapsed && !loading"
  class="forms"
  [class.valid]="form.valid"
>
  <div class="form-group-label top-margin-15">
    Az adósa cég vagy magánszemély?
  </div>
  <div class="flex vcenter top-margin-5">
    <div class="flex vcenter grow1 type-selector">
      <button
        payee-button
        class="option"
        [class.active]="debtor_type.value === 'org'"
        (click)="debtor_type.patchValue('org')"
      >
        <div class="flex vcenter" style="gap: 10px">
          <payee-icon name="form-org"></payee-icon>
          <span>Cég / Egyéni vállalkozás / Szervezet</span>
        </div>
      </button>
      <button
        payee-button
        class="option"
        [class.active]="debtor_type.value === 'ind'"
        (click)="debtor_type.patchValue('ind')"
      >
        <div class="flex vcenter" style="gap: 10px">
          <payee-icon name="form-ind"></payee-icon>
          <span>Magánszemély</span>
        </div>
      </button>
    </div>
    <button
      *ngIf="form.valid"
      payee-icon-button
      color="white"
      (click)="collapsed = true"
    >
      <payee-icon name="collapse"></payee-icon>
    </button>
  </div>

  <app-debtor-org-form
    *ngIf="debtor_type.value === 'org'"
    [countryOptions]="countryOptions"
    [partyTypeOptions]="partyTypeOptions"
    (validCompanySelected)="collapsed = true"
  ></app-debtor-org-form>
  <app-debtor-ind-form
    *ngIf="debtor_type.value === 'ind'"
    [countryOptions]="countryOptions"
  ></app-debtor-ind-form>
</section>

<div
  *ngIf="collapsed"
  class="debtor-summary flex vcenter top-margin-15"
  (click)="collapsed = false"
>
  <payee-icon name="checkmark-darker-green-circle"></payee-icon>
  <div class="debtor-data flex column grow1">
    <div class="debtor-name">{{ form.value.name }}</div>
    <div class="debtor-address">{{ addressString }}</div>
    <div *ngIf="form.value.tax_number" class="debtor-tax-number">
      {{ form.value.tax_number }}
    </div>
  </div>
  <payee-icon name="expand"></payee-icon>
</div>
