import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { UserSource } from 'src/types';
import { FullStory } from '@fullstory/browser';
import Intercom from '@intercom/messenger-js-sdk';

export type UpdateUserParams = {
  name: string;
};

export type User = {
  email: string;
  has_2fa: boolean;
  id: string;
  name: string | null;
  email_verified: boolean;
  origin: UserSource;
  created_at: string | null;
  intercom_hash: string;
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly userSubject = new BehaviorSubject<User | null>(null);

  constructor(
    private authService: AuthService,
  ) {
    this.authService.authSubject
      .subscribe({
        next: async isLoggedIn => {
          if (isLoggedIn) {
            const user = await this.setUser();
            this.userSubject.next(user);

            Intercom({
              app_id: 'n2gtpl1c',
              user_id: user.id,
              user_hash: user.intercom_hash,
              name: user.name,
              email: user.email,
              created_at: new Date(user.created_at).valueOf(),
            });
          } else {
            this.userSubject.next(null);
          }
        },
      });

    this.userSubject.subscribe({
      next: user => {
        if (!user) {
          FullStory('setIdentity', {
            anonymous: true,
          });
          return;
        }

        FullStory('setIdentity', {
          uid: user.id,
          properties: {
            email: user.email,
            emailVerified: user.email_verified,
          },
        });
      },
    })
  }

  async getUser(): Promise<User> {
    return await new Promise(resolve => this.userSubject.pipe(filter(user => !!user)).subscribe(resolve));
  }

  async updateUser(params: UpdateUserParams): Promise<User> {
    const url = environment.baseUrl + `/api/user`;
    const response = await axios.patch<User>(url, params);
    return response.data;
  }

  private async setUser(): Promise<User> {
    const url = environment.baseUrl + `/api/user`;
    const result = await axios.get<User>(url);
    if (this.authService.origin === 'ORGANIC') {
      this.authService.setOrigin(result.data.origin);
    }
    return result.data;
  }
}
