import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'payee-completed-search-box',
  templateUrl: './payee-completed-search-box.component.html',
  styleUrls: ['./payee-completed-search-box.component.scss']
})
export class PayeeCompletedSearchBoxComponent {
  @Input() name: string;
  @Input() address: string;
  @Input() taxNumber: string;
  @Input() additionalData: string;
  @Input() disabled: boolean = false;
  @Input() isDeletable: boolean = true;
  @Input() isEditable: boolean = true;

  @Output() deleteButtonClick = new EventEmitter();
  @Output() editButtonClick = new EventEmitter();

  constructor() { }

  onDeleteButtonClick() {
    this.deleteButtonClick.emit();
  }

  onEditButtonClick() {
    this.editButtonClick.emit();
  }
}
