<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img
      src="assets/shared/global-images/icons/trash-grey.svg"
      height="71.43px"
    />
  </div>
  <div class="title flex vcenter">
    {{ "DELETE_API_KEY.TITLE" | translate }}
  </div>
  <div class="description flex vcenter">
    {{ "DELETE_API_KEY.DESCRIPTION" | translate }}
  </div>
  <button mat-button class="confirm-button" [mat-dialog-close]="true">
    {{ "DELETE_API_KEY.CONFIRM_DELETE" | translate }}
  </button>
  <button mat-button class="close-button" [mat-dialog-close]="false">
    {{ "DELETE_API_KEY.CANCEL" | translate }}
  </button>
</div>
