import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { InitStepService } from "../init-step.service";
import { CommunicationStepData } from "../../models/communication/communication-step-data";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { LanguageDialogComponent } from '../../dialogs/language-dialog/language-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LanguageFormService extends FormService {
  communicationStepData: CommunicationStepData;
  form: FormGroup;

  readonly languageOptions = ['HU', 'EN'] as const;
  readonly languageOptionLabels: Record<typeof this.languageOptions[number], string> = {
    HU: 'Magyar',
    EN: 'Angol',
  };
  readonly languageOptionIcons: Record<typeof this.languageOptions[number], string> = {
    HU: 'hu-flag-circle',
    EN: 'en-flag-circle',
  };

  get communicationLanguageFormControl() { return this.form?.get('communicationLanguageFormControl') as FormControl; }

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private initStepService: InitStepService,
    private snackbar: MatSnackBar,
  ) {
    // set initial parent basic data
    super(helpersService, dialog);
    this.setText(
      'Nyelv',
      'Milyen nyelven kommunikáljunk az adósoddal?'
    );
    this.urlName = 'language';

    // set communication step data
    this.communicationStepData = this.initStepService.communicationStepData;
    this.initStepService.getCommunicationStepDataListener()
      .subscribe(async communicationStepData => {
        this.communicationStepData = communicationStepData;
      });
  }

  override buildForm() {
    this.form = this.formBuilder.group({
      communicationLanguageFormControl: [
        this.communicationStepData.country_iso ?? 'HU',
        [Validators.required]
      ],
    });
  }

  override  async persistData() {
    this.updateData();

    try {
      await this.initStepService.saveCommunicationStepData();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }
  }

  updateData() {
    this.communicationStepData.country_iso = this.communicationLanguageFormControl.value ?? 'HU';
  }

  override setMenuTexts() {
    if (this.communicationLanguageFormControl?.value in this.languageOptionLabels) {
      this.dropdownTitle = this.languageOptionLabels[this.communicationLanguageFormControl.value];
    } else {
      this.dropdownTitle = '';
    }
    this.dropdownItems = [];
  }

  override openDialog() {
    return this.dialog.open(LanguageDialogComponent, {
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });
  }
}
