<div class="wrapper flex column vcenter">
  <img
    src="assets/shared/global-images/icons/shield.svg"
    class="logo"
    height="71.43px"
    width="57.14px"
  />
  <div class="title">{{ "PASSWORD_RESET_DIALOG.TITLE" | translate }}</div>
  <div class="description">
    {{ "PASSWORD_RESET_DIALOG.DESCRIPTION" | translate }}
  </div>
  <form [formGroup]="passwordForm">
    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!oldPwd.value">
        {{ "PASSWORD_RESET_DIALOG.OLD_PASSWORD_LABEL" | translate }}
      </span>
      <input custom-input type="password" #oldpwd [formControl]="oldPwd" />
      <mat-error
        custom-error
        *ngIf="oldPwd.dirty && oldPwd.hasError('required')"
      >
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
      <mat-error
        custom-error
        *ngIf="
          oldPwd.dirty &&
          oldPwd.hasError('minlength') &&
          !oldPwd.hasError('required')
        "
      >
        {{ "ERROR_MESSAGES.PASSWORD_MIN_LENGTH" | translate }}
      </mat-error>
      <mat-error custom-error *ngIf="oldPwd.hasError('wrongPassword')">
        {{ "PASSWORD_RESET_DIALOG.ERROR_MESSAGES.OLD_PASSWORD_WRONG" | translate }}
      </mat-error>
      <div custom-suffix *ngIf="oldPwd.valid">
        <payee-icon
          [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"
        ></payee-icon>
      </div>
    </app-custom-input-field>

    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!newPwd.value">
        {{ "PASSWORD_RESET_DIALOG.NEW_PASSWORD_LABEL" | translate }}
      </span>
      <input custom-input type="password" #newpwd [formControl]="newPwd" />
      <mat-error
        custom-error
        *ngIf="newPwd.dirty && newPwd.hasError('required')"
      >
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
      <mat-error
        custom-error
        *ngIf="
          newPwd.dirty &&
          newPwd.hasError('minlength') &&
          !newPwd.hasError('required')
        "
      >
        {{ "ERROR_MESSAGES.PASSWORD_MIN_LENGTH" | translate }}
      </mat-error>
      <mat-error
        custom-error
        *ngIf="
          !newPwd.hasError('required') &&
          newPwd.hasError('oldAndNewPasswordMatch')
        "
      >
        {{ "PASSWORD_RESET_DIALOG.ERROR_MESSAGES.PASSWORD_MATCH" | translate }}
      </mat-error>
      <mat-error
        custom-error
        *ngIf="newPwd.dirty && newPwd.hasError('confirmPasswordMatch')"
      >
        {{ "ERROR_MESSAGES.PASSWORDS_DONT_MATCH" | translate }}
      </mat-error>
      <div custom-suffix *ngIf="newPwd.valid">
        <payee-icon
          [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"
        ></payee-icon>
      </div>
    </app-custom-input-field>

    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!newPwdConfirm.value">
        {{ "PASSWORD_RESET_DIALOG.CONFIRM_NEW_PASSWORD_LABEL" | translate }}
      </span>
      <input
        custom-input
        type="password"
        #confirm
        [formControl]="newPwdConfirm"
      />
      <div custom-suffix *ngIf="newPwdConfirm.valid">
        <payee-icon
          [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"
        ></payee-icon>
      </div>
    </app-custom-input-field>
    <mat-error custom-error *ngIf="passwordForm.hasError('tooManyRequests')">
      {{ "ERROR_MESSAGES.TOO_MANY_REQUESTS" | translate }}
    </mat-error>
  </form>

  <button
    mat-button
    [disabled]="passwordForm.invalid"
    (click)="onClose()"
    class="accept"
  >
    {{ "PASSWORD_RESET_DIALOG.BUTTONS.SAVE" | translate }}
  </button>
  <button mat-button [mat-dialog-close]="false" class="decline">
    {{ "PASSWORD_RESET_DIALOG.BUTTONS.CANCEL" | translate }}
  </button>
</div>
