<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <div class="title">
      {{ "TAX_NUMBER_MISSING_DIALOG.TITLE" | translate }}
    </div>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      <a href="mailto: hello@payee.tech" color="blue-king">
        {{
          "TAX_NUMBER_MISSING_DIALOG.DESCRIPTION"
            | translate : { email: "hello@payee.tech" }
        }}
      </a>
    </div>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      {{ "TAX_NUMBER_MISSING_DIALOG.CONFIRM" | translate }}
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate></ng-template>
</payee-popup>
