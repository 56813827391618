import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { filter, first } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ClientsService } from '../clients/clients.service';
import { SzamlazzhuService } from '../szamlazzhu/szamlazzhu.service';
import { UsersService } from '../users/users.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private clientsService: ClientsService,
    private usersService: UsersService,
    private szamlazzhuService: SzamlazzhuService,
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authService.isAuthenticated) {
      return await this.tryLoginWithShortToken(route, state);
    }

    // Set next in case tokens have expired
    this.authService.setNext(state.url);
    const user = await this.usersService.getUser();
    this.authService.setNext(null);
    if (!user.email_verified) {
      if (state.url === '/verify-email') {
        return true;
      } else {
        return this.router.navigateByUrl('verify-email');
      }
    }

    if (state.url.startsWith('/user/add-new-client')) {
      return true;
    }

    const clients = await this.clientsService.clientsSubject
      .pipe(filter(v => !!v), first())
      .toPromise();
    if (clients.length > 0) {
      return true;
    }

    return this.router.navigateByUrl('user/add-new-client-onboarding');
  }

  private async tryLoginWithShortToken(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const shortToken = route.queryParams.short_token;
    if (!shortToken) {
      return this.navigateUnauthenticated('login', state);
    }

    try {
      await this.authService.loginWithShortToken(shortToken);
      return true;
    } catch (error) {
      console.warn('Error while logging in with short token', error);
      return this.navigateUnauthenticated('login', state);
    }
  }

  private navigateUnauthenticated(path: string, state: RouterStateSnapshot): Promise<boolean>
  {
    this.authService.setNext(state.url);
    return this.router.navigateByUrl(path);
  }
}
