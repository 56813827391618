import {Component} from '@angular/core';
import {PaymentStepService} from "../../services/payment-step.service";
import {FormTypeEnum} from "../../../init-step/models/common/form-type-enum";

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent {
  get isSoftForm() { return this.paymentStepService.paymentStepData.type === FormTypeEnum.SOFT; }
  get isDemandLetterForm() { return this.paymentStepService.paymentStepData.type === FormTypeEnum.DEMAND_LETTER; }
  get isHardForm() { return this.paymentStepService.paymentStepData.type === FormTypeEnum.HARD; }

  constructor(
    public paymentStepService: PaymentStepService
  ) {
  }
}
