import {getEmptyPayeeFile, PayeeFile} from "../common/payee-file";

export interface Claim {
    uuid: number;
    amount: number;
    due_date_at: string;
    file: PayeeFile;
    ref_id: string;
}

export function getEmptyClaim(): Claim {
    return {
        uuid: null,
        amount: 0,
        due_date_at: '',
        file: getEmptyPayeeFile(),
        ref_id: null,
    };
}
