import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text: string;
  @Input() arrow = false;
  @Input() disabled = false;
  @Input() loading = false;

  @Output() clicked = new EventEmitter();

  onButtonClick() {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

  constructor() { }
}
