<div class="text">
  {{ "CLIENT_OPTIONS.TOV.DESCRIPTION" | translate }}
</div>
<div class="alt-theme">
  <mat-radio-group color="primary" [formControl]="toneOfVoice">
    <mat-radio-button [value]="informal">
      <span>{{ "CLIENT_OPTIONS.TOV.INFORMAL_LABEL" | translate }}</span>
      <img
        src="assets/shared/global-images/icons/question-mark.svg"
        width="20px"
        height="20px"
        matTooltipPosition="right"
        matTooltipClass="custom-tooltip"
        matTooltip="{{ 'CLIENT_OPTIONS.TOV.INFORMAL_TOOLTIP' | translate }}"
      />
    </mat-radio-button>
    <mat-radio-button [value]="formal">
      <span>{{ "CLIENT_OPTIONS.TOV.FORMAL_LABEL" | translate }}</span>
      <img
        src="assets/shared/global-images/icons/question-mark.svg"
        width="20px"
        height="20px"
        matTooltipPosition="right"
        matTooltipClass="custom-tooltip"
        matTooltip="{{ 'CLIENT_OPTIONS.TOV.FORMAL_TOOLTIP' | translate }}"
      />
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="save-icon flex vcenter" [class.show]="saveCount > 0">
  <img src="assets/shared/global-images/icons/dot-green.svg" />
  <span>{{ "AUTOSAVE" | translate }}</span>
</div>
