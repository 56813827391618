import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { CountryItem } from 'src/app/services/enum/enum.service';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-debtor-ind-form',
  templateUrl: './debtor-ind-form.component.html',
  styleUrls: ['./debtor-ind-form.component.scss']
})
export class DebtorIndFormComponent implements OnInit, OnDestroy {
  get form(): FormGroup { return this.formService.form.get('debtor') as FormGroup; }

  debtorCountry = new BehaviorSubject<CountryItem | null>(null);
  @Input() countryOptions: CountryItem[] = [];

  private readonly destroy = new Subject<void>();

  get debtor_type(): FormControl { return this.form.get('debtor_type') as FormControl; }
  get name(): FormControl { return this.form.get('name') as FormControl; }
  get country_iso(): FormControl { return this.form.get('address.country_iso') as FormControl; }
  get postcode(): FormControl { return this.form.get('address.postcode') as FormControl; }
  get settlement(): FormControl { return this.form.get('address.settlement') as FormControl; }
  get street(): FormControl { return this.form.get('address.street') as FormControl; }

  constructor(
    private formService: FormService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.country_iso.valueChanges
      .pipe(takeUntil(this.destroy), startWith(this.country_iso.value))
      .subscribe({
        next: (country: null | string | CountryItem) => {
          if (!country || typeof country === 'string') {
            this.debtorCountry.next(this.countryOptions.find(c => c.iso === country));
            return;
          }
          this.debtorCountry.next(country);
          this.country_iso.patchValue(country.iso);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  countryDisplayWith(country?: CountryItem): string {
    return country?.name ?? '';
  }
}
