import { Injectable, OnDestroy } from '@angular/core';
import { UsersService } from '../services/users/users.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserGuideService implements OnDestroy {
  userName: string = '';

  private readonly destroy = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private router: Router,
  ) {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => {
          if (!user?.name) {
            this.userName = '';
          } else {
            const [firstName = '', lastName = ''] = user.name.split(' ', 2);
            this.userName = (firstName[0] ?? '') + (lastName[0] ?? '');
          }
        },
      });
  }

  anchorChapter(chapterId: string) {
    this.router.navigate([], { fragment: chapterId });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
