import { Component, OnInit } from '@angular/core';
import { FormTypeEnum } from "../init-step/models/common/form-type-enum";
import { SummaryStepService } from "./services/summary-step.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormErrorSnackbarComponent } from "../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from '@angular/platform-browser';
import { CasesService } from 'src/app/services/cases/cases.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-summary-step-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss']
})
export class SummaryStepComponent implements OnInit {
  readonly FormTypeEnum = FormTypeEnum;
  partnerLogoName = '';
  loading = true;
  productChangeLoading = false;

  private caseUuid: string = '';

  constructor(
    public summaryStepService: SummaryStepService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private titleService: Title,
    private casesService: CasesService,
  ) {
  }

  async productChange(event: MatRadioChange) {
    try {
      this.productChangeLoading = true;
      await this.casesService.changeProduct(this.summaryStepService.caseUuid, event.value);
  
      await this.summaryStepService.setSummaryStepData();
    } catch (error) {
      console.error('Error while changing product', error);
    } finally {
      this.productChangeLoading = false;
    }
  }

  async startCase() {
    const result = await this.casesService.startSoftSuccessCase(this.summaryStepService.caseUuid);
    this.router.navigate(['/user/cases'], {
      queryParams: {
        filterType: 'payeeId',
        filter: result.payee_case_reference_id,
      },
    })
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.caseUuid = params['uuid'];
      this.summaryStepService.setCaseUuid(this.caseUuid);

      try {
        const result = await this.summaryStepService.setSummaryStepData();
        if (!result.redirecting) {
          this.loading = false;
          this.partnerLogoName = this.summaryStepService.getPartnerLogoName;
        }
      } catch (error) {
        console.error(error);
        this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        await this.router.navigate([`/case/${this.caseUuid}/data-entry`]);
      }
    });

    this.titleService.setTitle('Összegzés – Payee');
  }

  async onBackButtonClick() {
    await this.router.navigate([`/case/${this.caseUuid}/data-entry`]);
  }

  async onForwardButtonClick() {
    await this.router.navigate([`/case/${this.caseUuid}/payment`]);
  }
}
