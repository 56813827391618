export interface Address {
  country: string,
  postcode: string,
  settlement: string,
  street: string,
}

export function getEmptyAddress(): Address {
  return {
    country: 'Magyarország',
    postcode: '',
    settlement: '',
    street: '',
  }
}
