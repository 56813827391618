import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['./bank-account-form.component.scss']
})
export class BankAccountFormComponent {
  get form(): FormGroup { return this.formService.form.get('bank-account') as FormGroup; }

  get account_holder_name(): FormControl { return this.form.get('account_holder_name') as FormControl; }
  get account_number(): FormControl { return this.form.get('account_number') as FormControl; }
  get bic(): FormControl { return this.form.get('bic') as FormControl; }

  constructor(
    private formService: FormService,
  ) { }
}
