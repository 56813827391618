<!--tarsasház fixed, disabled input-->
<div class="top-margin-10">
    <payee-form-field height="tall" width="widest">
        <input payee-input value="Társasház" disabled>
    </payee-form-field>
</div>

<!--tax id-->
<div class="top-margin-10">
    <payee-form-field height="tall" width="widest">
        <!--input-->
        <input payee-input [formControl]="taxNumberFormControl" [placeholder]="'Adószám'">
        <!--error messages-->
        <ng-container payee-error *ngIf="taxNumberFormControl?.dirty">
            <div *ngIf="taxNumberFormControl.hasError('required')">A mező kitöltése kötelező!</div>
        </ng-container>
    </payee-form-field>
</div>

<!--registration number-->
<div class="top-margin-10">
    <payee-form-field height="tall" width="widest">
        <!--input-->
        <input payee-input [formControl]="registrationNumberFormControl" [placeholder]="'Helyrajzi szám'">
        <!--error messages-->
        <ng-container payee-error *ngIf="registrationNumberFormControl?.dirty">
            <div *ngIf="registrationNumberFormControl.hasError('required')">A mező kitöltése kötelező!</div>
        </ng-container>
    </payee-form-field>
</div>
