import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent {
  message: string;
  action: string;
  actionLink: string;
  type = 'error';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private data: {
      message: string,
      action: string,
      actionLink: string,
      type: string,
    },
    private snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
  ) {
    this.message = data.message;
    this.action = data.action;
    this.actionLink = data.actionLink;
    this.type = data.type;
  }

  dismiss() {
    if (this.actionLink) {
      window.open(this.actionLink, '_blank');
    }
    this.snackBarRef.dismiss();
  }
}
