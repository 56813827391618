import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-verify-token-expired',
  templateUrl: './verify-token-expired.component.html',
  styleUrls: ['./verify-token-expired.component.scss']
})
export class VerifyTokenExpiredComponent {
  resendPressed = false;

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  async resendVerifyEmail() {
    if (this.resendPressed) {
      return;
    }
    try {
      this.resendPressed = true;
      await this.authService.resendVerifyEmail();
      this.snackbar.open(
        this.translate.instant('VERIFY_TOKEN_EXPIRED.RESEND_SUCCESS'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } catch (error) {
      console.error('Error while sending verify email', error);
      this.snackbar.open(
        this.translate.instant('ERROR_MESSAGES.UNKNOWN_ERROR'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } finally {
      setTimeout(() => this.resendPressed = false, 15000);
    }
  }
}
