import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  readonly passwordForm: FormGroup;
  readonly email: string;
  readonly token: string;

  loading = false;

  private readonly destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private titleService: Title,
    private translate: TranslateService,
  ) {
    this.email = this.route.snapshot.queryParams.email;
    this.token = this.route.snapshot.params.token;

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(6), this.passwordConfirmationValidator]],
    });

    this.password.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.passwordConfirmation.updateValueAndValidity();
        },
      });
  }

  get password() { return this.passwordForm.get('password'); }
  get passwordConfirmation() { return this.passwordForm.get('passwordConfirmation'); }

  ngOnInit(): void {
    this.translate.get('BROWSER_TITLES.PASSWORD_RESET')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async setNewPassword(): Promise<void> {
    if (this.passwordForm.invalid) {
      this.password.markAsDirty();
      this.passwordConfirmation.markAsDirty();
      return;
    }

    try {
      this.loading = true;

      await this.authService.resetPassword({
        token: this.token,
        password: this.password.value,
        password_confirmation: this.passwordConfirmation.value,
        email: this.email,
      });
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        this.translate.instant('ERROR_MESSAGES.UNKNOWN_ERROR'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } finally {
      this.loading = false;
    }
  }

  private readonly passwordConfirmationValidator = (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    if (value !== this.password.value) {
      return { passwordsDontMatch: true };
    }
    return null;
  };
}
