import { Component } from '@angular/core';
import { PaymentStepService } from '../../form-steps/payment-step/services/payment-step.service';

@Component({
  selector: 'app-money-transfer-dialog',
  templateUrl: './money-transfer-dialog.component.html',
  styleUrls: ['./money-transfer-dialog.component.scss']
})
export class MoneyTransferDialogComponent {
  copyTooltip1Disabled = true;
  copyTooltip2Disabled = true;
  copyTooltip3Disabled = true;
  copyTooltip4Disabled = true;

  get totalFees(): number {
    return this.paymentStepService.paymentStepData.fees.reduce((sum, fee) => sum + fee.amount, 0);
  }

  constructor(
    private paymentStepService: PaymentStepService,
  ) { }

  async copyText(copiedText: string, tooltipNumber: number, tooltip) {
    this.setCopyTooltip(tooltipNumber, false);
    await navigator.clipboard.writeText(copiedText);
    tooltip.show();
    await this.delay(1000);
    this.setCopyTooltip(tooltipNumber, true);
  }

  setCopyTooltip(copyTooltipNumber: number, b: boolean) {
    switch (copyTooltipNumber) {
      case 1: {
        this.copyTooltip1Disabled = b;
        break;
      }
      case 2: {
        this.copyTooltip2Disabled = b;
        break;
      }
      case 3: {
        this.copyTooltip3Disabled = b;
        break;
      }
      case 4: {
        this.copyTooltip4Disabled = b;
        break;
      }
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
