<app-header-app xLink="/login"></app-header-app>
<app-form-template>
  <ng-template #titleTemplate>
    <div class="flex vcenter column title-wrapper">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green-big.svg"
        alt="checkmark-green-circle-big"
      />
      <h1>{{ "PASSWORD_RESET_SUCCESS.TITLE" | translate }}</h1>
    </div>
  </ng-template>

  <span>{{ "PASSWORD_RESET_SUCCESS.DESCRIPTION" | translate }}</span>

  <button payee-button width="wide" routerLink="/login">
    {{ "PASSWORD_RESET_SUCCESS.GO_TO_LOGIN" | translate }}
  </button>
</app-form-template>
