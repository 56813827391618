<div class="wrapper flex column vcenter">
  <div class="lottie-box flex vcenter">
    <lottie-player
      src="/assets/5595-success-animation.json"
      background="transparent"
      speed="1"
      autoplay
      style="transform: scale(0.65)"
    >
    </lottie-player>
  </div>
  <div class="title">{{ "PASSWORD_RESET_SUCCESS_DIALOG.TITLE" | translate }}</div>
  <div class="description">
    {{ "PASSWORD_RESET_SUCCESS_DIALOG.DESCRIPTION" | translate }}
  </div>
  <button mat-button class="accept" [mat-dialog-close]>
    {{ "PASSWORD_RESET_SUCCESS_DIALOG.BUTTON_OK" | translate }}
  </button>
</div>
