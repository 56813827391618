<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">
    {{ bankAccountFormService.title }}
  </div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Adja meg, hogy melyik bankszámlára kéri a követelés összegét.
  </div>

  <!--bank account form-->
  <div class="top-margin-30">
    <app-bank-account-data-box></app-bank-account-data-box>
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="bankAccountFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
