<div>
    <!--form form-steps-->
    <div class="top-margin-30">
        <div *ngFor="let formService of formServices; let i=index">
            <app-form-data-step
                [formService]="formService"
                (openStepButtonClick)="onStepDialogOpen(formService)">
            </app-form-data-step>
        </div>
    </div>
</div>
