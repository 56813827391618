<!--communication title-->
<div class="component-title top-margin-30">KOMMUNIKÁCIÓ NYELVE</div>

<!--communication language-->
<div class="top-margin-10">
  <payee-dropdown
    [formControl]="languageFormService.communicationLanguageFormControl"
    [options]="languageOptions"
    [displayWith]="languageOptionDisplayWith"
    height="tall"
    width="widest"
  >
    <ng-template #menuDisplayTemplate let-selected>
      <div class="flex vcenter" *ngIf="selected">
        <span class="grow1">{{ languageOptionLabels[selected] }}</span>
        <payee-icon
          class="comm-language-flag-icon"
          [name]="languageOptionIcons[selected]"
        ></payee-icon>
      </div>
    </ng-template>
  </payee-dropdown>
</div>
