import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AcceptTosDialogComponent } from '../../../dialogs/profile/accept-tos-dialog/accept-tos-dialog.component';
import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-cashbook-login-success',
  templateUrl: './cashbook-login-success.component.html',
  styleUrls: ['./cashbook-login-success.component.scss']
})
export class CashbookLoginSuccessComponent implements OnInit {
  ssoToken: string;
  loadingText = '';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.ssoToken = this.route.snapshot.queryParams.sso;
    if (!this.ssoToken) {
      await this.navigateToFailedCashbookLoginPage();
    }

    await this.login();
  }

  async login() {
    try {
      await this.loginWithAlreadyCreatedPayeeAccountFromCashbook();
    } catch (error) {
      if (error.details?.code === 'auth/user-not-found') {
        await this.createAndLoginWithPayeeAccountFromCashbook();
      } else if (error.message === 'Token not found') {
        await this.navigateToFailedCashbookLoginPage('token-not-found');
      } else {
        await this.navigateToFailedCashbookLoginPage('unknown');
      }
    }
  }

  async loginWithAlreadyCreatedPayeeAccountFromCashbook() {
    throw new Error('Unimplemented');
    // const generateAuthTokenForCashbookLogin = this.functions.httpsCallable('generateAuthTokenForCashbookLogin');

    // this.loadingText = 'Bejelentkezés...';
    // const result = await generateAuthTokenForCashbookLogin({ ssoToken: this.ssoToken });
    // await this.authService.loginWithCustomToken(result.data, 'PLUGIN_CASHBOOK');
    // if (await this.isClientAlreadyCreated()) {
    //   await this.router.navigate(['/user/cases']);
    // } else {
    //   await this.router.navigate(['/user/add-new-client-onboarding']);
    // }
  }

  async createAndLoginWithPayeeAccountFromCashbook() {
    this.loadingText = 'Fiók létrehozása...';
    const isTosAccepted = await this.openTosDialog();

    if (isTosAccepted) {
      try {
        const customLoginToken = await this.authService.createPayeeAccountFromCashbook(this.ssoToken);
        await this.authService.loginWithCustomToken(customLoginToken, 'PLUGIN_CASHBOOK');
        await this.router.navigate(['/user/add-new-client-onboarding']);
      } catch (error) {
        await this.navigateToFailedCashbookLoginPage('unknown');
      }
    } else {
      await this.router.navigate(['/login']);
    }
  }

  async openTosDialog() {
    const dialogRef = this.dialog.open(AcceptTosDialogComponent, {
      position: {
        top: '50px',
      }
    });

    const isAccepted = await dialogRef.afterClosed().toPromise();
    if (isAccepted) {
      return true;
    } else {
      return false;
    }
  }

  async navigateToFailedCashbookLoginPage(error?: string) {
    await this.router.navigate(['/_oauth/cashbook_login_failed'], {
      queryParams: {
        error: error,
      }
    });
  }

  // TODO
  async isClientAlreadyCreated(): Promise<boolean> {
    throw new Error('Unimplemented');
    // try {
    //   const userData = await this.usersService.getUser();
    //   if (userData.clientIds?.length === 0) {
    //     return false;
    //   }
    //   return true;
    // } catch (error) {
    //   await this.navigateToFailedCashbookLoginPage('unknown');
    // }
  }
}
