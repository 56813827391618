import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from '../../../services/helpers/helpers.service';
import {
  CompanySearchAutocompleteItem
} from '../../../aa-new-form/form-steps/init-step/models/common/company-search-autocomplete-item';
import { MatDialog } from '@angular/material/dialog';
import { ClientsService } from '../../../services/clients/clients.service';
import { CreateClientDto } from '../../../dtos/create-client.dto';
import { GroupTypeEnum } from '../../../aa-new-form/form-steps/init-step/models/common/group-type-enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormErrorSnackbarComponent } from '../../../snackbars/form-error-snackbar/form-error-snackbar.component';

@Component({
  selector: 'app-create-client-dialog',
  templateUrl: './create-client-dialog.component.html',
  styleUrls: ['./create-client-dialog.component.scss']
})
export class CreateClientDialogComponent implements OnInit {
  form: FormGroup;

  isClientSelected = false;
  isIndividual = false;
  loading = false;

  get showCloseButton(): boolean {
    return this.clientsService.clientsSubject.value.length > 0;
  }

  get nameFormControl() { return this.form.get('nameFormControl') as FormControl; }
  get taxNumberFormControl() { return this.form.get('taxNumberFormControl') as FormControl; }
  get countryFormControl() { return this.form.get('countryFormControl') as FormControl; }
  get postcodeFormControl() { return this.form.get('postcodeFormControl') as FormControl; }
  get settlementFormControl() { return this.form.get('settlementFormControl') as FormControl; }
  get streetFormControl() { return this.form.get('streetFormControl') as FormControl; }
  get motherNameFormControl() { return this.form.get('motherNameFormControl') as FormControl; }
  get birthPlaceFormControl() { return this.form.get('birthPlaceFormControl') as FormControl; }
  get birthDateFormControl() { return this.form.get('birthDateFormControl') as FormControl; }
  get accountCountryFormControl() { return this.form.get('accountCountryFormControl') as FormControl; }
  get accountHolderNameFormControl() { return this.form.get('accountHolderNameFormControl') as FormControl; }
  get accountNumberFormControl() { return this.form.get('accountNumberFormControl') as FormControl; }

  get address() {
    return this.postcodeFormControl.value + ' '
      + this.settlementFormControl.value + ', '
      + this.streetFormControl.value;
  }

  constructor(
      private formBuilder: FormBuilder,
      private helpersService: HelpersService,
      private dialog: MatDialog,
      private clientsService: ClientsService,
      private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
   this.buildEmptyForm();
  }

  buildEmptyForm() {
    this.form = this.formBuilder.group({
      nameFormControl: [],
      taxNumberFormControl: [],
      countryFormControl: [{
        value: 'Magyarország',
        disabled: true,
      }],
      postcodeFormControl: [],
      settlementFormControl: [],
      streetFormControl: [],
      motherNameFormControl: [
        '',
        [this.isIndividual ? Validators.required : Validators.nullValidator]
      ],
      birthDateFormControl: [
        null,
        [this.isIndividual ? Validators.required : Validators.nullValidator]
      ],
      birthPlaceFormControl: [
        '',
        [this.isIndividual ? Validators.required : Validators.nullValidator]
      ],
      accountCountryFormControl: [
        {
          value: 'Magyarország',
          disabled: true,
        },
        [Validators.required]
      ],
      accountHolderNameFormControl: [
        '',
        [Validators.required]
      ],
      accountNumberFormControl: [
        '',
        [Validators.required, this.helpersService.BBANValidator]
      ],
    });
  }

  async onSelectClient(autocompleteItem: CompanySearchAutocompleteItem) {
    this.isIndividual = autocompleteItem.type !== 'org';

    this.buildEmptyForm();

    this.nameFormControl.patchValue(autocompleteItem.name);
    this.taxNumberFormControl.patchValue(autocompleteItem.tax_number);
    this.countryFormControl.patchValue(autocompleteItem.country);
    this.postcodeFormControl.patchValue(autocompleteItem.postcode);
    this.settlementFormControl.patchValue(autocompleteItem.settlement);
    this.streetFormControl.patchValue(autocompleteItem.street);

    this.form.updateValueAndValidity();

    this.isClientSelected = true;
  }

  onDeleteClient() {
    this.buildEmptyForm();
    this.isClientSelected = false;
  }

  async onSave() {
    if (this.form.valid) {
      this.form.disable();
      this.loading = true;

      await this.persistData();
      this.dialog.closeAll();

      this.loading = false;
      this.form.enable();
    } else {
      this.helpersService.markAllChildrenAsDirty(this.form);
    }
  }

  private async persistData() {
    const createClientDto = this.getCreateClientDto();

    try {
      await this.clientsService.createNewClient(createClientDto);
    } catch (error) {
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
      console.error(error);
    }
  }

  private getCreateClientDto(): CreateClientDto {
    return {
      type: this.isIndividual ? GroupTypeEnum.INDIVIDUAL_WITH_TAX_NUMBER : GroupTypeEnum.OTHER_WITH_TAX_NUMBER,
      tax_number: this.taxNumberFormControl.value ?? '',
      birth_date: this.birthDateFormControl?.value?.format('YYYY-MM-DD') ?? '',
      birth_place: this.birthPlaceFormControl.value ?? '',
      mother_name: this.motherNameFormControl.value ?? '',
      account_number: this.accountNumberFormControl.value ?? '',
      account_holder_name: this.accountHolderNameFormControl.value ?? '',
    } as CreateClientDto;
  }
}


