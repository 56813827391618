<!--contact title-->
<div class="component-title top-margin-30">
  IDE KÜLDJÜK A FIZETÉSI FELSZÓLÍTÁSOKAT
</div>

<!--email addresses-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <input
      payee-input
      [formControl]="communicationFormService.emailAddressFormControl"
      [placeholder]="'finance@debtor.com'"
    />
    <ng-container
      payee-error
      *ngIf="communicationFormService.emailAddressFormControl.dirty"
    >
      <div
        *ngIf="
          communicationFormService.emailAddressFormControl.hasError('required')
        "
      >
        A mező kitöltése kötelező!
      </div>
      <div
        *ngIf="
          communicationFormService.emailAddressFormControl.hasError('email')
        "
      >
        Létező email címet adj meg!
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--explanation title-->
<div class="component-title top-margin-30">MEGJEGYZÉS</div>

<!--explanation-->
<div class="text-area-container top-margin-10">
  <payee-form-field width="widest" height="tall">
    <textarea
      [style.height]="'80px'"
      payee-input
      [formControl]="communicationFormService.descriptionFormControl"
      placeholder="Írd le, hogy mi történt az ügyben. Pl. A TT-2022-103 sorszámú számla nem lett kifizetve."
    ></textarea>
    <ng-container
      payee-error
      *ngIf="communicationFormService.descriptionFormControl.dirty"
    >
      <div
        *ngIf="
          communicationFormService.descriptionFormControl.hasError('required')
        "
      >
        A mező kitöltése kötelező!
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--info box-->
<div class="top-margin-10">
  <payee-info class="info-box debtor-info-box">
    <span class="info-box-text"> Az adósod ezt látni fogja. </span>
  </payee-info>
</div>

<!--tone of voice-->
<!-- <div class="top-margin-10">
  <payee-dropdown
    [formControl]="communicationFormService.toneOfVoiceFormControl"
    [options]="tovOptions"
    height="tall"
    width="widest"
    [displayWith]="tovOptionDisplayWith"
  >
  </payee-dropdown>
</div> -->

<!--info box-->
<!-- <div class="top-margin-10">
  <payee-info class="info-box tov-info-box">
    <span class="info-box-text">
      Mindegyik hangnem formális és barátságos, de a hivatalos hangnem egy
      távolságtartóbb, hivatalosabb, míg a barátságos hangnem egy közvetlenebb
      hangnem. Itt láthatsz példákat rá:
      <a
        color="blue-king"
        href="https://help.payee.tech/hu/articles/6251457-mi-lesz-kikuldve-az-adosnak-az-egyes-modulokban"
      >
        <strong>Tudásközpont</strong>
      </a>
    </span>
  </payee-info>
</div> -->

<!--partial payment title-->
<!--<div class="component-title top-margin-30">
  RÉSZLETFIZETÉS
</div>-->

<!--partial payment toggle-->
<!--<div class="toggle-wrapper top-margin-10">
  <div class="toggle-icon">
    <payee-icon *ngIf="communicationFormService.isPaymentPlanAllowedFormControl.value" [name]="'toggle-on'" (click)="onToggleClick(false)"></payee-icon>
    <payee-icon *ngIf="!communicationFormService.isPaymentPlanAllowedFormControl.value" [name]="'toggle-off'" (click)="onToggleClick(true)"></payee-icon>
  </div>
  <div class="toggle-text">Az adós fizethet részletekben</div>
</div>-->
