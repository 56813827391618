<div class="top-margin-30 information-container">
  <!--basic infos-->
  <div class="summary-item">
    <div class="summary-item-title">Hitelező</div>
    <div class="summary-item-value">
      {{ summaryStepService.summaryStepData.client_name }}
    </div>
  </div>
  <div class="summary-item top-margin-10">
    <div class="summary-item-title">Adós</div>
    <div class="summary-item-value">
      {{ summaryStepService.summaryStepData.debtor_name }}
    </div>
  </div>

  <!--claims-->
  <div class="summary-item top-margin-10">
    <div class="summary-item-title">Követelések</div>
    <div class="summary-item-title">
      {{
        summaryStepService.summaryStepData.displayed_claims_amount
          | number : "1.0" : "hu"
      }}
      {{ summaryStepService.summaryStepData.currency_iso }}
    </div>
  </div>
  <div class="top-margin-10">
    <div
      *ngFor="
        let claim of summaryStepService.summaryStepData.claims;
        let i = index
      "
    >
      <div class="summary-item">
        <div class="grey-text">
          <span
            >{{ i + 1 }}. követelés: (Lejárt:
            {{ claim.due_date_at | date : "yyyy.MM.dd." }})</span
          >
        </div>
        <div class="grey-text">
          <span>
            {{ claim.amount | number : "1.0" : "hu" }}
            {{ summaryStepService.summaryStepData.currency_iso }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
