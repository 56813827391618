<div class="container">
  <div
    class="box"
    [ngClass]="{ selected: type === 'notice' }"
    (click)="onSelectionChange('notice')"
  >
    <div class="soft-icon icon">
      <div class="icon-text">SOFT</div>
    </div>
    <div class="top-margin-20">
      <payee-icon [name]="'soft-icon'" width="auto" height="80px"></payee-icon>
    </div>
    <div
      class="title top-margin-20"
      [ngClass]="{ 'selected-title': type === 'notice' }"
    >
      Fizetési felszólítás
    </div>
    <div class="fees top-margin-10">
      <div>
        <span [ngClass]="{ 'selected-fees': type === 'notice' }">
          {{ productPrices?.soft_success | number : "1.0" : "hu" }}% sikerdíj
        </span>
        <!-- VAGY
        <span [ngClass]="{ 'selected-fees': type === 'notice' }">
          {{ productPrices?.soft_fix | number : "1.0" : "hu" }} Ft + ÁFA
        </span> -->
      </div>
    </div>
    <div
      class="description top-margin-15"
      [ngClass]="{ 'selected-description': type === 'notice' }"
    >
      Digitális felszólító üzenetek sorozata a Payee neve alól emailben
    </div>
    <div
      class="label top-margin-15"
      [ngClass]="{ 'selected-label': type === 'notice' }"
    >
      <div
        class="label-text"
        [ngClass]="{ 'selected-label-text': type === 'notice' }"
      >
        Magas megnyitási arány
      </div>
    </div>
  </div>

  <!--medium-->
  <div
    class="box"
    [ngClass]="{ selected: type === 'demand_letter' }"
    (click)="onSelectionChange('demand_letter')"
  >
    <div class="medium-icon icon">
      <div class="icon-text">MEDIUM</div>
    </div>
    <div class="top-margin-20">
      <payee-icon
        [name]="'demand-letter-icon'"
        width="auto"
        height="80px"
      ></payee-icon>
    </div>
    <div
      class="title top-margin-20"
      [ngClass]="{ 'selected-title': type === 'demand_letter' }"
    >
      Ügyvédi levél
    </div>
    <div class="fees top-margin-10">
      <div [ngClass]="{ 'selected-fees': type === 'demand_letter' }">
        {{ productPrices?.demand_letter_fix | number : "1.0" : "hu" }} Ft + ÁFA
      </div>
    </div>
    <div
      class="description top-margin-15"
      [ngClass]="{ 'selected-description': type === 'demand_letter' }"
    >
      Felszámolásban is használható tértivevényes ügyvédi felszólító levél
    </div>
    <div
      class="label top-margin-15"
      [ngClass]="{ 'selected-label': type === 'demand_letter' }"
    >
      <div
        class="label-text"
        [ngClass]="{ 'selected-label-text': type === 'demand_letter' }"
      >
        Ügyvéd által aláírva és postázva
      </div>
    </div>
  </div>

  <!--hard-->
  <div
    class="box"
    [ngClass]="{ selected: type === 'hard_1', opened: hardDropdownOpened }"
    (click)="onSelectionChange('hard_1')"
  >
    <div class="hard-icon icon">
      <div class="icon-text">HARD</div>
    </div>
    <div class="top-margin-20">
      <payee-icon [name]="'hard-icon'" width="auto" height="80px"></payee-icon>
    </div>
    <div
      class="title top-margin-20"
      [ngClass]="{ 'selected-title': type === 'hard_1' }"
    >
      Fizetési meghagyás
    </div>
    <div class="fees top-margin-10">
      <div [ngClass]="{ 'selected-fees': type === 'hard_1' }">
        {{ productPrices?.hard_fix | number : "1.0" : "hu" }} Ft + ÁFA (+ 3%
        illeték)
      </div>
    </div>
    <div *ngIf="!hardDropdownOpened" class="closed-dropdown">
      <div
        class="closed-dropdown-text"
        [ngClass]="{ 'selected-closed-dropdown-text': type === 'hard_1' }"
      >
        A VÉGREHAJTÁST IS KEZELJÜK
      </div>
      <div (click)="hardDropdownOpened = true">
        <payee-icon
          *ngIf="type === 'hard_1'"
          [name]="'arrow-down-blue'"
          [height]="'16px'"
        ></payee-icon>
        <payee-icon
          *ngIf="type !== 'hard_1'"
          [name]="'arrow-down-black'"
          [height]="'16px'"
        ></payee-icon>
      </div>
    </div>
    <div *ngIf="hardDropdownOpened" class="opened-dropdown top-margin-15">
      <div class="opened-dropdown-header">
        <div class="opened-dropdown-header-text">
          A VÉGREHAJTÁST IS KEZELJÜK
        </div>
        <div (click)="hardDropdownOpened = false">
          <payee-icon [name]="'arrow-up-black'" [height]="'16px'"></payee-icon>
        </div>
      </div>
      <div class="opened-dropdown-text">
        A fizetési meghagyás jogerőre emelkedésekor a végrehajtás online
        elindítható, díja
        {{ productPrices?.execution_fix | number : "1.0" : "hu" }} Ft + ÁFA (+ 1%
        illeték)
      </div>
    </div>
    <div
      class="description top-margin-15"
      [ngClass]="{ 'selected-description': type === 'hard_1' }"
    >
      Fizetési meghagyás kibocsátása közjegyző által és annak végrehajtása
    </div>
    <div
      class="label top-margin-15"
      [ngClass]="{ 'selected-label': type === 'hard_1' }"
    >
      <div
        class="label-text"
        [ngClass]="{ 'selected-label-text': type === 'hard_1' }"
      >
        Az egyedüli hivatalos jogi eszköz
      </div>
    </div>
  </div>
</div>
