<app-login-template title="{{ 'LOGIN.WELCOME_BACK' | translate }}">
  <div>
    <div class="input-label bottom-margin-10" color="grey-dark">
      {{ 'LOGIN.LOGIN_WITH' | translate }}
    </div>
    <a routerLink="/sso/szamlazzhu">
      <button
        type="button"
        payee-button
        width="wide"
        color="white"
        [isBorderButton]="true"
      >
        {{ 'LOGIN.SZAMLAZZ_HU' | translate }}
      </button>
    </a>
  </div>

  <payee-horizontal-line></payee-horizontal-line>

  <div>
    <span class="input-label" color="grey-dark">{{ 'LOGIN.NOT_REGISTERED_YET' | translate }}</span>&nbsp;
    <a color="blue-payee" routerLink="/signup">{{ 'LOGIN.SIGN_UP_NOW' | translate }}</a>
  </div>

  <div>
    <span class="input-label" color="grey-dark">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</span>&nbsp;
    <a color="blue-payee" routerLink="/forgot-password-get-link">
      {{ 'LOGIN.REQUEST_NEW_PASSWORD' | translate }}
    </a>
  </div>
</app-login-template>
