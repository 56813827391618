<div id="page-container" class="flex column" *ngIf="!loading">
  <div id="content-wrap" class="grow1">
    <!--header-->
    <app-form-header
      [selectedStepIndex]="0"
      [payeeId]="initStepService.payeeId"
      [partnerLogoName]="partnerLogoName"
    >
    </app-form-header>

    <!--middle part-->
    <div class="container">
      <div class="middle-part">
        <!--small title-->
        <h5 class="small-title" [ngSwitch]="initStepService.formType">
          <ng-container *ngSwitchCase="FormTypeEnum.SOFT"
            >Fizetési felszólítás indítása</ng-container
          >
          <ng-container *ngSwitchCase="FormTypeEnum.DEMAND_LETTER"
            >Ügyvédi levél indítása</ng-container
          >
          <ng-container *ngSwitchCase="FormTypeEnum.HARD"
            >Fizetési meghagyás indítása</ng-container
          >
        </h5>

        <!--title-->
        <div class="new-big-title top-margin-5">Adatmegadás</div>

        <!--description-->
        <div class="new-big-description top-margin-20">
          Adja meg a szükséges adatokat
        </div>

        <!--data entry-->
        <app-data-entry [formServices]="formServices"></app-data-entry>

        <!--buttons-->
        <div class="buttons-wrapper top-margin-30">
          <button
            payee-button
            [disabled]="!isBasicDataValid"
            (click)="onForwardButtonClick()"
            height="tall"
          >
            Tovább az összegzésre
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--footer-->
  <app-footer></app-footer>
</div>

<!--loading screen-->
<app-loading-screen *ngIf="loading"></app-loading-screen>
