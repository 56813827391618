export default {
  SHARED_FOOTER: {
    WHO_WE_ARE: 'Kik vagyunk mi?',
    LAW_PARTNER: 'Ügyvédi partnerünk',
    LEGAL_BACKGROUND: 'Jogi háttér',
    CONTACT: 'Kapcsolat',
    MISSION_STATEMENT: 'A küldetésünk, hogy a követeléskezelés könnyű és barátságos legyen, hogy a cégek tartósan növekedhessenek.',
    COPYRIGHT: '© Copyright 2024 Legal Labs Kft. | 1054 Budapest, Honvéd utca 8. | Cégjegyzékszám: 01-09-382985',
    TOS: 'ÁSZF',
    PRIVACY_POLICY: 'Adatkezelési tájékoztató',
    IMPRINT: 'Impresszum',
  },
};
