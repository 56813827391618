import { Component, OnInit } from '@angular/core';

export type SzamlazzhuSSOErrorId = 'invalid-login' | (string & {});

@Component({
  selector: 'app-szamlazzhu-sso-error',
  templateUrl: './szamlazzhu-sso-error.component.html',
  styleUrls: ['./szamlazzhu-sso-error.component.scss']
})

export class SzamlazzhuSsoErrorComponent implements OnInit {
  // TODO: Fix error message
  private errorId: SzamlazzhuSSOErrorId = 'invalid-login';
  public errorMsg: string;

  ngOnInit(): void {
    switch (this.errorId) {
      case 'invalid-login':
        this.errorMsg = 'Sikertelen Számlázz.hu bejelentkezés. Kérjük próbálja újra, amennyiben nem jár sikerrel, kérjük keresse fel ügyvédi irodánkat!';
        break;
      default:
        this.errorMsg = 'Ismeretlen hiba. Kérjük próbáljon újra bejelentkezni, amennyiben nem jár sikerrel, kérjük keresse fel ügyvédi irodánkat!';
        break;
    }
  }

}
