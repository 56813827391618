import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type EhazSsoResponse = {
  payee_case_id: string;
  access_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class EhazSsoApiService {
  async eHazSso(eHazToken: string): Promise<EhazSsoResponse> {
    const url = `${environment.baseUrl}/api/ehaz/sso/case/${eHazToken}`;
    const response = await axios.get<EhazSsoResponse>(url);
    return response.data;
  }
}
