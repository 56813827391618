<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon [name]="'cross-black'" (click)="onClose()"></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">{{ invoiceFormService.title }}</div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Kérjük, add meg, hogy milyen követeléseid vannak felé:
    {{ debtorFormService.debtorStepData?.debtor_data?.debtor?.name ?? "" }}
  </div>

  <!--invoices data component-->
  <app-invoice-data-box
    [loading]="loading"
    [waitingForInvoices]="!!ksziInfo?.invoices_pending"
    [(sortBy)]="sortBy"
    [(sortDirection)]="sortDirection"
    (sortByChange)="sortSubject.next()"
    (sortDirectionChange)="sortSubject.next()"
  >
  </app-invoice-data-box>

  <div
    *ngIf="!!ksziInfo?.invoices_pending"
    class="flex column vcenter hcenter spinner-container"
  >
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
      diameter="50"
    ></mat-progress-spinner>
    <span>
      Várhatóan még {{ ksziInfo.minutes_left }} perc az összes számlád betöltése
    </span>
  </div>

  <!--info box-->
  <div class="top-margin-30">
    <payee-info [width]="'widest'">
      Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz kell
      kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
    </payee-info>
  </div>

  <!--buttons-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="invoiceFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
