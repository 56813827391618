import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  resendPressed = false;
  loading = false;
  private readonly destroy = new Subject<void>();

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private titleService: Title,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.translate.get('BROWSER_TITLES.SIGNUP')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  xClicked = async () => {
    await this.authService.logout();
  };

  async resendVerifyEmail() {
    if (this.resendPressed || this.loading) {
      return;
    }

    try {
      this.resendPressed = true;
      this.loading = true;
      await this.authService.resendVerifyEmail();
      this.snackbar.open(
        this.translate.instant('VERIFY_TOKEN_EXPIRED.RESEND_SUCCESS'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } catch (error) {
      console.error(error);
      this.snackbar.open(
        this.translate.instant('ERROR_MESSAGES.UNKNOWN_ERROR'),
        this.translate.instant('OK'),
        {
          duration: 5000,
        },
      );
    } finally {
      this.loading = false;
      setTimeout(() => this.resendPressed = false, 15000);
    }
  }
}
