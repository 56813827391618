import { Component, Input, OnDestroy } from '@angular/core';
import { ClientsService, SelectedClient } from 'src/app/services/clients/clients.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;

  selectedClient: SelectedClient;
  clients: SelectedClient[];
  userInitials = '';

  readonly selectedClientSubscription: Subscription;
  readonly clientsSubscription: Subscription;

  private readonly subscriptionKiller = new Subject<void>();

  constructor(
    private clientsService: ClientsService,
    private usersService: UsersService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.selectedClientSubscription = this.clientsService.selectedClientSubject
      .pipe(takeUntil(this.subscriptionKiller))
      .subscribe(client => this.selectedClient = client);

    this.clientsSubscription = this.clientsService.clientsSubject
      .pipe(takeUntil(this.subscriptionKiller))
      .subscribe(clients => this.clients = clients);

    this.usersService.userSubject
      .pipe(takeUntil(this.subscriptionKiller))
      .subscribe(user => {
        if (!user) {
          this.userInitials = '';
          return;
        }
        const userNameParts = user.name?.split(' ', 2) ?? [];
        const first = userNameParts[0]?.[0] ?? '';
        const second = userNameParts[1]?.[0] ?? '';
        this.userInitials = first + second;
      });
  }

  get clientName() {
    return this.getClientName(this.selectedClient);
  }

  get shortClientName() {
    return this.clientName.split(' ').slice(0, 2).join(' ');
  }

  ngOnDestroy() {
    this.subscriptionKiller.next();
    this.subscriptionKiller.complete();
  }

  getClientName(client: SelectedClient) {
    return this.clientsService.getClientName(client);
  }

  onProfileClick() {
    this.router.navigate(['/user/user-profile']);
  }

  selectClient(client: SelectedClient) {
    this.clientsService.setSelectedClient(client);
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
