import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetInvoicesParams, GetKsziInfoResponse, SzamlazzhuService } from '../../../../../services/szamlazzhu/szamlazzhu.service';
import { SzamlazzhuInvoiceFormService } from '../../services/form-services/szamlazzhu-invoice-form-service';
import { Title } from '@angular/platform-browser';
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DebtorFormService } from '../../services/form-services/debtor-form-service';
import { InitStepService } from '../../services/init-step.service';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit, OnDestroy {
  loading = true;

  sortBy?: string;
  sortDirection?: string;
  sortSubject = new Subject<void>();

  private destroy = new Subject<void>();
  private ksziInfoInterval!: NodeJS.Timeout;
  public ksziInfo?: GetKsziInfoResponse;

  constructor(
    public debtorFormService: DebtorFormService,
    public invoiceFormService: SzamlazzhuInvoiceFormService,
    private szamlazzhuService: SzamlazzhuService,
    private titleService: Title,
    private snackbar: MatSnackBar,
    private initStepService: InitStepService,
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('Számla kiválasztása - Payee');

    this.sortSubject.pipe(
      takeUntil(this.destroy),
      debounceTime(250),
    )
      .subscribe({
        next: async () => await this.setInvoices(),
      });


    this.ksziInfoInterval = setInterval(async () => {
      const oldPending = this.ksziInfo?.invoices_pending ?? 0;
      await this.setKsziStatus();
      if (oldPending > (this.ksziInfo?.invoices_pending ?? 0)) {
        await this.setInvoices();
      }
    }, 10000);

    try {
      await this.setKsziStatus();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    clearInterval(this.ksziInfoInterval);
  }

  onClose() {
    this.invoiceFormService.openExitDialog();
  }

  async onSave() {
    await this.invoiceFormService.saveData();
  }

  private async setKsziStatus(): Promise<void> {
    try {
      this.ksziInfo = await this.szamlazzhuService.getKsziInfo();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.code === 'missing-szamlazzhu-data') {
          clearInterval(this.ksziInfoInterval);
          return;
        }
      }
    }
  }

  private async setInvoices(): Promise<void> {
    const params: GetInvoicesParams = {
      sort_by: this.sortBy,
      sort_direction: this.sortDirection,
    };
    if (!this.sortBy || !this.sortDirection) {
      delete params.sort_by;
      delete params.sort_direction;
    }

    const invoices = await this.szamlazzhuService.getInvoices(this.initStepService.getCaseUuid(), params);
    this.initStepService.setSzamlazzhuInvoiceStepData(invoices);
    this.invoiceFormService.buildForm();
  }
}
