import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { PaymentStepData } from "../models/payment-step-data";
import { Fee } from "../../summary-step/models/fee";
import axios from 'axios';
import { CasesService } from "src/app/services/cases/cases.service";
import { ClientsService } from "src/app/services/clients/clients.service";
import { filter, first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PaymentStepService {
  paymentStepData: PaymentStepData;

  private _caseUuid: string = '';
  private _partnerLogoName = '';

  get caseUuid(): string { return this._caseUuid; }
  get getPartnerLogoName(): string { return this._partnerLogoName; }

  constructor(
    private casesService: CasesService,
    private clientsService: ClientsService,
  ) { }

  setCaseUuid(caseUuid: string) {
    this._caseUuid = caseUuid;
  }

  async setPaymentStepData() {
    const data: any = await this.casesService.getCaseForm(this._caseUuid, 'payment');
    this.setData(data.payment_form_data);
  }

  setData(paymentStepData: PaymentStepData) {
    this.clientsService.clientsSubject
      .pipe(filter(v => !!v?.length), first())
      .subscribe({
        next: clients => {
          const client = clients.find(c => c.id === paymentStepData.client_id);
          this.clientsService.setSelectedClient(client);
        }
      });

    this.paymentStepData = {
      ...paymentStepData,
      fees: this.getFeesData(paymentStepData),
    } as PaymentStepData;
    this._partnerLogoName = paymentStepData.partner?.integration ?? '';
  }

  getFeesData(paymentStepData: PaymentStepData): Fee[] {
    const fees = [];

    for (const fee of paymentStepData.fees) {
      fees.push({
        label: fee.label,
        details_text: fee.details_text,
        amount: fee.amount
      } as Fee);
    }

    return fees;
  }
}
