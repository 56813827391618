import { Component, OnInit } from '@angular/core';
import { ClientFormService } from '../../services/form-services/client-form-service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss']
})
export class ClientDialogComponent implements OnInit {
  constructor(
    public clientFormService: ClientFormService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Kinek tartoznak? (Hitelező) - Payee');
  }

  onClose() {
    this.clientFormService.openExitDialog();
  }

  async onSave() {
    await this.clientFormService.saveData();
  }
}
