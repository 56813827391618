export interface Debtor {
  name: string,
  tax_number: string,
  registration_number: string,
}

export function getEmptyDebtor(): Debtor {
  return {
    name: '',
    tax_number: '',
    registration_number: ''
  };
}
