import {Component} from '@angular/core';
import {SummaryStepService} from "../../services/summary-step.service";

@Component({
  selector: 'app-summary-description-box',
  templateUrl: './summary-description-box.component.html',
  styleUrls: ['./summary-description-box.component.scss']
})
export class SummaryDescriptionBoxComponent {

  constructor(
      public summaryStepService: SummaryStepService
  ) { }
}
