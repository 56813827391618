<app-header-empty></app-header-empty>

<div *ngIf="isFirstClient" class="confetti-container">
  <img
    class="confetti"
    src="assets/shared/global-images/big-svgs/confetti.svg"
  />
</div>

<app-form-template>
  <ng-template #titleTemplate>
    <div class="title flex column vcenter">
      <img
        src="assets/shared/global-images/icons/checkmark-circle-green-big.svg"
      />
      <h2 color="grey-dark">
        Gratulálunk! Hitelezői fiók sikeresen létrehozva:
      </h2>
      <h1>{{ clientName }}</h1>
    </div>
  </ng-template>

  <div class="description">
    Sikeresen létrehoztad a
    <strong>felhasználódhoz tartozó Hitelezői fiókodat.</strong> A
    felhasználódban bármikor tudsz váltani a Hitelezői fiókjaid között.
  </div>

  <button payee-button width="wide" routerLink="/cases/wizard">
    Ügy indítása most
  </button>
  <button
    payee-button
    width="wide"
    color="white"
    [isBorderButton]="true"
    routerLink="/user/client-profile"
  >
    Vezérlőpult
  </button>
</app-form-template>
