<h2>Az Ön cégének bankszámlája</h2>
<form [formGroup]="form" [class.valid]="form.valid">
  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="account_holder_name"
        placeholder="Kedvezményezett neve"
      />
      <ng-container
        payee-error
        *ngIf="account_holder_name.invalid && account_holder_name.dirty"
      >
        <span *ngIf="account_holder_name.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="account_holder_name.hasError('unknown')">{{
          account_holder_name.errors.unknown
        }}</span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="account_number"
        placeholder="Bankszámlaszám"
      />
      <ng-container
        payee-error
        *ngIf="account_number.invalid && account_number.dirty"
      >
        <span *ngIf="account_number.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="account_number.hasError('pattern')">
          Helytelen formátum
        </span>
        <span *ngIf="account_number.hasError('unknown')">
          {{ account_number.errors.unknown }}
        </span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input payee-input formControlName="bic" placeholder="BIC" />
      <ng-container payee-error *ngIf="bic.invalid && bic.dirty">
        <span *ngIf="bic.hasError('required')">
          A mező kitöltése kötelező
        </span>
        <span *ngIf="bic.hasError('unknown')">{{ bic.errors.unknown }}</span>
      </ng-container>
    </payee-form-field>
  </div>
</form>
