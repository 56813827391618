import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { SzamlazzhuInvoiceFormService } from '../../../services/form-services/szamlazzhu-invoice-form-service';
import { environment } from 'src/environments/environment';
import { SzamlazzhuInvoice } from '../../../models/szamlazzhu-invoice/szamlazzhu-invoice';
import { MatTableDataSource } from '@angular/material/table';
import { SzamazzhuInvoiceGroup } from '../../../models/szamlazzhu-invoice/szamlazzhu-invoice-step-data';
import { InitStepService } from '../../../services/init-step.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-invoice-data-box',
  templateUrl: './invoice-data-box.component.html',
  styleUrls: ['./invoice-data-box.component.scss']
})
export class InvoiceDataBoxComponent implements OnInit, OnDestroy {
  environment = environment;

  @Input() waitingForInvoices = false;
  @Input() loading = false;

  @Input() sortBy?: string;
  @Output() sortByChange = new EventEmitter<string | null>();
  @Input() sortDirection?: string;
  @Output() sortDirectionChange = new EventEmitter<string | null>();

  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (sort === this.dataSource.sort) {
      return;
    }
    this.dataSource.sort = sort;
  }

  dataSource = new MatTableDataSource<SzamazzhuInvoiceGroup>();
  readonly displayedColumns: string[] = [
    'selected',
    'invoice_number',
    'due_date_at',
    'remaining_amount',
  ];

  readonly invoiceLabels = {
    "SZ": "Számla",
    "JS": "",
    "HS": "Helyesbítő számla",
    "ES": "Előlegszámla",
    "VS": "Végszámla",
    "D": "",
    "SL": "",
  };

  get szamlazzhuUrl() { return environment.szamlazzhu.url; }
  get showTable() { return this.waitingForInvoices || this.invoiceFormService.szamlazzhuInvoiceStepData.invoices.length > 0; }

  private readonly destroy = new Subject<void>();

  constructor(
    public invoiceFormService: SzamlazzhuInvoiceFormService,
    private initStepService: InitStepService,
  ) { }

  ngOnInit() {
    this.dataSource.data = this.invoiceFormService.szamlazzhuInvoiceStepData.invoices;
    this.initStepService.getSzamlazzhuInvoiceStepDataListener()
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: data => this.dataSource.data = data.invoices,
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onAllSelected(selected: boolean) {
    this.invoiceFormService.setAllInvoiceFormControlValue(selected);
  }

  onSort(sort: Sort) {
    this.sortByChange.emit(sort.active);
    this.sortDirectionChange.emit(sort.direction);
  }

  correctionTrackBy(invoice: SzamazzhuInvoiceGroup): (_: SzamlazzhuInvoice) => string {
    return (correction: SzamlazzhuInvoice) => {
      return `${invoice.invoice.invoice_id}-correction-${correction.invoice_id}`;
    };
  }
}
