<div class="wrapper flex column vcenter">
  <div class="title">{{ "ACCEPT_TERMS_DIALOG.TITLE" | translate }}</div>
  <div class="description">
    {{ "ACCEPT_TERMS_DIALOG.DESCRIPTION" | translate }}
  </div>

  <form [formGroup]="formGroup">
    <app-custom-checkbox formControlName="pp">
      <span class="checkbox-text">
        <a href="https://www.payee.tech/legal/privacy-policy" target="_blank">
          {{ "ACCEPT_TERMS_DIALOG.CHECKBOXES.PRIVACY_POLICY" | translate }}
        </a>
      </span>
    </app-custom-checkbox>
    <app-custom-checkbox formControlName="aszf">
      <span class="checkbox-text">
        <a
          href="https://www.payee.tech/legal/terms-and-conditions"
          target="_blank"
        >
          {{
            "ACCEPT_TERMS_DIALOG.CHECKBOXES.TERMS_AND_CONDITIONS" | translate
          }}
        </a>
      </span>
    </app-custom-checkbox>
  </form>

  <button
    mat-button
    class="accept-button"
    [disabled]="formGroup.invalid"
    (click)="onClose()"
    [mat-dialog-close]="true"
  >
    {{ "ACCEPT_TERMS_DIALOG.CONFIRM_BUTTON" | translate }}
  </button>
</div>
