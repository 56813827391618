import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-add-new-client-onboarding',
  templateUrl: './add-new-client-onboarding.component.html',
  styleUrls: ['./add-new-client-onboarding.component.scss']
})
export class AddNewClientOnboardingComponent {

  constructor(
    private authService: AuthService,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Első lépés - Payee');
  }

  async logout() {
    await this.authService.logout();
  }
}
