import { Injectable } from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export type ApiKey = {
  value: string;
  partner: string;
  created_at: string;
};

export type GetApiKeysResponse = {
  apikeys: ApiKey[];
};

export type CreateApiKeyResponse = {
  apikey: ApiKey;
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private clientsService: ClientsService,
  ) { }

  async getApiKeys(clientId = this.clientsService.selectedClientId): Promise<GetApiKeysResponse> {
    const url = `${environment.baseUrl}/api/${clientId}/apikey`;
    const result = await axios.get<GetApiKeysResponse>(url);
    return result.data;
  }

  async deleteApiKey(apiKey: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    const url = `${environment.baseUrl}/api/${clientId}/apikey/${apiKey}`;
    await axios.delete(url);
  }

  async generateApiKey(clientId = this.clientsService.selectedClientId): Promise<CreateApiKeyResponse> {
    const url = `${environment.baseUrl}/api/${clientId}/apikey`;
    const result = await axios.post<CreateApiKeyResponse>(url);
    return result.data;
  }
}
