import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['./bank-account-form.component.scss']
})
export class BankAccountFormComponent {
  @Input() countryFormControl: FormControl;
  @Input() accountHolderNameFormControl: FormControl;
  @Input() accountNumberFormControl: FormControl;

  constructor() { }
}
