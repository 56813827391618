import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';

@Component({
  selector: 'app-cashbook-login-failed',
  template: '',
})
export class CashbookLoginFailedComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    const error = this.route.snapshot.queryParams.error;

    if (!error) {
      this.router.navigate(['/login']);
      return;
    }

    let message: string;
    let actionLink: string;
    let action: string;
    if (error === 'token-not-found') {
      action = 'OK',
        message = 'Ez a token lejárt. Jelentkezz be újra!';
    } else {
      action = 'Bejelentés';
      message = 'Valami hiba történt!';
      actionLink = 'https://airtable.com/shri3NFvm46oX3rhd';
    }

    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {
        message,
        action,
        actionLink,
      },
      duration: 10000,
    });

    this.router.navigate(['/login'], {
      queryParams: {},
    });
  }
}
