import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import payeeIconConfig from '../../payee-icon/payee-icon-config';

@Injectable({
  providedIn: 'root'
})
export class PartnerLogoService {
  getPartnerLogoName(integration: string | null): string | null {
    if (!integration) {
      return environment.LOGO_ICON || 'payee-logo';
    }
    
    const key = `${integration}-logo`;

    if (key in payeeIconConfig) {
      return key;
    }

    return null;
  }

  getPartnerMiniLogoName(integration: string | null): string | null {
    if (!integration) {
      return environment.LOGO_ICON || 'payee-mini';
    }
    
    const key = `${integration}-mini`;

    if (key in payeeIconConfig) {
      return key;
    }

    return null;
  }
}
