import {Component} from '@angular/core';
import {FormTypeEnum} from "../../../init-step/models/common/form-type-enum";
import {SummaryStepService} from "../../services/summary-step.service";

@Component({
  selector: 'app-summary-start-case-fee-box',
  templateUrl: './summary-start-case-fee-box.component.html',
  styleUrls: ['./summary-start-case-fee-box.component.scss']
})
export class SummaryStartCaseFeeBoxComponent {
  get isHardForm() {
    return this.summaryStepService.summaryStepData.type === FormTypeEnum.HARD;
  }

  constructor(
      public summaryStepService: SummaryStepService
  ) { }
}
