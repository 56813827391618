import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { FormFactory, FormName } from './form.factory';
import { formComponents } from './form.components';
import { BankAccount, Claim, Client, Debtor, ProductType } from './form.resources';
import { BehaviorSubject } from 'rxjs';
import { TransformFormDataService } from 'src/app/aa-new-form/form-steps/init-step/services/transform-form-data.service';
import { FormTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/form-type-enum';

export type Case = {
  id: string;
  payee_case_reference_id: string;
  type: FormTypeEnum;
};

export type FormStepName = 'debtor' | 'client' | 'payment' | 'payment-redirect';
export const formStepNames: FormStepName[] = [
  'debtor',
  'client',
  'payment',
  'payment-redirect',
];
export const nextFormStep: Record<FormStepName, FormStepName | null> = {
  "payment-redirect": null,
  client: 'payment',
  debtor: 'client',
  payment: null,
};

export type FormStepResponse = {
  forms: Record<FormName, unknown>;
  payee_case: Case;
};

export type SelectOptionalClaimsParams = {
  is_flat_rate_cost_claim_added: boolean;
  is_interest_added: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class FormService {
  forms: Record<FormName, unknown>;
  case: Case;

  readonly form: FormGroup = new FormGroup({});
  caseId: string;

  readonly saving = new BehaviorSubject<number>(0);
  readonly justSaved = new BehaviorSubject<number>(0);
  readonly paymentLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: FormBuilder,
    private transformFormDataService: TransformFormDataService,
  ) { }

  setCaseId(caseId: string): FormService {
    this.caseId = caseId;
    return this;
  }

  async setFormData(step: FormStepName): Promise<void> {
    const url = `${environment.baseUrl}/api/case-form/${this.caseId}/step/${step}`;
    const result = await axios.get<FormStepResponse>(url);
    this.forms = result.data.forms;
    this.case = result.data.payee_case;
  }

  async upsertDebtor(params: Debtor): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/debtor`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async upsertClaim(params: Claim): Promise<Claim> {
    return await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/claim`;
      const formData = this.transformFormDataService.objectToFormData(params);
      const result = await axios.post<{ claim: Claim; }>(url, formData, { timeout: 5000 });
      return result.data.claim;
    });
  }

  async deleteClaim(id: string): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/claim/${id}`;
      await axios.delete(url, { timeout: 5000 });
    });
  }

  async updateClient(params: Client): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/client`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async upsertBankAccount(params: BankAccount): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/bank-account`;
      await axios.post(url, params, { timeout: 5000 });
    });
  }

  async selectOptionalClaims(params: SelectOptionalClaimsParams): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/select-optional-claims`;
      const result = await axios.post<FormStepResponse>(url, params);
      this.forms = result.data.forms;
      this.setForms();
    });
  }

  async changeProduct(product_type: ProductType): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/change-product/${product_type}`;
      const result = await axios.post<FormStepResponse>(url);
      this.forms = result.data.forms;
      this.setForms();
    });
  }

  async upsertDebtorContacts(email: string): Promise<void> {
    await this.save(async () => {
      const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/debtor-contacts`;
      await axios.post(url, {
        email,
      });
    });
  }

  async startWithCard(): Promise<void> {
    if (this.paymentLoading.value) {
      return;
    }

    await this.save(async () => {
      try {
        this.paymentLoading.next(true);
        const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/start-with-card`;
        const result = await axios.post<{ payment_url: string; }>(url);
        window.location.href = result.data.payment_url;
      } finally {
        this.paymentLoading.next(false);
      }
    });
  }

  async startWithTransfer(): Promise<void> {
    if (this.paymentLoading.value) {
      return;
    }

    await this.save(async () => {
      try {
        this.paymentLoading.next(true);
        const url = `${environment.baseUrl}/api/case-form/${this.caseId}/payment/start-with-transfer`;
        await axios.post(url);
      } finally {
        this.paymentLoading.next(false);
      }
    });
  }

  getFormComponents(): any[] {
    return Object.keys(this.forms).map(key => formComponents[key]);
  }

  setForms(): FormService {
    Object.keys(this.form.controls).forEach(key => this.form.removeControl(key));

    Object.entries(this.forms).forEach(([formName, data]) => {
      const form = new FormFactory(this, this.fb, data)
        .getForm(formName as FormName);

      this.form.addControl(formName, form);
    });

    return this;
  }

  private async save<T>(callback: () => Promise<T>): Promise<T> {
    try {
      this.saving.next(this.saving.value + 1);

      const result = await callback();

      this.justSaved.next(this.justSaved.value + 1);
      setTimeout(() => this.justSaved.next(this.justSaved.value - 1), 2500);

      return result;
    } finally {
      setTimeout(() => this.saving.next(this.saving.value - 1), 200);
    }
  }
}
