<!--uploaded file-->
<div *ngIf="claimFormService.getClaimFileFormGroup(selectedClaimIndex).valid" class="uploaded-file">
  <payee-icon [name]="'uploaded-document'"></payee-icon>
  <div class="name">
   {{claimFormService.getClaimFileNameFormControl(selectedClaimIndex).value}}
  </div>
  <div *ngIf="isFileDeletable">
    <payee-icon class="file-trash" (click)="deleteClaimFile()" [name]="environment.TRASH_ICON"></payee-icon>
  </div>
</div>

<!-- dropzone-->
<div *ngIf="claimFormService.getClaimFileFormGroup(selectedClaimIndex).invalid" class="claim-evidence-dropzone" >
  <payee-dropzone [multiple]="false" [width]="'widest'" (dropped)="filesSelected($event)"></payee-dropzone>
</div>
