<app-header-empty></app-header-empty>
<app-form-template
  title="Kérjük írja be az emailben kapott kódot"
  [formGroup]="twofaForm"
  [submit]="submitToken"
>
  <payee-form-field width="wide">
    <input payee-input formControlName="code" />
    <span payee-error *ngIf="code.hasError('required') && code.dirty">
      A mező kitöltése kötelező!
    </span>
  </payee-form-field>
  <button payee-button width="wide" [disabled]="loading || twofaForm.invalid">
    Megerősítés
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
  <button
    [isBorderButton]="true"
    payee-button
    width="short"
    height="shorter"
    color="white"
    [disabled]="resendLoading"
    (click)="resendCode()"
  >
    Kód újraküldése
  </button>
</app-form-template>
