import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-case-start-success-dialog',
  templateUrl: './case-start-success-dialog.component.html',
  styleUrls: ['./case-start-success-dialog.component.scss']
})
export class CaseStartSuccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, description: string }) { }
}
