import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    PayeeIconModule,
    PayeeButtonsModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class HeaderModule { }
