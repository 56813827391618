import { Component } from '@angular/core';

@Component({
  selector: 'app-optional-claim-title',
  templateUrl: './optional-claim-title.component.html',
  styleUrls: ['./optional-claim-title.component.scss']
})
export class OptionalClaimTitleComponent {

  constructor() { }
}
