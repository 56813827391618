<app-header-empty></app-header-empty>
<app-form-template title="{{ 'VERIFY_EMAIL.TITLE' | translate }}">
  <div class="description">
    {{ "VERIFY_EMAIL.DESCRIPTION" | translate }}
  </div>

  <payee-horizontal-line></payee-horizontal-line>

  <div class="flex vcenter hcenter">
    <span color="grey-dark">{{
      "VERIFY_EMAIL.EMAIL_NOT_RECIEVED" | translate
    }}</span
    >&nbsp;
    <a
      class="resend-link"
      [attr.color]="!resendPressed ? 'blue-payee' : 'grey-medium'"
      (click)="resendVerifyEmail()"
    >
      {{ "VERIFY_EMAIL.RESEND" | translate }}
    </a>
    <ng-container *ngIf="loading">
      &nbsp;
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
      </mat-progress-spinner>
    </ng-container>
  </div>
</app-form-template>
