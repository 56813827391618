import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AxiosError } from 'axios';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  readonly registerForm: FormGroup;
  loading = false;

  private utmSource?: string;
  private utmCampaign?: string;
  private utmContent?: string;
  private utmMedium?: string;
  private readonly destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private titleService: Title,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      ppAndAszf: [false, [Validators.requiredTrue]],
    });
  }

  ngOnInit() {
    this.translate.get('BROWSER_TITLES.SIGNUP')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });

    const queryParams: Record<string, string> = this.route.snapshot.queryParams;
    const referralId = queryParams.ref;
    if (referralId) {
      this.localStorageService.saveItem('referralId', referralId);
    }

    this.utmCampaign = queryParams.utm_campaign;
    this.utmSource = queryParams.utm_source;
    this.utmContent = queryParams.utm_content;
    this.utmMedium = queryParams.utm_medium;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  get email() { return this.registerForm.get('email'); }
  get ppAndAszf() { return this.registerForm.get('ppAndAszf'); }

  readonly register = async () => {
    if (this.registerForm.invalid || this.loading) {
      this.email.markAsDirty();
      this.ppAndAszf.markAsDirty();
      return;
    }
    try {
      this.loading = true;
      await this.authService.register({
        email: this.email.value,
        is_privacy_accepted: !!this.ppAndAszf.value,
        is_terms_accepted: !!this.ppAndAszf.value,
        utm_campaign: this.utmCampaign,
        utm_source: this.utmSource,
        utm_content: this.utmContent,
        utm_medium: this.utmMedium,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errors?.email?.includes('The email has already been taken.')) {
          this.email.setErrors({ used: true });
          return;
        }
        this.registerForm.setErrors({
          unknown: error.response?.data?.message ?? this.translate.instant('ERROR_MESSAGES.UNKNOWN_ERROR'),
        });
      } else {
        console.error('Unknown error during signup', error);
      }
    } finally {
      this.loading = false;
    }
  };
}
