<div class="wrapper">
  <div class="flex column grow1">
    <div class="flex grow1">
      <div class="flex column hcenter grow1">
        <div class="flex subtitle" *ngIf="subtitle">{{ subtitle }}</div>
        <div class="flex title" *ngIf="title">{{ title }}</div>
      </div>
      <div class="flex vcenter" style="padding-top: 31px; padding-bottom: 20px">
        <button
          mat-button
          class="client-selector-button"
          [matMenuTriggerFor]="menu"
        >
          <div class="flex vcenter">
            <span class="grow1">{{ clientName }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu xPosition="before">
          <button
            mat-menu-item
            *ngFor="let client of clients"
            (click)="selectClient(client)"
          >
            {{ getClientName(client) }}
          </button>
          <button mat-menu-item class="profile" routerLink="/user/user-profile">
            <span>{{ "HEADER.PROFILE" | translate }}</span>
            <span class="menu-icon" *ngIf="!!userInitials">
              <span class="profile-icon">{{ userInitials }}</span>
              <span class="circle"></span>
            </span>
          </button>
          <button mat-menu-item class="logout" (click)="logout()">
            <span>{{ "HEADER.LOGOUT" | translate }}</span>
            <payee-icon
              class="menu-icon"
              name="logout-red"
              height="20px"
            ></payee-icon>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
