import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { CasesService, GetCardPaymentStatus, PaymentStatus } from 'src/app/services/cases/cases.service';
import { Case, FormService, FormStepName, formStepNames, nextFormStep } from 'src/app/services/form/form.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { errorResponse } from 'src/types/error-response';
import { z } from 'zod';

export const caseNotDraftError = z.object({
  code: z.literal('case-not-draft'),
  payee_case_reference_id: z.string(),
  product_type: z.string(),
});

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss']
})
export class CaseFormComponent implements OnInit {
  formComponents: Component[] = [];
  get case(): Case { return this.formService.case; };
  private canShowSpinnerTimeout?: NodeJS.Timeout;
  canShowSpinner = false;
  loading = true;
  private caseId!: string;
  private step!: FormStepName;

  private readonly paymentInProgressStatuses: PaymentStatus[] = [
    'Started',
    'InProgress',
    'Waiting',
    'Reserved',
    'Authorized',
  ];
  private paymentStatus?: GetCardPaymentStatus;
  private paymentRedirectRetryInterval?: NodeJS.Timeout;
  private retryCount = 10;

  get saving(): boolean { return this.formService.saving.value > 0; }
  get justSaved(): boolean { return this.formService.justSaved.value > 0; }
  get showNextPageButton(): boolean { return !!nextFormStep[this.step]; }

  constructor(
    private casesService: CasesService,
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormService,
    private helpersService: HelpersService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe({
      next: async params => {
        this.canShowSpinnerTimeout = setTimeout(() => this.canShowSpinner = true, 200);
        this.loading = true;
        this.caseId = params.uuid;
        if (!this.caseId) {
          const result = await this.casesService.createCase({
            product_type: 'hard_1',
          });
          this.router.navigateByUrl(`case/${result.id}`);
          return;
        }
        this.formService.setCaseId(this.caseId);

        this.step = params.step as FormStepName;
        const paymentId = this.route.snapshot.queryParams.paymentId;
        if (
          !formStepNames.includes(this.step)
          || (this.step === 'payment-redirect' && !paymentId)
        ) {
          this.router.navigateByUrl(`case/${this.caseId}/debtor`);
          this.step = 'debtor';
        }

        await this.setForm();
        if (this.step === 'payment-redirect') {
          await this.checkPayment();
        }

        clearTimeout(this.canShowSpinnerTimeout);
        this.canShowSpinner = false;
        this.loading = false;
      }
    });
  }

  nextPage(): void {
    if (this.formService.form.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.formService.form);
      return;
    }

    if (nextFormStep[this.step]) {
      this.router.navigateByUrl(`case/${this.caseId}/${nextFormStep[this.step]}`);
    }
  }

  private async setForm(): Promise<void> {
    try {
      await this.formService.setFormData(this.step);
      this.formService.setForms();
      this.formComponents = this.formService.getFormComponents();
    } catch (error) {
      if (error instanceof AxiosError) {
        const notDraftError = caseNotDraftError.safeParse(error.response?.data);
        if (notDraftError.success) {
          this.router.navigate(['/user/cases'], {
            queryParams: {
              filterType: 'payeeId',
              filter: notDraftError.data.payee_case_reference_id,
            },
          });
          return;
        }

        const notValidError = errorResponse.safeParse(error.response?.data);
        if (notValidError.success) {
          this.router.navigateByUrl(`case/${this.caseId}/debtor`);
          return;
        }

        this.router.navigate(['/user/cases']);
        console.error('Error while loading case', error);
        return;
      }
    }
  }

  private async checkPayment(): Promise<void> {
    return new Promise((res, rej) => {
      this.paymentRedirectRetryInterval = setInterval(async () => {
        try {
          const paymentId = this.route.snapshot.queryParams.paymentId;
          this.paymentStatus = await this.casesService.getCardPaymentStatus(paymentId, this.caseId);
        } catch (error) {
          console.error('Error while getting payment status', error);
          await this.router.navigateByUrl(`case/${this.caseId}/payment`);
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        if (this.paymentStatus?.status === 'Succeeded') {
          this.casesService.caseOpenedGtmEvent(
            this.case.type,
            this.case.payee_case_reference_id,
          );
          await this.router.navigate(['/user/cases'], {
            queryParams: {
              filter: this.case.payee_case_reference_id,
              filterType: 'payeeId',
            },
          });
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        } else if (!this.paymentInProgressStatuses.includes(this.paymentStatus.status)) {
          await this.router.navigateByUrl(`case/${this.caseId}/payment`);
          clearInterval(this.paymentRedirectRetryInterval);
          res();
          return;
        }

        --this.retryCount;
        if (this.retryCount <= 0) {
          clearInterval(this.paymentRedirectRetryInterval);
          await this.router.navigateByUrl(`case/${this.caseId}/payment`);
          res();
          return;
        }
      }, 1000);
    });
  }
}
