<form [formGroup]="form">
  <div class="form-group-label top-margin-15">Mi az adós neve?</div>
  <payee-form-field class="name-form-field">
    <input
      payee-input
      formControlName="name"
      placeholder="Írja be a cég nevét, aki tartozik"
      [matAutocomplete]="auto"
    />
    <ng-container *ngIf="name.invalid && name.dirty" payee-error>
      <span *ngIf="name.hasError('required')">A mező kitöltése kötelező</span>
      <span *ngIf="name.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
    </ng-container>
    <span payee-suffix *ngIf="autocompleteSearchLoading">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="24"
      ></mat-progress-spinner>
    </span>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="companySelected($event)"
    >
      <mat-option *ngFor="let option of companySearchOptions" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </payee-form-field>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <payee-autocomplete
        formControlName="party_type_id"
        [options]="partyTypeOptions"
        [displayWith]="partyTypeDisplayWith"
        placeholder="Cég típusa"
      >
        <ng-template #menuDisplayTemplate>
          <span>{{ (debtorPartyType | async)?.label }}</span>
        </ng-template>
      </payee-autocomplete>
      <ng-container *ngIf="party_type_id.invalid && party_type_id.dirty" payee-error>
        <span *ngIf="party_type_id.hasError('required')">A mező kitöltése kötelező</span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="top-margin-15">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        formControlName="tax_number"
        placeholder="Cég adószáma"
      />
      <ng-container *ngIf="tax_number.invalid && tax_number.dirty" payee-error>
        <span *ngIf="tax_number.hasError('required')">A mező kitöltése kötelező</span>
        <span *ngIf="tax_number.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
      </ng-container>
    </payee-form-field>
  </div>

  <div class="form-group-label top-margin-15">Hol érhetjük el az adóst?</div>
  <form formGroupName="address">
    <div class="top-margin-15">
      <payee-dropdown
        formControlName="country_iso"
        [options]="countryOptions"
        width="widest"
        height="tall"
        [displayWith]="countryDisplayWith"
      >
        <ng-template #menuDisplayTemplate>
          <span>{{ debtorCountry.value?.name }}</span>
        </ng-template>
      </payee-dropdown>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="postcode"
          placeholder="Irányítószám"
        />
        <ng-container *ngIf="debtorPostcode.invalid && debtorPostcode.dirty" payee-error>
          <span *ngIf="debtorPostcode.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="debtorPostcode.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="settlement"
          placeholder="Település"
        />
        <ng-container *ngIf="debtorSettlement.invalid && debtorSettlement.dirty" payee-error>
          <span *ngIf="debtorSettlement.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="debtorSettlement.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>

    <div class="top-margin-15">
      <payee-form-field width="widest" height="tall">
        <input
          payee-input
          formControlName="street"
          placeholder="Utca és házszám"
        />
        <ng-container *ngIf="debtorStreet.invalid && debtorStreet.dirty" payee-error>
          <span *ngIf="debtorStreet.hasError('required')">A mező kitöltése kötelező</span>
          <span *ngIf="debtorStreet.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
        </ng-container>
      </payee-form-field>
    </div>
  </form>

  <ng-container *ngIf="form.get('representative').enabled">
    <div class="form-group-label top-margin-15">
      Mi a {{ name.value || "adós" }} képviselőjének neve és címe?
    </div>
  
    <form formGroupName="representative">
      <div class="top-margin-15">
        <payee-form-field width="widest" height="tall">
          <input
            payee-input
            formControlName="name"
            placeholder="Írja be a képviselő nevét..."
          />
          <ng-container *ngIf="repName.invalid && repName.dirty" payee-error>
            <span *ngIf="repName.hasError('required')">A mező kitöltése kötelező</span>
            <span *ngIf="repName.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
          </ng-container>
        </payee-form-field>
      </div>
  
      <form formGroupName="address">
        <div class="top-margin-15">
          <payee-dropdown
            formControlName="country_iso"
            [options]="countryOptions"
            width="widest"
            height="tall"
            [displayWith]="countryDisplayWith"
          >
            <ng-template #menuDisplayTemplate>
              <span>{{ representativeCountry.value?.name }}</span>
            </ng-template>
          </payee-dropdown>
        </div>
    
        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="postcode"
              placeholder="Irányítószám"
            />
            <ng-container *ngIf="repPostcode.invalid && repPostcode.dirty" payee-error>
              <span *ngIf="repPostcode.hasError('required')">A mező kitöltése kötelező</span>
              <span *ngIf="repPostcode.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
            </ng-container>
          </payee-form-field>
        </div>
    
        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="settlement"
              placeholder="Település"
            />
            <ng-container *ngIf="repSettlement.invalid && repSettlement.dirty" payee-error>
              <span *ngIf="repSettlement.hasError('required')">A mező kitöltése kötelező</span>
              <span *ngIf="repSettlement.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
            </ng-container>
          </payee-form-field>
        </div>
    
        <div class="top-margin-15">
          <payee-form-field width="widest" height="tall">
            <input
              payee-input
              formControlName="street"
              placeholder="Utca és házszám"
            />
            <ng-container *ngIf="repStreet.invalid && repStreet.dirty" payee-error>
              <span *ngIf="repStreet.hasError('required')">A mező kitöltése kötelező</span>
              <span *ngIf="repStreet.hasError('maxlength')">A megadott szöveg túllépi a megengedett karakterszámot.</span>
            </ng-container>
          </payee-form-field>
        </div>
      </form>
    </form>
  </ng-container>
</form>
