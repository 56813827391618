<app-header-app routerLink="/login"></app-header-app>
<app-form-template
  title="{{ 'GET_PASSWORD_RESET_LINK.TITLE' | translate }}"
  [submit]="sendEmail"
  [formGroup]="forgotPasswordForm"
>
  <payee-form-field width="wide">
    <span payee-label>{{ "GET_PASSWORD_RESET_LINK.EMAIL_LABEL" | translate }}</span>
    <input payee-input formControlName="email" />
    <ng-container payee-error *ngIf="email.dirty">
      <mat-error *ngIf="email.hasError('email')">
        {{ "ERROR_MESSAGES.INVALID_EMAIL" | translate }}
      </mat-error>
      <mat-error *ngIf="email.hasError('required')">
        {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
      </mat-error>
    </ng-container>
  </payee-form-field>

  <button payee-button width="wide" [disabled]="loading">
    <ng-container *ngIf="!loading">
      {{ "GET_PASSWORD_RESET_LINK.REQUEST_BUTTON" | translate }}
    </ng-container>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>
