import { Injectable } from "@angular/core";
import { Options, serialize } from 'object-to-formdata';

@Injectable({
  providedIn: 'root'
})
export class TransformFormDataService {
  objectToFormData(
    obj: unknown,
    options: Options = {
      booleansAsIntegers: true,
      indices: true,
      nullsAsUndefineds: true,
    },
    existingFormData?: FormData,
    keyprefix?: string,
  ): FormData {
    return serialize(obj, options, existingFormData, keyprefix);
  }
}
