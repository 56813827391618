import {Component, OnInit} from '@angular/core';
import {ClientFormService} from '../../../services/form-services/client-form-service';
import {CompanySearchAutocompleteItem} from "../../../models/common/company-search-autocomplete-item";
import {GroupTypeEnum} from "../../../models/common/group-type-enum";
import { DebtorFormService } from '../../../services/form-services/debtor-form-service';

@Component({
  selector: 'app-client-data-box',
  templateUrl: './client-data-box.component.html',
  styleUrls: ['./client-data-box.component.scss']
})
export class ClientDataBoxComponent implements OnInit {
  isClientEditing = false;
  isRepEditing = false;

  constructor(
    public clientFormService: ClientFormService,
    public debtorFormService: DebtorFormService
  ) { }

  ngOnInit() {
    this.isClientEditing = !this.clientFormService.form.valid;
    this.isRepEditing = this.clientFormService.representativeFormGroup.invalid;
  }

  async onCompanySearchRepresentativeSelected(autocompleteItem: CompanySearchAutocompleteItem) {
    this.clientFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER);

    this.clientFormService.deleteRepresentative();
    this.clientFormService.repNameFormControl.patchValue(autocompleteItem.name);
    this.clientFormService.repTaxNumberFormControl.patchValue(autocompleteItem.tax_number);
    this.clientFormService.repCountryFormControl.patchValue(autocompleteItem.country);
    this.clientFormService.repPostcodeFormControl.patchValue(autocompleteItem.postcode);
    this.clientFormService.repSettlementFormControl.patchValue(autocompleteItem.settlement);
    this.clientFormService.repStreetFormControl.patchValue(autocompleteItem.street);

    this.isRepEditing = false;
  }

  onIndividualRepresentativeSelected(name: string) {
    this.clientFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER);
    this.clientFormService.deleteRepresentative();
    this.clientFormService.repNameFormControl.patchValue(name);

    this.isRepEditing = true;
  }

  onRepresentativeDelete() {
    this.clientFormService.groupTypeFormControl.patchValue(GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER);
    this.clientFormService.deleteRepresentative();

    this.isRepEditing = true;
  }

  onRepresentativeEdit() {
    this.isRepEditing = true;
  }

  onClientEdit() {
    this.isClientEditing = true;
  }
}
