import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { PayeeButtonsModule } from "src/app/shared/payee-buttons/payee-buttons.module";
import { PayeeCompanySearchInputFieldModule } from "src/app/shared/payee-company-search-input-field/payee-company-search-input-field.module";
import { PayeeFormFieldsModule } from "src/app/shared/payee-form-fields/payee-form-fields.module";
import { PayeeIconModule } from "src/app/shared/payee-icon/payee-icon.module";
import { PayeeInfoModule } from "src/app/shared/payee-info/payee-info.module";
import { PayeeProgressBarModule } from "src/app/shared/payee-progress-bar/payee-progress-bar.module";
import { AddNewClientFormComponent } from "./add-new-client-form.component";
import { FormTemplateModule } from "src/app/shared/screen-templates/form-template/form-template.module";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [
    AddNewClientFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    PayeeCompanySearchInputFieldModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    PayeeInfoModule,
    PayeeProgressBarModule,
    FormTemplateModule,
  ],
  exports: [
    AddNewClientFormComponent,
  ],
})


export class AddNewClientFormModule { }
