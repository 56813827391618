import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ClientsService } from '../clients/clients.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddMissingTaxidDialogComponent } from 'src/app/dialogs/client/add-missing-taxid-dialog/add-missing-taxid-dialog.component';
import { first } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StartCaseGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (this.authService.getUserOrigin() === 'PLUGIN_STRIPE') {
      await this.router.navigateByUrl('login');
      return false;
    }

    return true;
  }
}
