import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'payee-toggle',
  templateUrl: './payee-toggle.component.html',
  styleUrls: ['./payee-toggle.component.scss']
})
export class PayeeToggleComponent {
  @Input() selected = false;
  @Input() disabled = false;
  @Input() text = '';

  @Output() selectionChanged = new EventEmitter<boolean>();

  onClick(b: boolean) {
    if (this.disabled) {
      return;
    }
    this.selected = b;
    this.selectionChanged.emit(this.selected);
  }
}

