import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-form-exit-warning-dialog',
  templateUrl: './form-exit-warning-dialog.component.html',
  styleUrls: ['./form-exit-warning-dialog.component.scss']
})
export class FormExitWarningDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      payeeId: string,
    },
    private dialogRef: MatDialogRef<FormExitWarningDialogComponent>,
    private router: Router
  ) {}

  async submit(): Promise<void> {
    const route = '/user/cases';
    const queryParams = { filter: this.data.payeeId, filterType: 'payeeId' };

    await this.router.navigate([route], { queryParams: queryParams });
    this.dialogRef.close();
  }
}
