import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: any[] = [];

  tableHeaders = [{}];

  constructor() { }

  ngOnInit() {
    this.tableHeaders = Object.keys(this.tableData[0]);
    console.log(this.tableHeaders);
  }
}
