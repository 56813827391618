import { Directive, ElementRef, Optional } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tab-directive]',
})
export class TabDirectiveDirective {
  constructor(
    private ref: ElementRef<HTMLElement>,
    @Optional() private autoTrigger: MatAutocompleteTrigger,
  ) {
    this.ref.nativeElement.addEventListener('keydown', event => {
      if (event.key === 'Tab' && this.autoTrigger.activeOption) {
        this.autoTrigger.autocomplete._emitSelectEvent(this.autoTrigger.activeOption);
        this.autoTrigger.closePanel();
      }
    });
  }
}
