<h2
  id="{{ chapterId }}"
  (click)="userGuideService.anchorChapter(chapterId)"
  *ngIf="headerWeight === 1"
>
  <payee-icon height="16px" width="16px" name="link-outline"></payee-icon>
  <span>{{ chapterId }}. {{ chapterName }}</span>
</h2>

<h3
  id="{{ chapterId }}"
  (click)="userGuideService.anchorChapter(chapterId)"
  *ngIf="headerWeight === 2"
>
  <payee-icon height="16px" width="16px" name="link-outline"></payee-icon>
  <span>{{ chapterId }}. {{ chapterName }}</span>
</h3>

<h4 id="{{ chapterId }}" *ngIf="headerWeight === 3">
  <payee-icon height="16px" width="16px" name="link-outline"></payee-icon>
  <span>{{ chapterId }}. {{ chapterName }}</span>
</h4>

<h5 id="{{ chapterId }}" *ngIf="headerWeight > 3">
  {{ chapterId }} {{ chapterName }}
</h5>
