<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">
    {{ claimFormService.title }}
  </div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Kérünk, add meg, hogy milyen azonos jogviszonyba tartozó követeléseid vannak
    vele szemben:
    {{ debtorFormService.debtorStepData?.debtor_data?.debtor?.name ?? "" }}
  </div>

  <!--claim data boxes-->
  <div
    *ngFor="
      let claimFormGroup of claimFormService.claimFormArray.controls;
      let i = index
    "
  >
    <div class="top-margin-30">
      <app-claim-data-box [selectedClaimIndex]="i"></app-claim-data-box>
    </div>
  </div>

  <!--add claim button-->
  <div
    *ngIf="isAddClaimButtonVisible"
    class="new-claim-button-container top-margin-20"
    (click)="onAddNewClaimButtonClick()"
  >
    <div class="new-claim-icon">
      <payee-icon [name]="environment.PLUS_ICON"></payee-icon>
    </div>
    <div class="new-claim-text">Követelés hozzáadása</div>
  </div>

  <!--total claims sum amount-->
  <div
    class="top-margin-30 sum-text-container"
    *ngIf="claimFormService.isAnyClaimAmountGiven()"
  >
    <div class="sum-text">Összesen</div>
    <div class="sum-text">
      {{ claimFormService.getClaimsSumAmount() | number : "1.0" : "hu" }} {{ claimFormService.claimsStepData.currency_iso }}
    </div>
  </div>

  <!--total claims sum amount cant surpass 30M HUF-->
  <div
    *ngIf="claimFormService.claimFormArray.hasError('sumExceeded')"
    color="alert-red"
    class="sum-error"
  >
    A követelések összege nem haladhatja meg a 30 millió forintot.
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="claimFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
