<div class="user-guide-container">
  <div class="navigation">
    <app-side-panel></app-side-panel>
  </div>

  <div class="content">
    <!--Chapter 1-->
    <app-chapter [chapterId]="'1'" [chapterName]="'Bevezetés'"></app-chapter>
    <div class="chapter-content">
      <p>
        A Real-Time Debt management rendszere automizált online
        követeléskezelési megoldásokat kínál, melynek moduljai:
      </p>
      <ul>
        <li><a href="/user-guide#2.1">Fizetési felszólítás</a></li>
        <li><a href="/user-guide#2.2">Fizetési meghagyás</a></li>
        <li><a href="/user-guide#2.3">Ügyvédi levél</a></li>
      </ul>
      <p>
        A rendszer lehetőséget biztosít egyéni illetve csoportos
        követeléskezelés menedzselésére.
      </p>
      <p>Három főbb felülettel rendelkezik:</p>
      <ul>
        <li><a href="/user-guide#3">Ügyfél felület</a></li>
        <li><a href="/user-guide#4">Ügyvédi felület</a></li>
        <li><a href="/user-guide#5">Adminisztrációs felület</a></li>
      </ul>
    </div>

    <!--Chapter 2-->
    <app-chapter
      [chapterId]="'2'"
      [chapterName]="'Termékek bemutatása'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A modulokat a <a href="/user-guide#3.2">Számlák oldalon</a> elérhető
        számlák alapján lehet indítani. Egy számlára egy időben csak egy ügyet
        lehet indítani.
      </p>
      <p>
        Alább látható a termékek rövid bemutatása valamint az ügymenetek főbb
        lépései.
      </p>
    </div>

    <!--Chapter 2.1-->
    <app-chapter
      [chapterId]="'2.1'"
      [chapterName]="'Fizetési felszólítás'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A hitelező fizetetési felszólítást indíthat az adóssal szemben. Ezek a
        fizetési felszólítások SMS és email formában kerülnek kiküldésre az adós
        felé az alábbi ütemezésben:
      </p>
      <ul>
        <li>Email és SMS felszólítás kiküldése az 1. napon</li>
        <li>Email és SMS felszólítás kiküldése az 7. napon</li>
        <li>Email és SMS felszólítás kiküldése az 15. napon</li>
        <li>
          Ha nem fizet egyik felszólításra sem, akkor a 20. napon a folyamat
          véget ér.
        </li>
      </ul>
      <p>
        Hétvégén és este nem küld felszólításokat a Magyar Nemzeti Bank ajánlása
        szerint.
      </p>
    </div>

    <!--Chapter 2.2-->
    <app-chapter
      [chapterId]="'2.2'"
      [chapterName]="'Ügyvédi levél'"
    ></app-chapter>
    <div class="chapter-content">
      <p>Ajánlott postai levél, melynek folyamata:</p>
      <ul>
        <li>A postai levél generálása az 1. napon belül</li>
        <li>A postai levél kiküldése az adós címére a 2. napon belül</li>
        <li>
          Az ügy akkor kerül lezárásra, amikor visszaérkezett a tértivevény a
          kiküldésről.
        </li>
      </ul>
    </div>

    <!--Chapter 2.3-->
    <app-chapter
      [chapterId]="'2.3'"
      [chapterName]="'Fizetési meghagyás'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Nem fizetett számlák esetén lehetőség van fizetési meghagyást indítani
        adóssal szemben, melynek két szakasza van:
      </p>
      <p><b>Az első szakasz - Fizetési meghagyás (FMH) elindítása</b></p>
      <ul>
        <li>Szükséges adatok megadása.</li>
        <li>
          Ha minden adat megfelel, akkor 24 órán belül beadásra kerül a fizetési
          meghagyás kérelme a közjegyzői kamara felületén.
        </li>
        <li>
          A közjegyző ezután 3 napon belül kibocsátja a fizetési meghagyást.
        </li>
        <li class="l2">
          Olykor a közjegyzők válaszideje miatt 5-7 nap is lehet, mire ez
          látszik a fiókjában.
        </li>
        <li>
          A posta ezután tértivevényes levélként kiviszi az adósnak a fizetési
          meghagyást.
        </li>
        <li>Az adós átveszi a fizetési meghagyást. Megjegyzések ehhez:</li>
        <li class="l2">
          Amennyiben az iratot az adós nem vette át, azaz postai kézbesítés
          esetén „nem kereste” jelzéssel érkezik vissza a közjegyzőhöz, azt az
          ellenkező bizonyításáig a kézbesítés második megkísérlését követő 5.
          munkanapon kézbesítettnek kell tekinteni.
        </li>
        <li class="l2">
          Amennyiben az irat az „átvételt megtagadta” jelzéssel érkezik vissza,
          a kézbesítés megkísérlésének napján kézbesítettnek kell tekinteni azt.
          Ezt nevezzük kézbesítési fikciónak.
        </li>
        <li>
          Ezután az adósnak a kézbesítéstől számítottan van 15 napja reagálni a
          fizetési meghagyásra.
        </li>
        <li class="l2">
          Mikortól számítódik a 15 nap? – A fiókjában látható a kézbesítéshez
          tartozó esemény, amihez emailben elküldjük az igazoló dokumentumot.
          Ebben megtalálja azt a napot, ahonnan számítani kell a 15 napot
          (naptári nap, nem munkanap)
        </li>
        <li class="l2">Ekkor az adós lehetőségei:</li>
        <li class="l3">
          Ellentmondhat – Ekkor az ügy perré alakulhat, és bíróságon
          folytatható.
        </li>
        <li class="l3">
          Részletfizetést vagy haladékot kérhet – Ezt ön elfogadhatja vagy
          elutasíthatja – Mint mindenről, erről is kap értesítést.
        </li>
        <li class="l3">Befizetheti az egész tartozást.</li>
        <li class="l3">
          Ha nem reagál, akkor pedig 16. napon jogerőre emelkedik az ügy és
          végrehajtható lesz.
        </li>
        <li class="l3">
          Ezután a közjegyző 4-6 napon belül feltölti a végzést, és mi arról
          azonnal értesítjük magát.
        </li>
      </ul>
      <p><b>Második szakasz - Fizetési meghagyás (FMH) elindítása</b></p>
      <p>
        A végrehajtási eljárásban a tartozást elsősorban az adós munkabéréből
        való letiltással, illetve a bankszámláján lévő pénz lefoglalásával kell
        behajtani.
      </p>
      <p>
        Ha előre látható, hogy a követelést a munkabérre, illetve a pénzforgalmi
        szolgáltatónál kezelt összegre vezetett végrehajtással nem lehet
        viszonylag rövidebb időn belül behajtani, az adós bármilyen lefoglalható
        vagyontárgya végrehajtás alá vonható. A lefoglalt ingatlant azonban csak
        akkor lehet értékesíteni, ha a követelés az adós egyéb vagyontárgyaiból
        nincs teljesen fedezve, vagy csak aránytalanul hosszú idő múlva
        elégíthető ki.
      </p>
      <p>A folyamat az alábbi lépésekbők áll:</p>
      <ul>
        <li>Szükséges adatok megadása</li>
        <li>A végrehajtó kibocsátja a végrehajtási lapot</li>
        <li>
          Megkapja a díjjegyzéket közvetlenül a végrehajtótól, és vele is fog a
          továbbiakban kapcsolatban lenni. A Payee-ben az ügy lezárul.
        </li>
      </ul>
    </div>

    <!-- APP -->

    <!--Chapter 3-->
    <app-chapter
      [chapterId]="'3'"
      [chapterName]="'Ügyfél felület'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyfél felületre</i> lépnek be mindazon felhasználók, akik
        <i>Ügyeket</i> szeretnének indítani és menedzselni
        <i>Hitelező</i> nevében különböző Adósokkal szemben.
      </p>
      <p>
        Az <i>Ügyfél felületre</i> való belépés regisztrációhoz kötött, a
        regisztrációt <i>Admin</i> jogosultsággal rendelkező személy végezheti.
      </p>
    </div>

    <!--Chapter 3.1-->
    <app-chapter
      [chapterId]="'3.1'"
      [chapterName]="'Bejelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer egy webes szolgáltatás, amely internetes böngésző segítégével
        érhető el.
      </p>
      <p>Támogatott böngészők:</p>
      <ul>
        <li>Google Chrome (preferált)</li>
        <li>Mozilla Firefox</li>
        <li>Microsoft Edge</li>
      </ul>
      <p>
        Az alkalmazás teszt és éles környezetet is biztosít, a hivatkozásokat
        lásd lentebb.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/login-screen.png"
      />
      <p>
        A <i>Bejelentkezés oldalon</i> egyetlen gomb található:
        <i>Bejelentkezés</i>. Erre kattintva a PwC Identity bejelentkeztető
        rendszerén keresztül tudunk belépni az alkalmazásba. Első lépésben az
        email címet kell megadni:
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-email.png"
      />
      <p>Második lépésben a jelszót kell megadni:</p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-password.png"
      />
      <p>
        Harmadik lépésben a két faktoros belépéshez kell kiválasztani, hogy
        milyen formában érkezzen meg a hitelesítő kód.
      </p>
      <p>Három féle mód közül lehet választani:</p>
      <ul>
        <li>Email formában a megadott email címre</li>
        <li>Szöveges üzenet formában a megadott telefonszámra</li>
        <li>Telefonhívásként a megadott telefonszámon</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-2fa.png"
      />
      <p>
        A rendszer 1 óra után automatikusan kiléptet, utána újra be kell
        jelentkezni.
      </p>
    </div>

    <!--Chapter 3.1.1-->
    <app-chapter
      [chapterId]="'3.1.1'"
      [chapterName]="'Teszt oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A teszt oldal tesztelési célokra szolgál, az
        <i>Online Riporting Tool</i> alkalmazás teszt felületéről szinkronizálja
        az adatokat.
      </p>
      <p>
        A teszt oldal elérhetősége:
        <a href="https:/app-dev.payee.tech" target="_blank"
          >https:/app-dev.payee.tech</a
        >
      </p>
    </div>

    <!--Chapter 3.1.2-->
    <app-chapter
      [chapterId]="'3.1.2'"
      [chapterName]="'Éles oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az éles oldal használható a rendszer partnerei számára. Az
        <i>Online Riporting Tool</i> alkalmazás éles felületéről szinkronizálja
        az adatokat.
      </p>
      <p>
        Az éles oldal elérhetősége:
        <a href="https:/realtimedebtmanagement.hu.pwc.com" target="_blank"
          >https:/realtimedebtmanagement.hu.pwc.com</a
        >
      </p>
    </div>

    <!--Chapter 3.2-->
    <app-chapter
      [chapterId]="'3.2'"
      [chapterName]="'Számlák oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Számlák oldalon</i> látható az
        <i>Online Riporting Tool</i> alkalmazásból átszinkronizálódott számlák
        listája. Ezen az oldalon lehet szűrni, rendezni a számlák listájára, a
        listában lévő számlákra ügyet indítani, valamint csoportos befizetést
        rögzíteni, kontakt listát feltölteni.
      </p>
      <p>
        Az <i>Online Riporting Tool</i> alkalmazásból nem minden adat
        szinkronizálódik át - fizetési határidő, email, telefonszám - ezeket a
        <a href="/user-guide#3.2.3"> Kontakt lista</a> pontban leírtak szerint
        lehet feltölteni.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-page.png"
      />
    </div>

    <!--Chapter 3.2.1-->
    <app-chapter
      [chapterId]="'3.2.1'"
      [chapterName]="'Számlák listázása, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az adatokat sorba lehet rendezni a táblázat oszlopainak fejléceire
        kattintva. Az alábbiak oszlopok szerint lehet rendezni:
        <i>Számlaszám</i>, <i>Státusz</i>, <i>Fizetési határidő</i>,
        <i>Vevő neve</i>, <i>Fennálló tartozás</i>
      </p>
      <p>
        Egyszer rákattintva növekvő sorrendbe, még egyszer kattintva csökkenő
        sorrendbe rendezi őket.
      </p>
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <p>
        A találatokat a <i>Keressen rá a számlára</i> beviteli mező kitöltésével
        lehet szűrni. A <i>Kifizetett számlák mutatása</i> checkbox-ot bejelölve
        a már kifizetett számlák is megjelennek a táblázatban.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-list.png"
      />
    </div>

    <!--Chapter 3.2.2-->
    <app-chapter
      [chapterId]="'3.2.2'"
      [chapterName]="'Ügy indítása'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Számlák</i> táblázat egyes sorainak végén látható az
        <i>Ügy létrehozása</i> gomb. Erre kattintva lehet ügyet indítani.
      </p>
      <p>Az <i>Ügy létrehozása</i> gombnak 3 állapota lehet:</p>
      <ul>
        <li>
          <b>A gomb aktív, kattintható</b> - Elindítható az ügy, mivel minden
          kötelező adat ki van töltve és nincs a számlára futó ügy
          <img
            class="mw-200 d-block"
            appLazyLoad
            src="assets/shared/user-guide/app-invoices-list-item-create-case-button-active.png"
          />
        </li>
        <li>
          <b>A gomb inaktív, nem kattintható</b> - Hiányzik valamilyen
          adatkitöltés
          <img
            class="mw-400 d-block"
            appLazyLoad
            src="assets/shared/user-guide/app-invoices-list-item-create-case-button-inactive-missing-data.png"
          />
        </li>
        <li>
          <b>A gomb inaktív, nem kattintható</b> - A számlára már van futó ügy
          <img
            class="mw-400 d-block"
            appLazyLoad
            src="assets/shared/user-guide/app-invoices-list-item-create-case-button-inactive-has-case.png"
          />
        </li>
      </ul>
      <p>
        Az aktív <i>Ügy indítása</i> gomb megnyomása esetén megjelenik a
        <a href="/user-guide#3.4">Termékválasztó popup</a>, és kiválasztva az
        indítandó ügy típusát a megfelelő ügyindítási oldalra kerülünk.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-start-case-popup.png"
      />
    </div>

    <!--Chapter 3.2.3-->
    <app-chapter
      [chapterId]="'3.2.3'"
      [chapterName]="'Kontakt lista'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Kontakt lista</i> gombra kattintva 2 opció közül választhatunk:
      </p>
      <ul>
        <li>
          <b>Lista excel letöltése</b> - A Számlák listájának letöltése excel
          formátumban
        </li>
        <li>
          <b>Kitöltött excel feltöltése</b> - A letöltött, majd a kitöltésre
          került excel feltöltése importáláshoz
        </li>
      </ul>
      <img
        class="mw-300 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-contact-list-button.png"
      />
      <p>
        A <i>Lista excel letöltése</i> gombot megnyomva letöltődik egy xlsx
        formátumú táblázat, mely az alábbi mezőket tartalmazza:
      </p>
      <dl>
        <dt>Tax Number</dt>
        <dd>
          Rendszerből érkező adat, a Hitelező adószámát tartalmazza. Nem szabad
          megváltoztatni.
        </dd>

        <dt>Invoice Number</dt>
        <dd>
          Rendszerből érkező adat, az Adós felé kiállított számla számát
          tartalmazza. Nem szabad megváltoztatni.
        </dd>

        <dt>Due date</dt>
        <dd>
          A számla fizetési határideje. Alapból a rendszer nem tartalmazza,
          ennek kitöltése a feltöltést végző személy feladata. Amennyiben adat
          látható ebben az oszlopban, az korábbi feltöltésből származik.
          Kötelező formátum: <i>YYYY-HH-NN</i>, példa: 2023-12-04
        </dd>

        <dt>Email</dt>
        <dd>
          Az <i>Adós</i> email címe. Alapból a rendszer nem tartalmazza, ennek
          kitöltése a feltöltést végző személy feladata. Amennyiben adat látható
          ebben az oszlopban, az korábbi feltöltésből származik. Email formátum
          megadása kötelező.
        </dd>

        <dt>Phone</dt>
        <dd>
          Az <i>Adós</i> telefonszáma. Alapból a rendszer nem tartalmazza, ennek
          kitöltése a feltöltést végző személy feladata. Amennyiben adat látható
          ebben az oszlopban, az korábbi feltöltésből származik.
        </dd>
      </dl>
      <img
        class="mw-800 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-contact-list-example-excel.png"
      />
      <p>
        Miután az egyes adatsorok kitöltése megtörtént, a
        <i>Kitöltött excel feltöltése</i> gombra kattintva lehet elindítani a
        feltöltési folyamatot. A gombra kattintva megjelenik a
        <i>Kontakt lista feltöltése</i> popup.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-contact-list-upload-popup.png"
      />
      <p>
        A fájl kiválasztása után megkezdődik az importálás. A folyamat során
        ellenőrzi a rendszer hogy a megfelelő adatok ki lettek e töltve.
      </p>
      <p>
        Amennyiben hibát talál, a <i>Rosszul kitöltött sorok száma</i> résznél
        jelzi hány sor nincs megfelelően kitöltve, valamint megjeleníti listában
        az adott sorokat a megadott adatokkal:
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/app-upload-contacts-popup-error.png"
      />
      <p>
        Az <i>Email</i> oszlopban ellenőrzi, hogy valós email cím lett e
        megadva. Továbbá ellenőrzi, hogy az <i>Email</i> és
        <i>Telefonszám</i> oszlopok közül legalább az egyik ki lett e töltve.
      </p>
      <p>
        Ha minden kitöltött adat helyes, az importálás végén az alábbi képernyő
        látható:
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/app-upload-contacts-popup-success.png"
      />
      <p>
        Az importálás után az adatok elérhetőek a <i>Számlák</i> táblázatban.
      </p>
    </div>

    <!--Chapter 3.2.4-->
    <app-chapter
      [chapterId]="'3.2.4'"
      [chapterName]="'Befizetések rögzítése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Befizetések rögzítése</i> gombra kattintva 2 opció közül
        választhatunk:
      </p>
      <ul>
        <li><b>Minta excel letöltése</b> - Befizetési lista letöltése</li>
        <li>
          <b>Kitöltött excel feltöltése</b> - A letöltött, majd a kitöltésre
          került excel feltöltése befizetés rögzítéséhez
        </li>
      </ul>
      <img
        class="mw-300 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-invoices-payments-button.png"
      />
      <p>
        A <i>Lista excel letöltése</i> gombot megnyomva letöltődik egy xlsx
        formátumú táblázat, mely az alábbi mezőket tartalmazza:
      </p>
      <dl>
        <dt>Supplier Tax Number</dt>
        <dd>
          Rendszerből érkező adat, a Hitelező adószámát tartalmazza. Nem szabad
          megváltoztatni.
        </dd>

        <dt>Invoice Number</dt>
        <dd>
          Rendszerből érkező adat, az Adós felé kiállított számla számát
          tartalmazza. Nem szabad megváltoztatni.
        </dd>

        <dt>Amount</dt>
        <dd>
          Befizetendő összeg. Alapból a rendszer nem tartalmazza, ennek
          kitöltése a feltöltést végző személy feladata. Kizárólag szám adható
          meg.
        </dd>
      </dl>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-batch-payment-example-excel.png"
      />
      <p>
        Miután az egyes adatsorok kitöltése megtörtént, a
        <i>Kitöltött excel feltöltése</i> gombra kattintva lehet elindítani a
        feltöltési folyamatot. Kattintás után megjelenik a
        <i>Befizetések rögzítése</i> popup.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-batch-payment-popup.png"
      />
      <p>
        A fájl kiválasztása után megkezdődik az importálás. A folyamat során
        ellenőrzi a rendszer hogy a megfelelő adatok ki lettek e töltve.
      </p>
      <p>
        Amennyiben hibát talál, a <i>Rosszul kitöltött sorok száma</i> résznél
        jelzi hány sor nincs megfelelően kitöltve, valamint megjeleníti listában
        az adott sorokat a megadott adatokkal:
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/app-batch-payment-popup-error.png"
      />
      <p>
        Az <i>Amount</i> oszlopban ellenőrzi, hogy valós szám érték lett e
        megadva.
      </p>
      <p>
        Ha minden kitöltött adat helyes, az importálás végén az alábbi képernyő
        látható:
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/app-batch-paymnet-success.png"
      />
      <p>
        Az importálás után az fennálló tartozások oszlopok értékei frissülnek
        azon számláknál ahol a befizetések megtörténtek.
      </p>
    </div>

    <!--Chapter 3.3-->
    <app-chapter
      [chapterId]="'3.3'"
      [chapterName]="'Ügyek oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyek oldalra</i> navigálva 3 tab-os felosztás látható. Ezek az
        egyes ügyek főbb életciklusainak bontása:
      </p>
      <ul>
        <li>Piszkozatok</li>
        <li>Nyitott ügyek</li>
        <li>Lezárt ügyek</li>
      </ul>
      <p>
        Bármelyik tab-ot kiválasztva az alábbi főbb egységekből áll az oldal:
      </p>
      <ul>
        <li>Súgó az aktuális tab tartalmáról</li>
        <li>Szűrő</li>
        <li>Találati lista</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-cases-page.png"
      />
    </div>

    <!--Chapter 3.3.1-->
    <app-chapter
      [chapterId]="'3.3.1'"
      [chapterName]="'Ügyek listázása, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <p>
        A találatokat <i>Ügyazonosító/Adós neve</i> lenyíló menü
        kiválasztásával, majd a mellette található beviteli mezőt kitöltve
        lehet.
      </p>

      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/app-cases-case-list.png"
      />
    </div>

    <!--Chapter 3.3.2-->
    <app-chapter
      [chapterId]="'3.3.2'"
      [chapterName]="'Ügy megtekintése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyek táblázat</i> egy sorára kattintva lenyílik az adott
        <i>ügy részletei</i> panel. A panel 2 tab-ot tartalmaz:
      </p>
      <ul>
        <li>Események</li>
        <li>Részletek</li>
      </ul>

      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
    </div>

    <!--Chapter 3.3.3-->
    <app-chapter
      [chapterId]="'3.3.3'"
      [chapterName]="'Ügy megtekintése / Események'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Események</i> tab alatt az ügy indítása óta történt események
        utolsó 3 eseményét listázza.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
      <p>
        Itt az <i>Összes esemény mutatása</i> gombra kattintva az ügy indítása
        óta bekövetkezett eseményeket lehet megtekinteni.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-all.png"
      />
      <p>
        Az események között - ha az eseményhez tartozott letölthető állomány,
        akkor innen letölthető:
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-download-doc.png"
      />
    </div>

    <!--Chapter 3.3.4-->
    <app-chapter
      [chapterId]="'3.3.4'"
      [chapterName]="'Ügy megtekintése / Részletek'"
    ></app-chapter>
    <div class="chapter-content">
      <p>A <i>Részletek</i> tab alatt az ügy részletei láthatóak.</p>
      <p>
        Az alábbi főbb adatokból épül fel, függően az elindított ügy típusától:
      </p>
      <ul>
        <li><b>Ügyazonosító:</b> A rendszer által létrehozott ügy azonosító</li>
        <li><b>Adós neve:</b> Akivel szemben az ügy indult.</li>
        <li><b>Adós címe:</b> Adós címe</li>
        <li><b>Szöveges indoklás:</b> Ügyhöz tartozó szöveges indoklás</li>
        <li>
          <b>Követelések:</b> Soronként felsorolva az egyes követelések,
          lejárati határidővel és összeggel
        </li>
        <li>
          <b>Járulékos követelések:</b> Soronként ügytípustól függően lehet
          Törvényes kamatok, 40 EUR behajtási költségátalány, Ügyintézési díj,
          összeggel
        </li>
        <li>
          <b>Teljes követelés:</b> A Követelések és a Járulékos követelések
          összege
        </li>
        <li>
          <b>Kommunikáció nyelve:</b> Az Adós felé történő értesítések ezen a
          nyelven történnek (Magyar vagy Angol)
        </li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-details.png"
      />
    </div>

    <!--Chapter 3.3.5-->
    <app-chapter
      [chapterId]="'3.3.5'"
      [chapterName]="'Ügyek műveletei'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A Ügyek listájában minden sor végén látható a ügyek műveletei lenyíló
        menü. Itt az adott ügy adott fázisához tartozó műveleteket lehet
        végrehajtani.
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-details-event-dropdown.png"
      />
      <p>
        Amennyiben nincs az ügyhöz elérhető művelet,
        <i>Nincs elérhető esemény</i> felirat látható az opciók helyén.
      </p>
      <p>Az ügyek műveletei listában az alábbi eseményekkel találkozhatunk:</p>

      <p><b>Ügy törlése</b></p>
      <p>
        Piszkozat státuszú ügy törlése. Ebben az esetben az ügyben kiválasztott
        számlákra lehet új ügyet indítani. Nem számítolja bele a riportba a
        rendszer költséggel, az ügyben kiválasztott számlákra lehet új ügyet
        indítani
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-delete-draft.png"
      />

      <p><b>Befizetés rögzítése</b></p>
      <p>
        Befizetés rögzítésekor a fennálló követelések tételesen fel vannak
        sorolva. A követelések közül az adott sort kijelölve azon tételre
        vonatkozóan lehet csökkenteni a követelés összegét. Az összeg típusok
        amikből lehet csökkenteni az összeget: <i>Számla alapú követelés</i>,
        <i>kamat</i>, <i>40 EUR költség átalány.</i><br />
        Ha minden követelés ki lett fizetve, akkor az ügy kifizetetten lezárul.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-payment.png"
      />

      <p><b>Részletfizetés mulasztása</b></p>
      <p>
        Fizetési meghagyáskor ha az adós részletfizetést kért, de nem fizetett
        időben, akkor lehet kérni a behajtás folytatását.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-non-payment-installment.png"
      />

      <p><b>Új kézbesítési cím megadása</b></p>
      <p>
        Ha az adós címére nem lehet kézbesíteni a fizetési meghagyást, akkor új
        címet kell megadni.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-non-payment-installment.png"
      />

      <p><b>Nyilatkozás - Teljes kiegyenlítés</b></p>
      <p>
        Fizetési meghagyáskor az adós jelezheti, hogy már kifizette a
        tartozását.
        <i>A teljes követelés meg lett fizetve:</i> Az ügy kifizetetten lezárul
        <i>Nem lett a teljes követelés megfizetve:</i> A behajtás folytatódik
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-statement-settlement.png"
      />

      <p><b>Ügy leállítása</b></p>
      <p>
        <i>A teljes követelésemet megfizették:</i> Az ügy kifizetetten lezárul
        <i>Egyéb okból állítom le:</i> Az ügy kifizetetlenül lezárul
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-stop-case.png"
      />

      <p><b>Hiánypótlás</b></p>
      <p>
        Fizetési meghagyás ügymenete során előfordul, hogy a további adatok
        megadása szükséges. Erről email formájában küld a rendszer üzenetet. Az
        adatok megadása után folytatódik tovább az ügymenet.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-missing-data.png"
      />

      <p><b>Végrehajtás indítása</b></p>
      <p>
        Amennyiben egy fizetési meghagyás jogerőre emelkedi, megindítható a
        végrehajtási eljárás. Az <i>Elindítom a végrehajtást</i> gombra
        kattintva az ügy át lesz adva ügyvéd részére, aki kezdeményeezi a
        végrehajtást.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-enforcement-by-attorney.png"
      />

      <p><b>Végrehajtás ingatlanra</b></p>
      <p>
        Email formában a hitelező részére elküldött ingatlan listából azon
        ingatlanok listáját kell felsorolni, melyre a végrehajtást szeretné
        elindítani.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-enforcement-real-estate.png"
      />
    </div>

    <!--Chapter 3.4-->
    <app-chapter [chapterId]="'3.4'" [chapterName]="'Ügyindítás'"></app-chapter>
    <div class="chapter-content">
      <p>
        Ügyet indítani a
        <a href="/user-guide#3.2.2">Számlák listájában adott számla sorában</a>
        az <i>Ügy létrehozása</i> gombra kattintva lehet. Vannak olyan esetek,
        amikor nem lehet egyből indítani az ügyet, erről az
        <a href="/user-guide#3.2.2">Ügy indítása</a> részben lehet bővebben
        olvasni.
      </p>
      <p>
        Miután az <i>Ügy indítása</i> gomb meg lett nyomva, első lépésként
        megjelenik a <i>Termékválasztó popup:</i>
      </p>
      <img
        class="mw-1000 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/app-invoices-start-case-popup.png"
      />
      <p>A termék választónál a három modul közül lehet választani.</p>
      <p>
        <i>Ügyvédi levél</i> esetén, amennyiben nem a fizetési határidő a számla
        lejárta után még nem érte el a 20 napot, az alábbi üzenet jelenik meg:
      </p>
      <img
        class="mw-800 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/start-case-attorney-20-days-warning.png"
      />
    </div>

    <!--Chapter 3.4.1-->
    <app-chapter
      [chapterId]="'3.4.1'"
      [chapterName]="'Adatmegadás'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Miután a termék kiválasztása megtörtént, átkerülünk az
        <i>Adatmegadás</i> oldalra.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-page.png"
      />
      <p>Az adatmegadás oldal a főbb blokkokat tartalmazza:</p>

      <dl>
        <dt>Ki tartozik? (Adós)</dt>
        <dd>
          Adós megnevezése, akivel kapcsolatosan a követelés fennáll és akivel
          kapcsolatban az ügy el fog indulni. Forrása az
          <i>Online Riporting Tool</i>-ból átvett adatok. Módosítani nem lehet,
          csak megtekinteni.
        </dd>
      </dl>
      <p>A <i>Megtekintés</i> gombra kattintva az alábbi popup jelenik meg:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-debtor-popup.png"
      />

      <dl>
        <dt>Számlák kiválasztása</dt>
        <dd>
          Ebben a popupban jelennek meg az adóssal kapcsolatos,
          <i>Online Reporting Tool</i>-ból átvett számlák. Itt lehet
          kiválasztani, hogy az adott ügyhöz mely számlákat szeretnénk bevonni
          az ügyindításhoz. <br />
          Amennyiben egy sor végén felkiálltó jel háromszögben piktogram
          látható, akkor a számlát nem lehet hozzáadni. <br />A piktogram két
          esetben jelenhet meg:
          <br />
          1. Nincs kitöltve minden adat, ekkor a súgóban az alábbi szöveg
          látható:
          <i
            >Ügyindításhoz meg kell adni az adós elérhetőségeit. Használja a
            kontakt lista feltöltése funkciót.</i
          >. Ebben az esetben el a
          <a href="/user-guide#3.2.3">Kontakt lista</a> fejezetben leírtak
          szerint kell pótolni a hiányzó adatokat.<br />
          2. A számlára már lett indítva és most is fut ügy, ekkor a súgó
          szövege: <i>Van futó ügy a számlára. (P1001)</i> Párhuzamosan egy
          számlára nem indulhat több ügy.
        </dd>
      </dl>
      <p>A <i>Szerkesztés</i> gombra kattintva az alábbi popup jelenik meg:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-invoices-popup.png"
      />

      <dl>
        <dt>Kommunikáció</dt>
        <dd>
          Itt lehet beállítani a telefonszámot és az email címet (Fizetési
          felszólítás esetén), valamint a kommunikáció nyelvét (Fizetési
          felszólítás és Ügyvédi levél esetén).
        </dd>
      </dl>
      <p>A <i>Szerkesztés</i> gombra kattintva az alábbi popup jelenik meg:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-debtor-popup.png"
      />

      <dl>
        <dt>Kinek tartoznak? (Hitelező)</dt>
        <dd>Hitelező és elérhetőségei tekinthetők meg ebben a blokkban.</dd>
      </dl>
      <p>A <i>Megtekintés</i> gombra kattintva az alábbi popup jelenik meg:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-debtor-popup.png"
      />

      <dl>
        <dt>Bankadatok</dt>
        <dd>
          A Hitelező banki adatai állíthatóak be. A
          <a href="/user-guide#3.6.2">Hitelezői fiók beállítások</a> oldalon
          megadott értékek jelennek meg alapértelmezetten.
        </dd>
      </dl>
      <p>A <i>Szerkesztés</i> gombra kattintva az alábbi popup jelenik meg:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-data-bank-account-popup.png"
      />
      <p>
        Miután minden adat kitöltésre került, a
        <i>Tovább az összegzésre</i> gombra kattintva az
        <i>Összegzés oldalra</i> jutunk
      </p>
    </div>

    <!--Chapter 3.4.2-->
    <app-chapter
      [chapterId]="'3.4.2'"
      [chapterName]="'Összegzés oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Adatmegadás oldalon</i> az adatok kitöltése után az
        <i>Összegzés oldalra</i> jutunk.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/form-summary-page.png"
      />
      <p>
        Két főbb részből áll: Itt egyfelől az ügyindítás előtti összegző adatok
        láthatóak, továbbá a szám alapvető követelésekre egyéb követelések és
        költségelemek jelenhetnek meg:
      </p>
      <ul>
        <li>
          Az első egységben látható a Hitelezó és Adós megnevezése, valamint a
          számlák alapján a követelések listája
        </li>
        <li>
          A második egységben azon kötelező és opcionálisan megjelenő
          követelések listája, amely az adott ügyhöz mint járulékos követelések
          felmerülhetnek. Amennyiben bármely tétel beállítása megtörténik, a
          számított követelés elem hozzáadódi a <i>Teljes követeléshez.</i> Ezen
          rész csak <i>Ügyvédi levél</i> és <i>Fizetési meghagyás</i> modulok
          esetén elérhető.
        </li>
      </ul>
      <p>A <i>Járulékos követelések</i> az alábbiak lehetnek:</p>
      <dl>
        <dt>Törvényes kamatok</dt>
        <dd>
          A számlák lejárta óta felhalmozódott, törvényi megfelelés szerinti
          kamatok összege, amely követelés formájában érvényesíthető az adós
          felé. Kiválasztása opcionális.
        </dd>
      </dl>
      <dl>
        <dt>Törvény szerinti 40 EUR behajtási költségátalány</dt>
        <dd>
          Minden 1 évnél nem régebbi lejáratú követelés esetén érvényesíthető az
          ügymenetre egy 40 EUR-os behajtási költségátalány követelési
          tételként. Kiválasztása opcionális.
        </dd>
      </dl>
      <dl>
        <dt>Szolgáltatási díj</dt>
        <dd>
          Fizetési meghagyás modul indítása esetén tövényi kötelezettség az
          ügymenet folyamán Szolgáltatási díjat felszámolni. A Fizetési
          meghagyás két főbb szakaszból áll: "Indítási szakasz" és "Behajtási
          szakasz". <br />
          Indítási szakasz esetén a rendszer követelésként hozzáadja a számlák
          által követelt összegek 3%-át, de minimum 8 000, maximum 300 000
          forintot. <br />
          Behajtási szakasz esetén a rendszer követelésként hozzáadja a számlák
          által követelt összegek 1%-át, de minimum 8 000, maximum 150 000
          forintot. <br />
        </dd>
      </dl>
    </div>

    <!--Chapter 3.5-->
    <app-chapter
      [chapterId]="'3.5'"
      [chapterName]="'Havi riportok'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer kétféle riportot küld automatikusan, minden hónap első
        napján, hajnali 2 órakor:
      </p>
      <ul>
        <li>Összegző riport</li>
        <li>Számla riport</li>
      </ul>
      <p>
        Ezen riportok célja, hogy tájékoztást nyújtson a rendszerben elindított
        ügyek számosságával és értékével kapcsolatosan.
      </p>
    </div>

    <!--Chapter 3.5.1-->
    <app-chapter
      [chapterId]="'3.5.1'"
      [chapterName]="'Összegző riport'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer minden hitelezőről külön-külön havi szinten küld egy riportot
        egy előre meghatározott email címre. A riportban szerepel hogy az előző
        hónapban mennyi és milyen típusú ügy indult, milyen értékben
      </p>
    </div>

    <!--Chapter 3.5.2-->
    <app-chapter
      [chapterId]="'3.5.2'"
      [chapterName]="'Számla riport'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer minden hónapban küld a Hitelezői fiók kezelői számára egy
        összesítőt arról, hogy az előző hónapban mennyi és milyen típusú ügy
        indult, milyen értékben.
      </p>
    </div>

    <!--Chapter 3.6-->
    <app-chapter
      [chapterId]="'3.6'"
      [chapterName]="'További funkciók'"
    ></app-chapter>
    <div class="chapter-content"></div>

    <!--Chapter 3.6.1-->
    <app-chapter
      [chapterId]="'3.6.1'"
      [chapterName]="'Hitelező választása'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Egy ügyfél fiókhoz több hitelező is tartozhat, így egy ügyfél fiókból
        több hitelező számláit és ügyeit is lehet kezelni.
      </p>
      <p>
        Az ügyfél felületen a fejlécben a jobb oldalon található a
        <i>Hitelező választása</i> lenyíló menü. A listában megjelennek azok a
        hitelezők, amikhez a felhasználóknak van jogosultságuk hozzáférni. Adott
        hitelezőt kiválasztva a hozzá tartozó számlák és ügyek jelennek meg.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/header-client-dropdown-opened.png"
      />
    </div>

    <!--Chapter 3.6.2-->
    <app-chapter
      [chapterId]="'3.6.2'"
      [chapterName]="'Hitelezői fiók beállítások'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A fejlécben a jobb felső sarokban a bejelentkezett felhasználó nevének
        kezdőbetűiből (JD) összeállított gombra kattintva megjelenik a
        <i>Felhasználói fiók</i>, ahol a hitező(k) beállításait lehet
        szerkeszteni.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/header-user-account-clients-page.png"
      />
      <p>
        A Hitelezők listájából adott sorban a <i>Fiók beállításai</i> gombra
        kattintva az adott <i>Hitelező beállításai</i> oldalra jutunk.
      </p>
      <p>
        A Hitelezőnél felhasználóként a Számlaszám részleteinek alábbi adatait
        lehet módosítani (az adatok mentése kitöltés után automatikusan
        történik):
      </p>
      <ul>
        <li><b>Kedvezményezett neve:</b> A hitelező neve</li>
        <li>
          <b>Bankszámlaszám/IBAN:</b> Bankszámlaszám hagyományos
          00000000-00000000-00000000 osztásban vagy IBAN formátumban
        </li>
        <li><b>Számlavezető bank neve:</b> A hitelező számlavezető bankja</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/header-user-account-clients-edit-bank-account-page.png"
      />
      <p>
        Ezek az adatok <a href="/user-guide#3.4">Ügy indításakor</a> a
        <i>Számlaszám automatikus kitöltéséhez</i> szükségesek.
      </p>
    </div>

    <!--Chapter 3.7-->
    <app-chapter
      [chapterId]="'3.7'"
      [chapterName]="'Kijelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Kijelentkezni a fejlécben található, jobbról a második ikonnal lehet.
      </p>
    </div>

    <!-- ATTORNEY --->

    <app-chapter
      [chapterId]="'4'"
      [chapterName]="'Ügyédi felület'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyvédi felület</i> az ügyvédi folyamatok menedzselésére szolgáló
        felület.
      </p>
    </div>

    <app-chapter
      [chapterId]="'4.1'"
      [chapterName]="'Bejelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer egy webes szolgáltatás, amely internetes böngésző segítégével
        érhető el.
      </p>
      <p>Támogatott böngészők:</p>
      <ul>
        <li>Google Chrome (preferált)</li>
        <li>Mozilla Firefox</li>
        <li>Microsoft Edge</li>
      </ul>
      <p>
        Az alkalmazás teszt és éles környezetet is biztosít, a hivatkozásokat
        lásd lentebb.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/login-screen.png"
      />
      <p>
        A Bejelentkezés oldalon egyetlen gomb található: Bejelentkezés. Erre
        kattintva a PwC Identity bejelentkeztető rendszerén keresztül tudunk
        belépni az alkalmazásba. Első lépésben az email címet kell megadni:
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-email.png"
      />
      <p>Második lépésben a jelszót kell megadni:</p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-password.png"
      />
      <p>
        Harmadik lépésben a két faktoros belépéshez kell kiválasztani, hogy
        milyen formában érkezzen meg a hitelesítő kód.
      </p>
      <p>Három féle mód közül lehet választani:</p>
      <ul>
        <li>Email formában a megadott email címre</li>
        <li>Szöveges üzenet formában a megadott telefonszámra</li>
        <li>Telefonhívásként a megadott telefonszámon</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-2fa.png"
      />
      <p>
        A rendszer 1 óra után automatikusan kiléptet, utána újra be kell
        jelentkezni.
      </p>
    </div>

    <!--Chapter 4.1.1-->
    <app-chapter
      [chapterId]="'4.1.1'"
      [chapterName]="'Teszt oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A teszt oldal tesztelési célokra szolgál, az
        <i>Online Riporting Tool</i> alkalmazás teszt felületéről szinkronizálja
        az adatokat.
      </p>
      <p>
        A teszt oldal elérhetősége:
        <a href="https:/attorney-dev.payee.tech" target="_blank"
          >https:/attorney-dev.payee.tech</a
        >
      </p>
    </div>

    <!--Chapter 4.1.2-->
    <app-chapter
      [chapterId]="'4.1.2'"
      [chapterName]="'Éles oldalak'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az éles oldal használható a rendszer partnerei számára. Az Online
        Riporting Tool alkalmazás éles felületéről szinkronizálja az adatokat.
      </p>
      <p>
        Az éles oldal elérhetősége:
        <a
          href="https:/attorney.realtimedebtmanagement.hu.pwc.com"
          target="_blank"
          >https:/attorney.realtimedebtmanagement.hu.pwc.com</a
        >
      </p>
    </div>

    <!--Chapter 4.2-->
    <app-chapter
      [chapterId]="'4.2'"
      [chapterName]="'Összes ügy oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Összes ügy</i> oldalra navigálva az oldal az alábbi két főbb
        egységet tartalmazza:
      </p>
      <ul>
        <li>Szűrők</li>
        <li>Ügyek listája</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/attorney-cases-page.png"
      />
    </div>

    <!--Chapter 4.2.1-->
    <app-chapter
      [chapterId]="'4.2.1'"
      [chapterName]="'Ügyek listázása, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A listában elérhető minden olyan ügy, amely az adott ügyvédhez van
        rendelve.
      </p>
      <p>
        A találatokat <i>“CLIENT/DEBTOR/ÜGYAZONOSÍTÓ”</i> címkék melletti
        inputokkal lehet szűrni. <br />
        A <i>CLIENT</i> és a <i>DEBTOR</i> kitöltésének elkezdésekor
        automatikusan felajánlja a rendszerben lévő Hitelezők és Adósokat, a
        listából kell kiválasztani a megfelelő szűrési feltételt. <br />
        <i>ÜGYAZONOSÍTÓ</i> kitöltése szabadszavas.
      </p>
      <p>
        Az egyes szűrési feltételek törlése az inputok mellett található kuka
        ikonnal lehetséges.
      </p>
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/cases-list-filtered.png"
      />
    </div>

    <!--Chapter 4.2.2-->
    <app-chapter
      [chapterId]="'4.2.2'"
      [chapterName]="'Ügy megtekintése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyek lista</i> egy sorára kattintva lenyílik az adott ügy
        részletei panel. A panel 2 tab-ot tartalmaz:
      </p>
      <ul>
        <li>Események</li>
        <li>Részletek</li>
      </ul>

      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
    </div>

    <!--Chapter 4.2.3-->
    <app-chapter
      [chapterId]="'4.2.3'"
      [chapterName]="'Ügy megtekintése / Események'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Események</i> tab alatt az ügy indítása óta történt események
        utolsó 3 eseményét listázza.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
      <p>
        Itt az <i>Összes esemény mutatása</i> gombra kattintva az ügy indítása
        óta bekövetkezett eseményeket lehet megtekinteni.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-all.png"
      />
      <p>
        Az események között - ha az eseményhez tartozott letölthető állomány,
        akkor innen letölthető:
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-download-doc.png"
      />
    </div>

    <!--Chapter 4.2.4-->
    <app-chapter
      [chapterId]="'4.2.4'"
      [chapterName]="'Ügy megtekintése / Részletek'"
    ></app-chapter>
    <div class="chapter-content">
      <p>A <i>Részletek</i> tab alatt az ügy részletei láthatóak.</p>
      <p>
        Az alábbi főbb adatokból épül fel, függően az elindított ügy típusától:
      </p>
      <ul>
        <li><b>Ügyazonosító:</b> A rendszer által létrehozott ügy azonosító</li>
        <li><b>Adós neve:</b> Akivel szemben az ügy indult.</li>
        <li><b>Adós címe:</b> Adós címe</li>
        <li><b>Szöveges indoklás:</b> Ügyhöz tartozó szöveges indoklás</li>
        <li>
          <b>Követelések:</b> Soronként felsorolva az egyes követelések,
          lejárati határidővel és összeggel
        </li>
        <li>
          <b>Járulékos követelések:</b> Soronként ügytípustól függően lehet
          Törvényes kamatok, 40 EUR behajtási költségátalány, Ügyintézési díj,
          összeggel
        </li>
        <li>
          <b>Teljes követelés:</b> A Követelések és a Járulékos követelések
          összege
        </li>
        <li>
          <b>Kommunikáció nyelve:</b> Az Adós felé történő értesítések ezen a
          nyelven történnek (Magyar vagy Angol)
        </li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-details.png"
      />
    </div>

    <!--Chapter 4.2.5-->
    <app-chapter
      [chapterId]="'4.2.5'"
      [chapterName]="'Ügyek műveletei'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Ügyek listájában</i> minden sor végén látható a ügyek műveletei
        lenyíló menü. Itt az adott ügy adott életciklusához tartozó műveleteket
        tudjuk végrehajtani.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-details-event-dropdown.png"
      />
      <p>
        Amennyiben nincs az ügyhöz elérhető művelet,
        <i>Nincs elérhető esemény</i> felirat látható az opciók helyén.
      </p>

      <p><b>Általános popup</b></p>

      <p>
        Az <i>Ügyvédi felületen</i> elérhető legtöbb esemény az alábbi három fő
        csoportba tartozik:
      </p>
      <ul>
        <li>Csak belső megjegyzés rögzíthető</li>
        <li>Csak csatolmány rögzíthető</li>
        <li>Belső megjegyzés, csatolmány és határidő rögzíthető</li>
      </ul>
      <p>Az egyes beviteli mezők jellemzői:</p>
      <dl>
        <dt>Belső megjegyzés</dt>
        <dd>
          Belső megjegyzésben lehet elhelyezni olyan tartalmakat, amik az ügyvéd
          számára hasznos. Ezek a Ügyfél felületen nem láthatóak, kizárólag az
          Ügyvédi és Admin felületeken elérhetőek.
        </dd>
      </dl>
      <dl>
        <dt>Határidő</dt>
        <dd>
          Az ügymenet során amennyiben határidő kerül beállításra, akkor a
          következő lépés határideje a megadott dátum. Az ügymenet következő
          lépésének szereplője számára látható, amennyiben email küldéssel jár a
          levélben is megtalálható.
        </dd>
      </dl>
      <p>
        Az ügyek műveletei listában az alábbi eseményekkel találkozhatunk,
        melyek felhasználói interakciót igényelnek:
      </p>
      <dl>
        <dt>Csatolmány</dt>
        <dd>
          Bármilyen az ügyhöz kapcsolódó dokumentum, amely az ügymenet következő
          lépésének szereplője számára látható, letölthető mindhárom felületről.
          Amennyiben email küldéssel jár a lépés, csatolmányként elérhető a
          kiküldött levélben.
        </dd>
      </dl>
      <p>Alább látható egy minta amelyen az összes beviteli mező látható:</p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-popup-attorney-example.png"
      />

      <p><b>Ügyvédi fájl generálása</b></p>
      <p>
        Legenerálja az ügyhöz tartozó specifikus fájlokat. Ügyvédi levél esetén
        ez egy nyomtatható dokumentum, fizetési meghagyás esetén pedig egy
        speciális formátumú
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/cases-popup-attorney-generate-attorney-file.png"
      />
    </div>

    <app-chapter
      [chapterId]="'4.3'"
      [chapterName]="'Kijelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Kijelentkezni a fejlécben található, jobbról a második ikonnal lehet:
        <img
          class="mw-50 d-inline-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/attorney-admin-logout.png"
        />
      </p>
    </div>

    <!-- ADMIN --->

    <app-chapter
      [chapterId]="'5'"
      [chapterName]="'Adminisztrációs felület'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az Adminisztációs felület az adminok számára kialakított védett oldal,
        ahol az ügyeket lehet teljeskörűen menedzselni, jogosultságokat
        (felhasználókat) és hitelezőket kezelni.
      </p>
    </div>

    <app-chapter
      [chapterId]="'5.1'"
      [chapterName]="'Bejelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A rendszer egy webes szolgáltatás, amely internetes böngésző segítégével
        érhető el.
      </p>
      <p>Támogatott böngészők:</p>
      <ul>
        <li>Google Chrome (preferált)</li>
        <li>Mozilla Firefox</li>
        <li>Microsoft Edge</li>
      </ul>
      <p>
        Az alkalmazás teszt és éles környezetet is biztosít, a hivatkozásokat
        lásd lentebb.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/login-screen.png"
      />
      <p>
        A Bejelentkezés oldalon egyetlen gomb található: Bejelentkezés. Erre
        kattintva a PwC Identity bejelentkeztető rendszerén keresztül tudunk
        belépni az alkalmazásba. Első lépésben az email címet kell megadni:
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-email.png"
      />
      <p>Második lépésben a jelszót kell megadni:</p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-password.png"
      />
      <p>
        Harmadik lépésben a két faktoros belépéshez kell kiválasztani, hogy
        milyen formában érkezzen meg a hitelesítő kód.
      </p>
      <p>Három féle mód közül lehet választani:</p>
      <ul>
        <li>Email formában a megadott email címre</li>
        <li>Szöveges üzenet formában a megadott telefonszámra</li>
        <li>Telefonhívásként a megadott telefonszámon</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/pwc-login-2fa.png"
      />
      <p>
        A rendszer 1 óra után automatikusan kiléptet, utána újra be kell
        jelentkezni.
      </p>
    </div>

    <!--Chapter 5.1.1-->
    <app-chapter
      [chapterId]="'5.1.1'"
      [chapterName]="'Teszt oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A teszt oldal tesztelési célokra szolgál, az Online Riporting Tool
        alkalmazás teszt felületéről szinkronizálja az adatokat.
      </p>
      <p>
        A teszt oldal elérhetősége:
        <a href="https:/admin-dev.payee.tech" target="_blank"
          >https:/admin-dev.payee.tech</a
        >
      </p>
    </div>

    <!--Chapter 5.1.2-->
    <app-chapter
      [chapterId]="'5.1.2'"
      [chapterName]="'Éles oldalak'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az éles oldal használható a rendszer partnerei számára. Az Online
        Riporting Tool alkalmazás éles felületéről szinkronizálja az adatokat.
      </p>
      <p>
        Az éles oldal elérhetősége:
        <a href="https:/admin.realtimedebtmanagement.hu.pwc.com" target="_blank"
          >https:/admin.realtimedebtmanagement.hu.pwc.com</a
        >
      </p>
    </div>

    <!--Chapter 5.2-->
    <app-chapter
      [chapterId]="'5.2'"
      [chapterName]="'Összes ügy oldal'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az Összes ügy oldalra navigálva az oldal az alábbi két főbb egységet
        tartalmazza:
      </p>
      <ul>
        <li>Szűrők</li>
        <li>Ügyek listája</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/admib-cases-page.png"
      />
    </div>

    <!--Chapter 5.2.1-->
    <app-chapter
      [chapterId]="'5.2.1'"
      [chapterName]="'Ügyek listázása, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A listában elérhető minden olyan ügy, amely az adott ügyvédhez van
        rendelve.
      </p>
      <p>
        A találatokat <i>“CLIENT/DEBTOR/ÜGYAZONOSÍTÓ”</i> címkék melletti
        inputokkal lehet szűrni. A <i>CLIENT</i> és a <i>DEBTOR</i> kitöltésének
        elkezdésekor automatikusan felajánlja a rendszerben lévő Hitelezők és
        Adósokat, a listából kell kiválasztani a megfelelő szűrési feltételt.
        <i>ÜGYAZONOSÍTÓ</i> kitöltése szabadszavas.
      </p>
      <p>
        Az egyes szűrési feltételek törlése az inputok mellett található kuka
        ikonnal lehetséges.
      </p>
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="assets/shared/user-guide/cases-list-filtered.png"
      />
    </div>

    <!--Chapter 5.2.2-->
    <app-chapter
      [chapterId]="'5.2.2'"
      [chapterName]="'Ügy megtekintése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Ügyek lista</i> egy sorára kattintva lenyílik az adott ügy
        részletei panel. A panel 2 tab-ot tartalmaz:
      </p>
      <ul>
        <li>Események</li>
        <li>Részletek</li>
      </ul>

      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
    </div>

    <!--Chapter 5.2.3-->
    <app-chapter
      [chapterId]="'5.2.3'"
      [chapterName]="'Ügy megtekintése / Események'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Események</i> tab alatt az ügy indítása óta történt események
        utolsó 3 eseményét listázza.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events.png"
      />
      <p>
        Itt az <i>Összes esemény mutatása</i> gombra kattintva az ügy indítása
        óta bekövetkezett eseményeket lehet megtekinteni.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-all.png"
      />
      <p>
        Az események között - ha az eseményhez tartozott letölthető állomány,
        akkor innen letölthető:
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-events-download-doc.png"
      />
    </div>

    <!--Chapter 5.2.4-->
    <app-chapter
      [chapterId]="'5.2.4'"
      [chapterName]="'Ügy megtekintése / Részletek'"
    ></app-chapter>
    <div class="chapter-content">
      <p>A <i>Részletek</i> tab alatt az ügy részletei láthatóak.</p>
      <p>
        Az alábbi főbb adatokból épül fel, függően az elindított ügy típusától:
      </p>
      <ul>
        <li><b>Ügyazonosító:</b> A rendszer által létrehozott ügy azonosító</li>
        <li><b>Adós neve:</b> Akivel szemben az ügy indult.</li>
        <li><b>Adós címe:</b> Adós címe</li>
        <li><b>Szöveges indoklás:</b> Ügyhöz tartozó szöveges indoklás</li>
        <li>
          <b>Követelések:</b> Soronként felsorolva az egyes követelések,
          lejárati határidővel és összeggel
        </li>
        <li>
          <b>Járulékos követelések:</b> Soronként ügytípustól függően lehet
          Törvényes kamatok, 40 EUR behajtási költségátalány, Ügyintézési díj,
          összeggel
        </li>
        <li>
          <b>Teljes követelés:</b> A Követelések és a Járulékos követelések
          összege
        </li>
        <li>
          <b>Kommunikáció nyelve:</b> Az Adós felé történő értesítések ezen a
          nyelven történnek (Magyar vagy Angol)
        </li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/case-list-item-details.png"
      />
    </div>

    <!--Chapter 5.2.5-->
    <app-chapter
      [chapterId]="'5.2.5'"
      [chapterName]="'Ügyek műveletei'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Az <i>Admin felületen</i> egy adott ügyhöz az összes műveletet el lehet
        végezni, amit a <i>Felhasználói felületen</i> és az
        <i>Ügyvédi felületeken</i> lehet. Ezeknek leírásai a
        <a href="/user-guide#3.3.5">Ügyfél felület ügyek műveletei</a> valamint
        az <a href="/user-guide#4.2.5"> Ügyvédi felület</a> - ügyek műveletei
        részben olvasható
      </p>
    </div>

    <!--Chapter 5.3-->
    <app-chapter [chapterId]="'5.3'" [chapterName]="'Hitelezők'"></app-chapter>
    <div class="chapter-content">
      <p>
        A Hitelezők oldalon láthatóak a rendszerbe felvitt Hitelezők. 3 főbb
        részből áll:
      </p>
      <ul>
        <li>Hitelezők listája</li>
        <li>Szűrés hitelezőre</li>
        <li>Új hitelező hozzáadása</li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-clients-page.png"
      />
    </div>

    <!--Chapter 5.3.1-->
    <app-chapter
      [chapterId]="'5.3.1'"
      [chapterName]="'Hitelezők listája, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Hitelezők listájában</i> láthatóak a rendszerben felvett hitelezők.
      </p>
      <p>Egy sor az alábbi adatokkal és funkciókkal rendelkezik:</p>
      <ul>
        <li><b>Hitelező neve:</b> Hitelező neve</li>
        <li><b>Hitelező adószáma:</b> Hitelező adószáma</li>
        <li><b>Futó ügyek:</b> Jelenleg aktív, futó ügyek darabszáma</li>
        <li><b>Lezárt ügyek:</b> Lezárt ügyek darabszáma</li>
        <li>
          <b>Hitelező ügyei:</b> A gombra kattintva az Összes ügy oldara
          kerülünk, ahol az adott hitelező összes ügye látható szűrve.
        </li>
        <li>
          <b>Hitlező szerkesztése:</b> A gombra kattintva a hitelező adatainak
          szerkesztése lehetséges.
        </li>
        <li>
          <b>Hitelező törlése:</b> A gombra kattintva a hitelezőt lehet törölni.
        </li>
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-clients-list.png"
      />
      <p>
        Az adatokat sorba lehet rendezni a táblázat oszlopainak fejléceire
        kattintva. Az alábbiak oszlopok szerint lehet rendezni: Hitelező neve,
        Hitelező adószáma, Futó ügyek, Lezárt ügyek
      </p>
      <p>
        Egyszer rákattintva növekvő sorrendbe, még egyszer kattintva csökkenő
        sorrendbe rendezi őket.
      </p>
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <p>A találatokat a <i>Hitelező neve</i> inputtal lehet szűrni.</p>
    </div>

    <!--Chapter 5.3.2-->
    <app-chapter
      [chapterId]="'5.3.2'"
      [chapterName]="'Új hitelező hozzáadása'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Új hitelező felvételéhez az <i>Új hitelező</i> gombot kell megnyomni
        melynek hatására megjelenik az <i>Új hitelező</i> popup benne a
        kitöltendő adatokkal.
      </p>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-clients-add-client-popup-empty.png"
      />
      <p>Az űrlapon az alábbi adatokat kell kitölteni:</p>
      <dl>
        <dt>Hitelező</dt>
        <dd>
          Hitelező neve. Szövegesen kell beírni, majd gazdálkodó szervezet
          esetén cég adatbázis alapján a listából ki kell választani a
          megfelelőt. Az adószámot cég adatbázisból automatikusan hozzárendeli a
          kiválasztott céghez a rendszer:
        </dd>
        <img
          class="mw-400 d-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/admin-add-client-popup-name-autocomplete.png"
        />

        <dt>Hitelező típusa</dt>
        <dd>
          Hitelező működési formája. Választó input, belekattintva megjelennek
          az elérhető hitelezői típusok. Szövegbeírás esetén szűkíti a találati
          listát. Amennyiben a hitelező meg lett adva az előző lépésben,
          automatikusan kiválasztásra kerül a cég adatbázis adatai alapján:
        </dd>
        <img
          class="mw-400 d-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/admin-add-client-popup-client-type-select.png"
        />

        <dt>Hitelező ügyeihez rendelt alapértelmezett jogviszony</dt>
        <dd>
          Hitelező - Adós alapértelmezett jogviszonya. Választó input,
          belekattintva megjelennek az elérhető jogviszonyok. Szövegbeírás
          esetén szűkíti a találati listát. Az itt megadott érték lesz az
          ügyindításkor az alapértelemezett jogviszony:
        </dd>
        <img
          class="mw-400 d-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/admin-add-client-popup-client-debtor-default-relation.png"
        />

        <dt>Hitelező ügyeihez rendelt alapértelmezett követelés típus</dt>
        <dd>
          Hitelező - Adós követelésének alapértelmezett értéke. Választó input,
          belekattintva megjelennek az elérhető követelés típusok. Szövegbeírás
          esetén szűkíti a találati listát. Tartalma a
          <i>Hitelező ügyeihez rendelt alapértelmezett jogviszony</i> input
          kiválasztásakor változik. Az itt megadott érték lesz az ügyindításkor
          az alapértlemezett követelés típus.
        </dd>
        <img
          class="mw-400 d-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/admin-add-client-popup-client-debtor-default-claim-type.png"
        />

        <dt>Számlák szinkronizálása ettől a dátumtól kezdve</dt>
        <dd>
          Az <i>Online Reporting Tool</i> rendszeréből ettől a dátumtól kezdi
          meg a számlák szinkronizálást a <i>Hitelező</i> adószáma alapján. A
          legkorábbi dátum a mai nap -5 év. Amennyiben egy dátum beállításra
          kerül és megkezdődik a szinkronizálás a háttérben, addig
          szerkesztésnél nem lehet újabb dátumot kiválasztani a szinkronizálás
          befejeztéig. Szerkesztéskor pedig az alábbi üzenet jelenik meg a dátum
          választó helyett: <i>Számlák szinkronizálása folyamatban van</i>
          Amennyiben egy dátum már ki lett választva és a szinkronizálás
          megtörtént, onnantól kezdve későbbi időpontot nem lehet kiválasztani.
        </dd>
        <img
          class="mw-400 d-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/admin-add-client-popup-sync-date.png"
        />
      </dl>
    </div>

    <!--Chapter 5.3.3-->
    <app-chapter
      [chapterId]="'5.3.3'"
      [chapterName]="'Hitelező szerkesztése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Hitelezőt szerkeszteni a Hitelezők listájában, a
        <i>Hitelező szerkesztése</i> gombra kattintva lehet. Ennek hatására
        megjelenik az alábbi popup a következő adatokkal:
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-edit-client-popup.png"
      />
      <dl>
        <dt>Hitelező</dt>
        <dd>Hitelező neve. Nem változtatható.</dd>

        <dt>Hitelező típusa</dt>
        <dd>
          Hitelező működési formája. Választó input, belekattintva megjelennek
          az elérhető hitelezői típusok. Szövegbeírás esetén szűkíti a találati
          listát. Amennyiben a hitelező meg lett adva az előző lépésben,
          automatikusan kiválasztásra kerül a cég adatbázis adatai alapján.
        </dd>

        <dt>Hitelező ügyeihez rendelt alapértelmezett jogviszony</dt>
        <dd>
          Hitelező - Adós alapértelmezett jogviszonya. Választó input,
          belekattintva megjelennek az elérhető jogviszonyok. Szövegbeírás
          esetén szűkíti a találati listát. Az itt megadott érték lesz az
          ügyindításkof az alapértelemezett jogviszony:
        </dd>

        <dt>Hitelező ügyeihez rendelt alapértelmezett követelés típus</dt>
        <dd>
          Hitelező - Adós követelésének alapértelmezett értéke. Választó input,
          belekattintva megjelennek az elérhető követelés típusok. Szövegbeírás
          esetén szűkíti a találati listát. Tartalma a
          <i>Hitelező ügyeihez rendelt alapértelmezett jogviszony</i> input
          kiválasztásakor változik. Az itt megadott érték lesz az ügyindításkor
          az alapértlemezett követelés típus.
        </dd>

        <dt>Számlák szinkronizálása ettől a dátumtól kezdve</dt>
        <dd>
          Az <i>Online Reporting Tool rendszeréből</i> ettől a dátumtól kezdi
          meg a számlák szinkronizálást a Hitelező adószáma alapján. A
          legkorábbi dátum a mai nap -5 év. Amennyiben egy dátum beállításra
          kerül és megkezdődik a szinkronizálás a háttérben, addig
          szerkesztésnél nem lehet újabb dátumot kiválasztani a szinkronizálás
          befejeztéig. Szerkesztéskor pedig az alábbi üzenet jelenik meg a dátum
          választó helyett: <i>Számlák szinkronizálása folyamatban van</i>
          Amennyiben egy dátum már ki lett választva és a szinkronizálás
          megtörtént, onnantól kezdve későbbi időpontot nem lehet kiválasztani.
        </dd>
      </dl>
    </div>

    <!--Chapter 5.3.4-->
    <app-chapter
      [chapterId]="'5.3.4'"
      [chapterName]="'Hitelező törlése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Hitelezőt törölni a Hitelezők listájában, a
        <i>Hitelező törlése</i> gombra kattintva lehet. A gomb csak abban az
        esetben kattintható, ha nincs a <i>Hitelezőhöz</i>
        <i>Felhasználó</i> rendelve, valamint nem lett még ügy indítva.
      </p>
      <p>
        Amennyiben kattintható a <i>Hitelező törlése</i> gomb, megnyomásákor az
        alábbi megerősítő popup jelenik meg:
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-delete-client-confirm-popup.png"
      />
      <p>
        A <i>Törlés</i> gombra kattintva véglegesíthető a törlés, a
        <i>Mégsem</i> gomb pedig visszavonja a törlési igényt. Bármely gombra
        kattintva a popup bezárul.
      </p>
    </div>

    <!--Chapter 5.4-->
    <app-chapter
      [chapterId]="'5.4'"
      [chapterName]="'Jogosultságok'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Jogosultságok</i> menüpont alatt találhatóak a rendszer
        felhasználói, itt lehet az egyes felületekhez (Ügyfél, Admin, Attorney)
        hozzáférést adni, beállítani a felhasználónak a jogosultsági szintjét
        (user, admin, attorney, Super-Admin). Egy <i>Felhasználó</i> több
        jogosultsággal is rendelkezhet, valamint több <i>Hitelezői fiók</i> alá
        tartozhat.
      </p>

      <p>
        Az egyes jogosultsági szintek determinálják az egyes oldalakhoz való
        hozzáférést:
      </p>
      <ul>
        <li><b>user:</b> Ügyfél felület</li>
        <li><b>admin:</b> Admin felület</li>
        <li><b>attorney:</b> Attorney felület</li>
        <li><b>Super Admin:</b> Minden felülethez hozzáfér</li>
      </ul>

      <p>
        Super-Admin jogosultságot csak a rendszer üzemeltetői állíthatnak be.
        Admin jogosultságot csak Super-Admin jogosultsággal lehet kiosztani.
      </p>

      <p>
        Super-Admin jogosultság birtokában minden felülethez és minden
        hitelezőhöz hozzáfér a felhasználó.
      </p>

      <p>3 főbb egységből áll:</p>
      <ul>
        <li>Felhasználók listája</li>
        <li>Szűrés felhasználóra</li>
        <li>Felhasználó hozzáadása</li>
      </ul>

      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-users-page.png"
      />
    </div>

    <!--Chapter 5.4.1-->
    <app-chapter
      [chapterId]="'5.4.1'"
      [chapterName]="'Felhasználók listája, szűrése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        A <i>Felhasználók listájában</i> láthatóak a rendszerben felvett
        felhasználók.
      </p>
      <ul>
        <li>Felhasználó email cím:</li>
        Felhasználó email címe
        <li>Felhasználó neve:</li>
        Felhasználó neve
        <li>Jogosultságok:</li>
        Soronként tartalmazza a felhasználó jogosultságait
        <li>Szerkesztés:</li>
        A gombra kattintva lehet szerkeszteni az adott felhasználót.
      </ul>
      <img
        class="mw-1200 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-users-list.png"
      />
      <p>
        Az adatokat sorba lehet rendezni a táblázat oszlopainak fejléceire
        kattintva. Az alábbiak oszlopok szerint lehet rendezni:
        <i>Felhasználó email címe</i>, <i>Felhasználó neve</i>,
        <i>Jogosultságok</i>, <i>Hitelezők</i>.
      </p>
      <p>
        Egyszer rákattintva növekvő sorrendbe, még egyszer kattintva csökkenő
        sorrendbe rendezi őket.
      </p>
      <p>
        A táblázat alján lévő lapozóval tudunk további sorokat megtekinteni a
        táblázatban. Ezen a részen tudjuk megadni továbbá azt is, hogy egyszerre
        hány sor látszódjon (10, 25, 50 vagy 100).
      </p>
      <p>
        A találatokat a <i>Felhasználó email címe</i> inputtal lehet szűrni.
      </p>
    </div>

    <!--Chapter 5.4.2-->
    <app-chapter
      [chapterId]="'5.4.2'"
      [chapterName]="'Felhasználó létrehozása'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Új felhasználó felvételéhez az <i>Új felhasználó</i> gombot kell
        megnyomni melynek hatására megjelenik az <i>Új hitelező</i> popup benne
        a kitöltendő adatokkal.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-add-user-popup.png"
      />
      <p>A formban az alábbi adatokat kell kitölteni:</p>
      <ul>
        <li>
          <b>Felhasználó email címe:</b> Felhasználó email címe, kizárólag email
          címet fogad el
        </li>
        <li><b>Felhasználó neve:</b> Felhasználó neve</li>
        <li>
          <b>Felhasználó telefonszáma:</b> Felhasználó telefonszáma. Megadása
          nem kötelező.
        </li>
        <li>
          <b>Felhasználó jogosultságai:</b> A felhasználó milyen
          jogosultságokkal rendelkezzen és ezáltal milyen felülethez férjen
          hozzá. Egy felhasználóhoz több is megjelölhető. Admin jogosultság csak
          Super-Admin felhasználónák jelenik meg.
        </li>
        <li>
          <b>Felhasználó által elérhető cégek:</b> Minimum 3 karakter
          begépelésekor a rendszerben elérhető hitelezőket jeleníti meg a
          listában, ahonnan ki kell választani a megfelelőt.
        </li>
      </ul>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-add-user-filter-clients.png"
      />
      <p>
        Egy Felhasználóhoz több hitelezőt is lehet rendelni. A kiválasztott
        hitelezők a beviteli mező alatt jelennek meg listaszerűen. Eltávolítani
        a Hitelezőt a kuka ikonnal lehet.
      </p>
      <img
        class="mw-400 d-block-center"
        appLazyLoad
        src="../../assets/shared/user-guide/admin-add-user-selected-clietns.png"
      />
    </div>

    <!--Chapter 5.4.3-->
    <app-chapter
      [chapterId]="'5.4.3'"
      [chapterName]="'Felhasználó szerkesztése'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Felhasználó szerkesztéséhez a <i>Felhasználók listájában</i> a
        Szerkesztés gombra kattintva van lehetőség. A felhasználó szerkesztési
        űrlap megegyezik a
        <a href="/user-guide#5.4.3">Felhasználó hozzáadása</a> résznél
        leírtakkal.
      </p>
    </div>

    <!--Chapter 5.5-->
    <app-chapter
      [chapterId]="'5.5'"
      [chapterName]="'Kijelentkezés'"
    ></app-chapter>
    <div class="chapter-content">
      <p>
        Kijelentkezni a fejlécben található, jobbról a második ikonnal lehet:
        <img
          class="mw-50 d-inline-block-center"
          appLazyLoad
          src="../../assets/shared/user-guide/attorney-admin-logout.png"
        />
      </p>
    </div>
  </div>
</div>
