import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EhazSsoApiService } from '../../../services/ehaz/ehaz-sso-api.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Case } from 'src/types';

@Component({
  selector: 'app-ehaz-sso',
  templateUrl: './ehaz-sso.component.html',
  styleUrls: ['./ehaz-sso.component.scss']
})
export class EhazSsoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eHazSsoApiService: EhazSsoApiService,
    private authService: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    const token = this.route.snapshot.queryParams.token;
    if (!token) {
      await this.router.navigate(['sso/ehaz/error']);
      return;
    }

    await this.manageEhazSso(token);
  }

  private async manageEhazSso(token: string) {
    try {
      const eHazSsoResult = await this.eHazSsoApiService.eHazSso(token);

      this.authService.clearTokens();
      this.authService.setPersistance('session');
      this.authService.setTokens({
        access_token: eHazSsoResult.access_token,
        expires_in: eHazSsoResult.expires_in,
        id_token: null,
        refresh_token: null,
      });

      await this.router.navigateByUrl(`/case/${eHazSsoResult.payee_case_id}`);
    } catch (error) {
      console.log('Error while managing ehaz sso', error);
      await this.router.navigate(['sso/ehaz/error']);
    }
  }
}
