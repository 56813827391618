import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonePipe'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: string): string {
    if (!phoneValue) {
      return '';
    }

    const value = phoneValue.replace(/[^\d+]/g, '');
    let format: {
      index: number, length: number,
    }[];
    if (value.substr(0, 3) === '+36') {
      format = [{
        index: 0,
        length: 3,
      }, {
        index: 3,
        length: 2,
      }, {
        index: 5,
        length: 3,
      }, {
        index: 8,
        length: 4,
      }];
    } else if (value.substr(0, 3) === '+40') {
      format = [{
        index: 0,
        length: 3,
      }, {
        index: 3,
        length: 3,
      }, {
        index: 6,
        length: 3,
      }, {
        index: 9,
        length: 3,
      }];
    } else if (value.substr(0, 3) === '+44') {
      // UK phone number
      format = [{
        // Country code
        index: 0,
        length: 3,
      }, {
        // National destination
        index: 3,
        length: 4,
      }, {
        // Subscriber number
        index: 7,
        length: 6,
      }];
    } else {
      if (value[0] !== '+') {
        return '+36' + value;
      }
      return value;
    }

    return formatString(value, format);
  }
}

function formatString(str: string, format: {
  index: number, length: number,
}[]) {
  const pieces: string[] = [];
  for (const f of format) {
    if (f.index > str.length) {
      break;
    }
    if (f.index === str.length) {
      pieces.push(null);
    } else if (f.length) {
      pieces.push(str.substr(f.index, f.length));
    } else {
      pieces.push(str.substr(f.index));
    }
  }
  return pieces.join(' ');
}
