<div class="payment-method-wrapper">
  <h1>
    Válassz fizetési módot
  </h1>
  <h2>
    Fizetési módok:
  </h2>

  <!--payment method selector radio buttons-->
  <div class="payment-method-radio-buttons-wrapper">
    <!--credit card payment method radio button-->
    <div class="credit-card-payment-wrapper" (click)="creditCardPaymentSelected(true)">

      <!--radio button-->
      <div class="radio-button-wrapper">
        <payee-icon *ngIf="isCreditCardPayment" [name]="environment.RADIO_CHECKED_ICON" width="20px"></payee-icon>
        <payee-icon *ngIf="!isCreditCardPayment" [name]="'radio-unchecked-grey'" width="20px"></payee-icon>
      </div>

      <!--credit card payment title and description-->
      <div class="payment-method-title-wrapper">
        <div [ngClass]="isCreditCardPayment ? 'payment-method-title-checked' : 'payment-method-title-unchecked'">
          Bankkártyával fizetek
        </div>
        <div class="payment-method-description">
          Biztonságos és gyors
        </div>
      </div>

      <!--credit card payment logos-->
      <div class="payment-logos-wrapper">
        <div class="recommended-text-wrapper">
          <span>AJÁNLOTT</span>
        </div>
        <payee-icon [name]="'barion'" height="14px"></payee-icon>
        <payee-icon [name]="'visa'" height="14px"></payee-icon>
        <payee-icon [name]="'visa-electron'" height="14px"></payee-icon>
        <payee-icon [name]="'mastercard'" height="14px"></payee-icon>
        <payee-icon [name]="'maestro'" height="14px"></payee-icon>
        <payee-icon [name]="'amex'" height="14px"></payee-icon>
      </div>
    </div>


    <!--money transfer payment method radio button-->
    <div class="transfer-payment-wrapper" (click)="creditCardPaymentSelected(false)">
      <!--radio button-->
      <div class="radio-button-wrapper">
        <payee-icon *ngIf="isCreditCardPayment" [name]="'radio-unchecked-grey'" width="20px"></payee-icon>
        <payee-icon *ngIf="!isCreditCardPayment" [name]="environment.RADIO_CHECKED_ICON" width="20px"></payee-icon>
      </div>

      <!--paying via money transfer title and description-->
      <div class="payment-method-title-wrapper">
        <div [ngClass]="!isCreditCardPayment ? 'payment-method-title-checked' : 'payment-method-title-unchecked'">
          Átutalással fizetek
        </div>
        <div class="payment-method-description">
          24 óra átfutási idő
        </div>
      </div>
    </div>
  </div>

  <!--payment submit button-->
  <button *ngIf="isCreditCardPayment" payee-button class="payment-button" color="blue" height="tall" (click)="startPayment()">
    {{paymentStepService.paymentStepData.start_case_price | number:'1.0':'hu'}} HUF fizetése most
  </button>
  <button *ngIf="!isCreditCardPayment" payee-button class="payment-button" color="blue" height="tall" (click)="startPayment()">
    {{paymentStepService.paymentStepData.start_case_price | number:'1.0':'hu'}} HUF-ról díjbekérőt kérek
  </button>

  <!--payment description-->
  <div class="payment-description">
    A fizetést a
    <a href="https://help.payee.tech/hu/articles/6228854-impresszum" target="_blank"><u>Legal Labs Kft.</u></a>
    fogadja és dolgozza fel biztonságosan. A tranzakcióról
    ÁFA-s elektronikus számlát küldünk a fizetésed után emailben.
  </div>
</div>
