import { Component, OnInit } from '@angular/core';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';

@Component({
  selector: 'app-szamlazzhu-sso',
  templateUrl: './szamlazzhu-sso.component.html',
  styleUrls: ['./szamlazzhu-sso.component.scss']
})
export class SzamlazzhuSsoComponent implements OnInit {
  constructor(
    private szamlazzhuService: SzamlazzhuService,
  ) { }

  async ngOnInit() {
    await this.szamlazzhuService.redirectToSzamlazzhuLogin();
  }

}
