import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FormExitWarningDialogComponent
} from 'src/app/aa-new-form/dialogs/form-exit-warning-dialog/form-exit-warning-dialog.component';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { FormService, FormStepName } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  selectedStepIndex = 0;
  partnerLogoName = '';

  stepWidth = '206px';
  steps = [
    'debtor',
    'client',
    'payment',
  ];
  stepNames = {
    debtor: 'Az Ön követelései',
    client: 'Az Ön cége',
    payment: 'Rendelés befejezése',
  };

  get isLogoClickable(): boolean { //return !this.caseFormDataService.caseData?.partnerId; TODO
    return true;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private formService: FormService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: params => {
        const step = params.step as FormStepName;
        if (this.steps.includes(step)) {
          this.selectedStepIndex = this.steps.indexOf(step);
        }
      }
    });
  }

  async navigateToStep(index: number) {
    if (index < this.selectedStepIndex) {
      switch (index) {
        case 0: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/debtor`]);
          this.selectedStepIndex = index;
          break;
        }
        case 1: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/client`]);
          this.selectedStepIndex = index;
          break;
        }
      }
    }
  }

  onLogoClick() {
    if (!this.isLogoClickable) {
      return;
    }

    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.formService.case.payee_case_reference_id,
      }
    });
  }

  onExitButtonClick() {
    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.formService.case.payee_case_reference_id,
      }
    });
  }

  readonly environment = environment;
}
