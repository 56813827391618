export interface PayeeFile {
    uuid: number,
    file_name: string,
    file: File,
}

export function getEmptyPayeeFile(): PayeeFile {
    return {
        uuid: null,
        file_name: '',
        file: null,
    };
}
