import { Component } from '@angular/core';
import { FormTypeEnum } from "../../../init-step/models/common/form-type-enum";
import { SummaryStepService } from "../../services/summary-step.service";

@Component({
  selector: 'app-summary-optional-claims-box',
  templateUrl: './summary-optional-claims-box.component.html',
  styleUrls: ['./summary-optional-claims-box.component.scss']
})
export class SummaryOptionalClaimsBoxComponent {
  readonly FormTypeEnum = FormTypeEnum;
  loading = false;

  get interestAmountLabel(): string {
    const amount = this.summaryStepService.summaryStepData.potential_interest_amount.toLocaleString('hu-HU');
    return `Törvényes kamatok (${amount} HUF)`;
  }

  get euroLabel(): string {
    const hufAmount = this.summaryStepService.summaryStepData.forty_eur_claims_in_huf.toLocaleString('hu-HU');
    return `Törvény szerinti 40 EUR behajtási költségátalány (${hufAmount} HUF)`;
  }

  get startCaseFeeLabel(): string {
    const amount = this.summaryStepService.summaryStepData.fees.start
      .find(fee => fee.label === 'FMH ügyindítási díj')?.amount
      .toLocaleString('hu-HU');
    return `FMH ügyindítási díj (${amount} HUF)`;
  }

  get administrativeFeeLabel(): string {
    const amount = this.summaryStepService.summaryStepData.fees.start
      .find(fee => fee.label === 'FMH közjegyzői díj')?.amount
      .toLocaleString('hu-HU');
    return `FMH közjegyzői díj (${amount} HUF)`;
  }

  constructor(
      public summaryStepService: SummaryStepService,
  ) { }

  async onInterestClick(b: boolean) {
    try {
      this.loading = true;
      this.summaryStepService.summaryStepData.is_added_interest_claim = b;
      await this.summaryStepService.saveSummaryStepData();
    } finally {
      this.loading = false;
    }
  }

  async onFortyEurosClick(b: boolean) {
    try {
      this.loading = true;
      this.summaryStepService.summaryStepData.is_added_flat_rate_cost_claim = b;
      await this.summaryStepService.saveSummaryStepData();
    } finally {
      this.loading = false;
    }
  }
}
