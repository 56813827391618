<button [class.disabled] = "disabled || loading" (click)="onButtonClick()">
  <!--static-->
  <div *ngIf="!loading" class="content-wrapper">
    <div class="text">
      {{text}}
    </div>
    <div *ngIf="arrow" class="arrow-icon-wrapper">
      <payee-icon [name]="'arrow-right-white'"></payee-icon>
    </div>
  </div>

  <!--loading-->
  <div *ngIf="loading" class="content-wrapper">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
  </div>
</button>
