<app-header-app xLink="/signup">
  <div class="flex gdpr-box">
    <payee-icon name="gdpr" height="40px" width="auto"></payee-icon>
  </div>
</app-header-app>
<app-form-template title="{{ 'VERIFY_TOKEN_EXPIRED.TITLE' | translate }}">
  <div class="description">
    <span>{{ 'VERIFY_TOKEN_EXPIRED.DESCRIPTION' | translate }}</span>
  </div>
  <payee-horizontal-line></payee-horizontal-line>
  <div class="flex resend-link-wrapper">
    <span color="grey-dark">{{ 'VERIFY_TOKEN_EXPIRED.QUESTION' | translate }}</span>&nbsp;
    <a color="blue-payee" (click)="resendVerifyEmail()">{{ 'VERIFY_TOKEN_EXPIRED.RESEND_LINK_TEXT' | translate }}</a>
  </div>
</app-form-template>
