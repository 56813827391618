import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-twofa-form',
  templateUrl: './twofa-form.component.html',
  styleUrls: ['./twofa-form.component.scss']
})
export class TwofaFormComponent {
  readonly twofaForm: FormGroup;

  loading = false;
  resendLoading = false;

  get code(): FormControl { return this.twofaForm.get('code') as FormControl; }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.twofaForm = this.fb.group({
      code: ['', Validators.required],
    });
  }

  readonly submitToken = async (): Promise<void> => {
    try {
      if (this.twofaForm.invalid) {
        return;
      }

      this.resendLoading = true;

      await this.authService.submitMFACode(this.code.value);
    } finally {
      this.resendLoading = false;
    }
  }

  async resendCode(): Promise<void> {
    try {
      this.resendLoading = true;

      await this.authService.resendMFACode();
    } finally {
      this.resendLoading = false;
    }
  }
}
