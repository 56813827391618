import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ClientsService, SelectedClient } from 'src/app/services/clients/clients.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-new-client-success',
  templateUrl: './add-new-client-success.component.html',
  styleUrls: ['./add-new-client-success.component.scss']
})
export class AddNewClientSuccessComponent implements AfterViewInit, OnDestroy {
  client: SelectedClient;
  isFirstClient = false;

  private readonly clientsSubscription: Subscription;
  get clientName() { return this.client.name; }

  constructor(
    private titleService: Title,
    private clientsService: ClientsService,
  ) {
    this.titleService.setTitle('Új Hitelezői fiók hozzáadása - Payee');

    this.clientsSubscription = this.clientsService.selectedClientSubject
      .subscribe({
        next: client => this.client = client,
      });
  }

  async ngAfterViewInit() {
    this.isFirstClient = this.clientsService.clientsSubject.value.length === 1;
  }

  ngOnDestroy(): void {
    this.clientsSubscription.unsubscribe();
  }
}
