import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-accept-tos-dialog',
  templateUrl: './accept-tos-dialog.component.html',
  styleUrls: ['./accept-tos-dialog.component.scss']
})
export class AcceptTosDialogComponent {
  formGroup: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      aszf: [false, [Validators.requiredTrue]],
      pp: [false, [Validators.requiredTrue]],
    });
  }

  get aszf() { return this.formGroup.get('aszf'); }
  get pp() { return this.formGroup.get('pp'); }

  async onClose() {
    if (this.formGroup.invalid) {
      return;
    }
  }
}
