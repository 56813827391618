<payee-progress-bar
  [(selectedIndex)]="selectedIndex"
  [linear]="true"
  [xLink]="leaveLink"
>
  <ng-template #headerLogoTemplate>
    <img
      src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg"
      width="100px"
    />
  </ng-template>

  <ng-template #headerButtonTemplate>
    <button
      payee-icon-button
      color="white"
      [routerLink]="leaveLink"
      type="button"
    >
      <payee-icon name="cross-black"></payee-icon>
    </button>
  </ng-template>

  <ng-template #nextButton let-index>
    <button
      payee-button
      payee-progress-bar-next
      width="wide"
      height="tall"
      type="button"
    >
      Folytatás
    </button>
  </ng-template>

  <payee-step
    label="Alapadatok"
    [formGroup]="!isClientInd ? orgBasicsForm : indBasicsForm"
  >
    <ng-template #nameInputTemplate>
      <payee-company-search-input-field
        width="400px"
        height="46px"
        [name]="name.value"
        [canBeInd]="false"
        [canBeHouse]="false"
        (individualSelected)="fillWithIndValues($event)"
        (houseSelected)="fillWithCompanyValues($event)"
        (companySearchItemSelected)="fillWithCompanyValues($event)"
      >
        <ng-container payee-error *ngIf="name.dirty">
          <div *ngIf="name.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-company-search-input-field>
    </ng-template>

    <ng-template #basicsTitleTemplate>
      <div class="title-box flex vcenter column">
        <h1>Ki a hitelező?</h1>
        <!-- TODO: tooltip -->

        <payee-info *ngIf="isSzamlazzhu" class="szamlazzhu-warning">
          Felhívjuk a figyelmed, hogy most a Payee-n belül fogsz létrehozni egy
          új hitelezői fiókot, az adatokat nem fogjuk előtölteni a
          Számlázz.hu-ból, és nem is fogjuk átvezetni oda ezt a fiókot.
        </payee-info>
      </div>
    </ng-template>

    <app-form-template *ngIf="!isClientInd" [formGroup]="orgBasicsForm">
      <ng-template #titleTemplate>
        <ng-container [ngTemplateOutlet]="basicsTitleTemplate"></ng-container>
      </ng-template>
      <ng-container [ngTemplateOutlet]="nameInputTemplate"></ng-container>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Adószám</div>
        <input payee-input formControlName="taxNumber" />
        <ng-container payee-error *ngIf="taxNumber.dirty">
          <div *ngIf="taxNumber.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Nyilvántartási szám</div>
        <input payee-input formControlName="registrationNumber" />
        <ng-container payee-error *ngIf="registrationNumber.dirty">
          <div *ngIf="registrationNumber.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <app-form-template
      *ngIf="isClientInd && indBasicsForm"
      [formGroup]="indBasicsForm"
    >
      <ng-template #titleTemplate>
        <ng-container [ngTemplateOutlet]="basicsTitleTemplate"></ng-container>
      </ng-template>
      <ng-container [ngTemplateOutlet]="nameInputTemplate"></ng-container>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Édesanyád neve</div>
        <input payee-input formControlName="mothersName" />
        <ng-container payee-error *ngIf="mothersName.dirty">
          <div *ngIf="mothersName.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Születési helyed</div>
        <input payee-input formControlName="birthPlace" />
        <ng-container payee-error *ngIf="birthPlace.dirty">
          <div *ngIf="birthPlace.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Születési időd</div>
        <ng-container payee-error *ngIf="birthDate.dirty">
          <div *ngIf="birthDate.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
        <payee-datepicker formControlName="birthDate"> </payee-datepicker>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step label="Cím" [formGroup]="addressForm">
    <app-form-template [formGroup]="addressForm">
      <ng-template #titleTemplate>
        <h1 *ngIf="!isClientInd">{{ clientName }} székhelye</h1>
        <!-- TODO: tooltip -->
        <h1 *ngIf="isClientInd">{{ clientName }} lakcíme</h1>
        <!-- TODO: tooltip -->
      </ng-template>

      <payee-dropdown
        formControlName="country"
        height="tall"
        width="wide"
        [options]="countries"
      >
        <ng-template #menuDisplayTemplate let-selected>
          <div class="flex vcenter" *ngIf="selected">
            <span class="grow1">{{ selected.label }}</span>
            <img [src]="selected.iconUrl" alt="" width="25" height="25" />
          </div>
        </ng-template>
        <ng-template #itemDisplayTemplate let-option>
          <ng-container
            [ngTemplateOutlet]="menuDisplayTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        </ng-template>

        <div payee-label color="grey-dark">Ország</div>
        <ng-container payee-error *ngIf="country.dirty">
          <div *ngIf="country.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-dropdown>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Irányítószám</div>
        <input
          payee-input
          formControlName="postcode"
          #addresstext
          placeholder=""
        />
        <ng-container payee-error *ngIf="postcode.dirty">
          <div *ngIf="postcode.hasError('required')">
            A mező kitöltése kötelező!
          </div>
          <div *ngIf="postcode.hasError('pattern')">Helytelen formátum!</div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Település</div>
        <input payee-input formControlName="settlement" />
        <ng-container payee-error *ngIf="settlement.dirty">
          <div *ngIf="settlement.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Utca és házszám</div>
        <input payee-input formControlName="street" />
        <ng-container payee-error *ngIf="street.dirty">
          <div *ngIf="street.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step label="Bankszámla" [formGroup]="bankAccountForm">
    <app-form-template [formGroup]="bankAccountForm">
      <ng-template #titleTemplate>
        <h1>Bankszámla adatai</h1>
        <!-- TODO: tooltip -->
      </ng-template>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Kedvezményezett neve</div>
        <input payee-input formControlName="beneficiaryName" />
        <ng-container payee-error *ngIf="beneficiaryName.dirty">
          <div *ngIf="beneficiaryName.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Bankszámlaszám / IBAN</div>
        <input
          payee-input
          formControlName="bankAccount"
          (input)="formatBankAccount(bankAccount)"
        />
        <ng-container payee-error *ngIf="bankAccount.dirty">
          <div *ngIf="bankAccount.hasError('required')">
            A mező kitöltése kötelező!
          </div>
          <div *ngIf="bankAccount.hasError('pattern')">Helytelen formátum!</div>
        </ng-container>
      </payee-form-field>

      <payee-form-field height="tall" width="wide">
        <div payee-label color="grey-dark">Számlavezető bank neve</div>
        <input payee-input formControlName="bankName" />
        <ng-container payee-error *ngIf="bankName.dirty">
          <div *ngIf="bankName.hasError('required')">
            A mező kitöltése kötelező!
          </div>
        </ng-container>
      </payee-form-field>
    </app-form-template>

    <ng-container [ngTemplateOutlet]="nextButton"></ng-container>
  </payee-step>

  <payee-step label="Kommunikáció" [formGroup]="toneOfVoiceForm">
    <app-form-template
      (submit)="onSubmit()"
      class="tov-form"
      [formGroup]="toneOfVoiceForm"
      title="Hogyan kommunikáljon a Payee?"
    >
      <payee-info width="wide">
        Ezt később <strong>bármikor</strong> módosíthatod.
      </payee-info>

      <!--tone of voice-->
      <div>
        <div class="dropdown-title-container">
          <h2 class="dropdown-title">Hangnem</h2>
        </div>
        <div class="description">
          Kiválaszthatod, hogy
          <strong>{{ clientName }} hitelezői fiókodból</strong> indított
          fizetési felszólítások
          <strong>(email és SMS) milyen hangneműek</strong> legyenek, azaz, hogy
          hogyan akarsz kommunikálni a vevőiddel.
        </div>
        <payee-dropdown
          class="dropdown"
          [options]="tovs"
          [displayWith]="tovDisplayWith"
          formControlName="toneOfVoice"
          height="tall"
          width="wide"
        >
          <ng-template #menuDisplayTemplate let-option>
            <span class="bold">{{ tovDisplayWith(option) }}</span>
            <payee-icon
              *ngIf="toneOfVoice.value.value === 'friendly'"
              class="tooltip"
              [name]="'questionmark-grey-circle'"
              matTooltip="Kedves, barátságos, magázódó hangnem.
                                    Inkább egy erősebb figyelmeztetés, mint felszólítás."
              matTooltipPosition="right"
              matTooltipClass="custom-tooltip"
            >
            </payee-icon>
            <payee-icon
              *ngIf="toneOfVoice.value.value === 'formal'"
              class="tooltip"
              [name]="'questionmark-grey-circle'"
              matTooltip="Keményebb, magázódó hangnem,
                                    ahol már határozottan felszólítjuk az adóst,
                                    hogy amennyiben nem fizet, a jogi eljárás haladéktalanul elindul."
              matTooltipPosition="right"
              matTooltipClass="custom-tooltip"
            >
            </payee-icon>
          </ng-template>
          <ng-template #itemDisplayTemplate let-option>
            <span class="bold">{{ tovDisplayWith(option) }}</span>
          </ng-template>
          <ng-container payee-error *ngIf="toneOfVoice.dirty">
            <div *ngIf="toneOfVoice.hasError('required')">
              A mező kitöltése kötelező!
            </div>
          </ng-container>
        </payee-dropdown>
      </div>

      <!--credit card payment-->
      <div>
        <div class="dropdown-title-container">
          <h2 class="dropdown-title">Bankkártyás fizetés</h2>
        </div>
        <div class="description">
          Ha adós bankkártyával fizet, akkor
          <a
            class="transaction-fee-link"
            href="https://help.payee.tech/hu/articles/6460278-milyen-dijakat-kell-fizetni-a-szolgaltatas-hasznalata-soran"
            target="_blank"
          >
            tranzakciós díjat
          </a>
          számolunk fel, amit havonta, utólag számlázunk ki a Hitelező fiókodra.
        </div>
        <payee-dropdown
          [options]="creditCardOptions"
          [displayWith]="creditCardDisplayWith"
          formControlName="creditCardPayment"
          height="tall"
          width="wide"
        >
          <ng-template #menuDisplayTemplate let-option>
            <span class="bold">{{ creditCardDisplayWith(option) }}</span>
          </ng-template>
          <ng-template #itemDisplayTemplate let-option>
            <span class="bold">{{ creditCardDisplayWith(option) }}</span>
          </ng-template>
          <ng-container payee-error *ngIf="creditCardPayment.dirty">
            <div *ngIf="creditCardPayment.hasError('required')">
              A mező kitöltése kötelező!
            </div>
          </ng-container>
        </payee-dropdown>
      </div>

      <button
        payee-button
        class="finish-button"
        width="wide"
        [disabled]="clientForms.invalid || createClientLoading"
      >
        <ng-container *ngIf="!createClientLoading"> Befejezés </ng-container>
        <div class="flex hcenter vcenter bw-theme" *ngIf="createClientLoading">
          <mat-progress-spinner
            color="accent"
            mode="indeterminate"
            diameter="30"
          >
          </mat-progress-spinner>
        </div>
      </button>
    </app-form-template>
  </payee-step>

  <button
    *ngIf="selectedIndex > 0"
    class="prev-button"
    payee-button
    payee-progress-bar-prev
    width="shorter"
    type="button"
    color="white"
  >
    <div class="flex hcenter vcenter">
      <img src="assets/shared/global-images/icons/arrow-left-grey.svg" />
      <span color="grey-dark">Vissza</span>
    </div>
  </button>
  <button
    *ngIf="selectedIndex === 0"
    class="prev-button"
    payee-button
    width="shorter"
    type="button"
    color="white"
    [routerLink]="leaveLink"
  >
    <div class="flex hcenter vcenter">
      <img src="assets/shared/global-images/icons/arrow-left-grey.svg" />
      <span color="grey-dark">Vissza</span>
    </div>
  </button>
</payee-progress-bar>
