import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CasesService } from 'src/app/services/cases/cases.service';
import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';

@Component({
  selector: 'app-claims-rerouter',
  templateUrl: './claims-rerouter.component.html',
  styleUrls: ['./claims-rerouter.component.scss']
})
export class ClaimsRerouterComponent implements OnInit {

  constructor(
    private casesService: CasesService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    const claimToken = this.route.snapshot.params.claimToken;
    try {
      const { payee_case_id } = await this.casesService.getCaseByClaimToken(claimToken);
      return this.router.navigateByUrl(`case/${payee_case_id}`);
    } catch (error) {
      console.error(error);

      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          message: this.translate.instant('ERROR_MESSAGES.ERROR_WHILE_LOADING_CASE'),
          action: this.translate.instant('OK'),
        },
        duration: 10000,
      });
      return this.router.navigateByUrl('/login');
    }
  }

}
