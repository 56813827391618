import { Component, Input } from '@angular/core';
import { ClaimFormService } from '../../../../services/form-services/claim-form-service';
import { environment } from '../../../../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../../../../../../snackbars/error-snackbar/error-snackbar.component';
import { InitStepService } from '../../../../services/init-step.service';

@Component({
  selector: 'app-claim-evidence-upload',
  templateUrl: './claim-evidence-upload.component.html',
  styleUrls: ['./claim-evidence-upload.component.scss']
})
export class ClaimFileUploadComponent {
  @Input() selectedClaimIndex: number = 0;

  get isFileDeletable() {
    return !this.initStepService.isSzamlazzHu;
  }

  constructor(
    public claimFormService: ClaimFormService,
    private snackBar: MatSnackBar,
    private initStepService: InitStepService,
  ) {
  }

  filesSelected(fileList: FileList) {
    for (let i = 0; i < fileList.length; ++i) {
      if (fileList.item(i).size <= 100 * 1024 * 1024) { // 10 MB
        this.claimFormService.getClaimFileIdFormControl(this.selectedClaimIndex).patchValue(null);
        this.claimFormService.getClaimFileNameFormControl(this.selectedClaimIndex).patchValue(fileList.item(i).name);
        this.claimFormService.getClaimFileFormControl(this.selectedClaimIndex).patchValue(fileList.item(i));
      } else {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          data: {
            message: 'A fájl meghaladta a 100 MB-os mérethatárt.',
            action: 'Ok',
          },
        });
      }
    }

    this.claimFormService.updateFormControlDisability();
  }

  deleteClaimFile() {
    this.claimFormService.getClaimFileIdFormControl(this.selectedClaimIndex).patchValue(null);
    this.claimFormService.getClaimFileNameFormControl(this.selectedClaimIndex).patchValue('');
    this.claimFormService.getClaimFileFormControl(this.selectedClaimIndex).patchValue(null);

    this.claimFormService.updateFormControlDisability();
  }

  readonly environment = environment;
}
