<table
  *ngIf="showTable"
  mat-table
  matSort
  matSortDirection="desc"
  matSortActive="due_date_at"
  [matSortDisableClear]="true"
  (matSortChange)="onSort($event)"
  [dataSource]="dataSource"
  multiTemplateDataRows
>
  <ng-container matColumnDef="select-all-button">
    <th
      class="select-all-header-cell"
      mat-header-cell
      *matHeaderCellDef
    >
      <div class="checkbox-wrapper">
        <payee-icon
          *ngIf="invoiceFormService.allSelected"
          [name]="environment.CHECKBOX_FILLED_ICON"
        >
        </payee-icon>
        <payee-icon
          *ngIf="!invoiceFormService.allSelected"
          [name]="'checkbox-empty'"
        >
        </payee-icon>
      </div>
    </th>
  </ng-container>

  <ng-container matColumnDef="select-all-title">
    <th
      class="select-all-header-cell"
      mat-header-cell
      *matHeaderCellDef
      [attr.colspan]="displayedColumns.length"
    >
      <div class="all-selected-item">
        Összes kijelölése
      </div>
    </th>
  </ng-container>

  <ng-container matColumnDef="selected">
    <th
      class="table-header-cell"
      mat-header-cell
      *matHeaderCellDef
    ></th>
    <td
      mat-cell
      *matCellDef="let element;"
    >
      <div class="checkbox-wrapper">
        <payee-icon
          *ngIf="invoiceFormService.getInvoiceFormControl(element)?.value.selected"
          [name]="environment.CHECKBOX_FILLED_ICON"
        >
        </payee-icon>
        <payee-icon
          *ngIf="!invoiceFormService.getInvoiceFormControl(element)?.value.selected"
          [name]="'checkbox-empty'"
        >
        </payee-icon>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoice_number">
    <th
      class="table-header-cell"
      mat-header-cell
      mat-sort-header
      mat-header
      *matHeaderCellDef
    >
      Számla sorszáma
    </th>
    <td
      mat-cell
      *matCellDef="let element"
    >
      <span>{{element.invoice.invoice_number}}</span>
      <span *ngIf="element.invoice.type !== 'SZ' && invoiceLabels[element.invoice.type]">&nbsp;({{ invoiceLabels[element.invoice.type] }})</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="due_date_at">
    <th
      class="table-header-cell"
      mat-header-cell
      mat-sort-header
      mat-header
      *matHeaderCellDef
    >Lejárat</th>
    <td
      mat-cell
      *matCellDef="let element"
    >
      Lejárat: {{element.invoice.due_date_at | date: 'yyyy.MM.dd.'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="remaining_amount">
    <th
      class="table-header-cell"
      mat-header-cell
      mat-sort-header
      mat-header
      *matHeaderCellDef
    >
      Összeg
    </th>
    <td
      mat-cell
      *matCellDef="let element"
    >
      {{element.invoice.remaining_amount | number:'1.0':'hu'}} {{element.invoice.currency_iso}}
    </td>
  </ng-container>

  <ng-container matColumnDef="corrections">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div class="flex column corrections-box">
        <table>
          <tr>
            <td class="correction-select"></td>
            <td colspan="3">Számla történet:</td>
          </tr>
          <tr *ngFor="let correction of element.corrections; trackBy: correctionTrackBy(element)">
            <td
              class="correction-id"
              colspan="3"
            >
              <span>{{correction.invoice_number}}</span>
              <span *ngIf="invoiceLabels[correction.type]">&nbsp;({{invoiceLabels[correction.type]}})</span>
            </td>
          </tr>
        </table>
      </div>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="table-header"
  ></tr>
  <tr
    mat-header-row
    *matHeaderRowDef="['select-all-button', 'select-all-title']"
    class="select-all-header"
    (click)="onAllSelected(!invoiceFormService.allSelected)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns;"
    (click)="invoiceFormService.toggleInvoice(row)"
    class="invoice-row"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: ['corrections'];"
    [class.hide-row]="element.corrections.length === 0"
    class="corrections-row"
  ></tr>
</table>

<div
  *ngIf="!showTable"
  class="top-margin-30"
>
  Jelenleg nincs több <a
    [href]="szamlazzhuUrl"
    color="blue-king"
  ><b>Számlázz.hu</b></a>-ban kiállított számlád, amire ügyet tudsz indítani.
</div>

<div
  *ngIf="invoiceFormService.form.hasError('noInvoiceSelected')"
  class="top-margin-10"
>
  <mat-error custom-error>
    Legalább egy számla kiválasztása kötelező!
  </mat-error>
</div>

