import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

import { ClientsService } from '../clients/clients.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DiscardCaseDialogComponent } from 'src/app/dialogs/case/discard-case-dialog/discard-case-dialog.component';
import { FormTypeEnum } from 'src/app/aa-new-form/form-steps/init-step/models/common/form-type-enum';
import { ProductsService } from '../products/products.service';

// Typescript hack for GTM
declare global {
  interface Window { dataLayer: any[]; }
}

export interface QuickHardColGuestUploadUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export type CreateCaseParams = {
  product_type: string;
};

export type CreateCaseResponse = {
  id: string;
};

export type GetCasesParams = {
  debtor_name?: string;
  payee_case_reference_id?: string;
  stage?: 'draft' | 'ongoing' | 'closed';
  page?: number;
  per_page?: number;
};

export type CasesTableData = {
  id: string;
  payee_case_reference_id: string;
  parties: {
    debtor_name: string | null;
    client_name: string;
  };
  state: {
    stage: string;
    state_label_text_color: string;
    state_label_background_color: string;
    state_label: string;
    stage_label: string;
  };
  partner: {
    integration: string;
    name: string;
  } | null;
  claim: {
    remaining_amount: number;
    original_amount: number;
    currency: string;
  };
  actions: {
    id: string;
    label: string;
    dialog: string;
  }[];
  is_deletable: boolean;
  is_editable: boolean;
};

export type CasesTableHeader = {
  title: string;
  align: 'center' | 'start';
  key?: keyof CasesTableData;
};

export type GetCasesResponse = {
  headers: CasesTableHeader[];
  // TODO
  sortBy: never[];
  data: CasesTableData[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

export type PaymentStatus =
  'Prepared'
  | 'Started'
  | 'InProgress'
  | 'Waiting'
  | 'Reserved'
  | 'Authorized'
  | 'Canceled'
  | 'Succeeded'
  | 'Failed'
  | 'PartiallySucceeded'
  | 'Expired';

export type GetCardPaymentStatus = {
  total: number;
  currency_iso: string;
  status: PaymentStatus;
};

export type GetCaseByTrackingIdResponse = {
  payee_case_id: string;
  payee_case_reference_id: string;
};

export type StartSoftSuccessCaseResponse = {
  id: string;
  payee_case_reference_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  constructor(
    private productsService: ProductsService,
    private clientsService: ClientsService,
    private dialog: MatDialog,
  ) { }

  async getCases(params: GetCasesParams, clientId = this.clientsService.selectedClientId): Promise<GetCasesResponse> {
    if (!clientId) {
      throw new Error('Client id not set');
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case`;
    const result = await axios.get<GetCasesResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async getCaseByClaimToken(trackingId: string): Promise<GetCaseByTrackingIdResponse> {
    const url = `${environment.baseUrl}/api/claim-by-tracking-id/${trackingId}`;
    const result = await axios.get<GetCaseByTrackingIdResponse>(url);
    return result.data;
  }

  async createCase(params: CreateCaseParams, clientId = this.clientsService.selectedClientId): Promise<CreateCaseResponse> {
    if (!clientId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case`;
    const result = await axios.post<CreateCaseResponse>(url, params);
    this.clientsService.setClients();
    return result.data;
  }

  openDeleteCaseDialog(id: string): MatDialogRef<DiscardCaseDialogComponent> {
    return this.dialog.open(DiscardCaseDialogComponent, {
      data: {
        id,
      },
    });
  }

  async deleteCase(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/payee-case/${caseId}`;
    await axios.delete(url);
  }

  async getCase(caseId: string, clientId = this.clientsService.selectedClientId): Promise<unknown> {
    if (!caseId) {
      throw new Error('Case id missing in getCaseForm');
    }
    const url = `${environment.baseUrl}/api/client/${clientId}/case/${caseId}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getCaseForm(caseId: string, step: 'init' | 'summary' | 'payment'): Promise<unknown> {
    if (!caseId) {
      throw new Error('Case id missing in getCaseForm');
    }
    const url = `${environment.baseUrl}/api/payee-case/${caseId}/${step}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getCardPaymentStatus(paymentId: string, caseId: string, clientId = this.clientsService.selectedClientId): Promise<GetCardPaymentStatus> {
    if (!clientId || !caseId || !paymentId) {
      throw new Error('getCardPaymentStatus - Some parameters are missing');
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case/${caseId}/get-card-payment-status/${paymentId}`;
    const response = await axios.get<GetCardPaymentStatus>(url);
    return response.data;
  }

  async startWithCardPayment(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case/${caseId}/start-with-card-payment`;
    const response = await axios.get<string>(url);
    window.location.href = response.data;
  }

  async startWithTransfer(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case/${caseId}/start-with-transfer`;
    const response = await axios.post<string>(url);
    console.log(response.data);
  }

  async changeProduct(caseId: string, productId: number): Promise<void> {
    if (!caseId || !productId) {
      return;
    }

    const url = environment.baseUrl + `/api/payee-case/${caseId}/change-product/${productId}`;
    await axios.post(url, {
      '_method': 'PUT',
    });
  }

  async startSoftSuccessCase(caseId: string): Promise<StartSoftSuccessCaseResponse> {
    if (!caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/payee-case/${caseId}/start-soft-success`;
    const result = await axios.post<StartSoftSuccessCaseResponse>(url);
    this.caseOpenedGtmEvent(FormTypeEnum.SOFT, result.data.payee_case_reference_id);
    return result.data;
  }

  caseOpenedGtmEvent(formType: FormTypeEnum, id: string): void {
    window.dataLayer = window.dataLayer || [];
    
    const event: any = {
      event: 'case_opened',
      is_production: environment.production,
      case_id: id,
      case: this.productsService.productLabels[formType],
    };
    console.log('Sending PPC Event', event);
    window.dataLayer.push(event);
  }
}
