import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormService } from '../../services/form-services/form-service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-form-data-step',
  templateUrl: './form-data-step.component.html',
  styleUrls: ['./form-data-step.component.scss']
})
export class FormDataStepComponent {
  @Input() formService: FormService;

  @Output() openStepButtonClick =  new EventEmitter<string>();

  isOpen = false;

  constructor() {
  }

  onButtonClick(): void {
    this.formService.setData();
    this.openStepButtonClick.emit();
  }

  readonly environment = environment;
}
