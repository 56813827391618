import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-get-password-link',
  templateUrl: './get-password-link.component.html',
  styleUrls: ['./get-password-link.component.scss']
})
export class GetPasswordLinkComponent implements OnInit, OnDestroy {
  readonly forgotPasswordForm: FormGroup;
  private readonly destroy = new Subject<void>();

  loading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private titleService: Title,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() { return this.forgotPasswordForm.get('email'); }

  ngOnInit(): void {
    this.translate.get('BROWSER_TITLES.PASSWORD_RESET')
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: v => this.titleService.setTitle(v),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  readonly sendEmail = async (): Promise<void> => {
    if (this.forgotPasswordForm.invalid || this.loading) {
      return;
    }

    try {
      this.loading = true;

      await this.authService.sendPasswordResetEmail({
        email: this.email.value,
      });

      this.router.navigate(['/forgot-password-get-link-success'], {
        queryParams: {
          email: this.email.value,
        },
      });
    } finally {
      this.loading = false;
    }
  };
}
