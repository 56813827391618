<div>
  <!--country-->
  <div>
    <payee-dropdown [formControl]="countryFormControl" height="tall" width="widest">
      <!--styling-->
      <ng-template #menuDisplayTemplate let-selected>
        <div class="flex vcenter" *ngIf="selected">
          <span class="grow1">{{selected}}</span>
          <payee-icon class="flag-margin" [name]="'hu-flag-circle'"></payee-icon>
        </div>
      </ng-template>
      <!--error messages-->
      <ng-container payee-error *ngIf="countryFormControl.dirty">
        <div *ngIf="countryFormControl.hasError('required')">A mező kitöltése kötelező!</div>
      </ng-container>
    </payee-dropdown>
  </div>

  <!--post code-->
  <div class="top-margin-10">
    <payee-form-field height="tall" width="widest">
      <!--input-->
      <input #addresstext payee-input [formControl]="postcodeFormControl" [placeholder]="'Irányítószám'" (ngModelChange)="formatPostcode()">
      <!--error messages-->
      <ng-container payee-error *ngIf="postcodeFormControl?.dirty">
        <div *ngIf="postcodeFormControl.hasError('required')">A mező kitöltése kötelező!</div>
        <div *ngIf="postcodeFormControl.hasError('pattern')">Helytelen formátum!</div>
      </ng-container>
    </payee-form-field>
  </div>

  <!--city-->
  <div class="top-margin-10">
    <payee-form-field height="tall" width="widest">
      <!--input-->
      <input payee-input [formControl]="cityFormControl" [placeholder]="'Település'" (ngModelChange)="formatCity()">
      <!--error messages-->
      <ng-container payee-error *ngIf="cityFormControl.dirty">
        <div *ngIf="cityFormControl.hasError('required')">A mező kitöltése kötelező!</div>
      </ng-container>
    </payee-form-field>
  </div>

  <!--street address-->
  <div class="top-margin-10">
    <payee-form-field height="tall" width="widest" class="top-margin-10">
      <!--input-->
      <input payee-input [formControl]="streetFormControl" [placeholder]="'Utca és házszám'">
      <!--error messages-->
      <ng-container payee-error *ngIf="streetFormControl.dirty">
        <div *ngIf="streetFormControl.hasError('required')">A mező kitöltése kötelező!</div>
      </ng-container>
    </payee-form-field>
  </div>
</div>
