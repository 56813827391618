import {FormService} from './form-service';
import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {HelpersService} from '../../../../../services/helpers/helpers.service';
import {MatDialog} from '@angular/material/dialog';
import {BankAccountDialogComponent} from '../../dialogs/bank-account-dialog/bank-account-dialog.component';
import {InitStepService} from "../init-step.service";
import {BankAccountStepData} from "../../models/bank-account/bank-account-step-data";
import {FormErrorSnackbarComponent} from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class BankAccountFormService extends FormService {
    bankAccountStepData: BankAccountStepData;

    get countryFormControl() { return this.form.get('countryFormControl') as FormControl; }
    get accountHolderNameFormControl() { return this.form.get('accountHolderNameFormControl') as FormControl; }
    get accountNumberFormControl() { return this.form.get('accountNumberFormControl') as FormControl; }

    constructor(
        public helpersService: HelpersService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private initStepService: InitStepService,
        private snackbar: MatSnackBar,
    ) {
        // set initial parent basic data
        super(helpersService, dialog);
        this.setText(
            'Bankadatok',
            'A hitelező számlája, ahova az adós utalhat'
        );
        this.urlName = 'payout-information';

        // set szamlazzhu specific data
        // this.showSzamlazzhuIcon = this.formHelperService.isSzamlazzhu;

        // set bank account step data
        this.bankAccountStepData = this.initStepService.bankAccountStepData;
        this.initStepService.getBankAccountStepDataListener()
            .subscribe((bankAccountStepData: BankAccountStepData) => {
                this.bankAccountStepData = bankAccountStepData;
            });
    }

    // override
    buildForm() {
        this.form = this.formBuilder.group({
            countryFormControl: [
                {
                    value: 'Magyarország',
                    disabled: true,
                },
                [Validators.required]
            ],
            accountHolderNameFormControl: [
                this.bankAccountStepData?.account_holder_name ?? '',
                [Validators.required]
            ],
            accountNumberFormControl: [
                this.helpersService.getBBANFormat(this.bankAccountStepData?.account_number) ?? '',
                [Validators.required, this.helpersService.BBANValidator]
            ],
        });
    }

    private updateData() {
        this.bankAccountStepData.account_holder_name = this.accountHolderNameFormControl.value ?? '';
        this.bankAccountStepData.account_number = this.accountNumberFormControl.value ?? '';
    }

    // override
    setMenuTexts() {
        this.dropdownTitle = `${this.helpersService.getBBANFormat(this.bankAccountStepData.account_number ?? '')}`;
        this.dropdownItems = [`${this.helpersService.getBBANFormat(this.bankAccountStepData.account_number ?? '')}`];
    }

    // override
    openDialog() {
        return this.dialog.open(BankAccountDialogComponent, {
            position: {
                top: '50px',
            },
            autoFocus: false,
            disableClose: true,
        });
    }

    // override
    async persistData() {
        this.updateData();
        try {
            await this.initStepService.saveBankAccountStepData();
        } catch (error) {
            console.error(error);
            this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        }

    }
}
