import { Injectable } from '@angular/core';

import { Debtor } from 'src/types';
import { ClientsService } from '../clients/clients.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export type GetDebtorsParams = {
  name?: string;
};

export type GetDebtorsResponse = {
  name: string;
}[];

@Injectable({
  providedIn: 'root'
})
export class DebtorsService {
  public isLoading = 0;

  constructor(
    private clientsService: ClientsService,
  ) { }

  async getDebtors(params: GetDebtorsParams, clientId = this.clientsService.selectedClientId): Promise<GetDebtorsResponse> {
    const url = environment.baseUrl + `/api/client/${clientId}/debtor`;
    const result = await axios.get<GetDebtorsResponse>(url, {
      params,
    });
    return result.data;
  }
}
