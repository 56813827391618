import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type StartWithTransferDialogData = {
  transfer_amount: number;
  currency_iso: string;
  payee_case_reference_id: string;
};

@Component({
  selector: 'app-start-with-transfer-dialog',
  templateUrl: './start-with-transfer-dialog.component.html',
  styleUrls: ['./start-with-transfer-dialog.component.scss']
})
export class StartWithTransferDialogComponent {
  readonly form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: StartWithTransferDialogData,
  ) {
    this.form = this.fb.group({
      transfer_amount: data.transfer_amount,
      currency_iso: data.currency_iso,
      account_holder_name: 'Legal Labs Kft.',
      account_number: '12600016-19259526-84463815',
      payee_case_reference_id: data.payee_case_reference_id,
    });
  }
}
