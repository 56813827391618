<!--country-->
<div>
  <payee-dropdown [formControl]="bankAccountFormService.countryFormControl" height="tall" width="widest">
    <!--styling-->
    <ng-template #menuDisplayTemplate let-selected>
      <div class="flex vcenter" *ngIf="selected">
        <span class="grow1">{{selected}}</span>
        <payee-icon class="flag-margin" [name]="'hu-flag-circle'"></payee-icon>
      </div>
    </ng-template>
    <!--error messages-->
    <ng-container payee-error *ngIf="bankAccountFormService.countryFormControl.dirty">
      <div *ngIf="bankAccountFormService.countryFormControl.hasError('required')">A mező kitöltése kötelező!</div>
    </ng-container>
  </payee-dropdown>
</div>

<!--account holder name-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <!--input-->
    <input payee-input [formControl]="bankAccountFormService.accountHolderNameFormControl" [placeholder]="'Kedvezményezett neve'">
    <!--error messages-->
    <ng-container payee-error *ngIf="bankAccountFormService.accountHolderNameFormControl?.dirty">
      <div *ngIf="bankAccountFormService.accountHolderNameFormControl.hasError('required')">A mező kitöltése kötelező!</div>
    </ng-container>
  </payee-form-field>
</div>

<!--bank account number-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <!--input-->
    <input payee-input [formControl]="bankAccountFormService.accountNumberFormControl" [placeholder]="'Bankszámlaszám'">
    <!--error messages-->
    <ng-container payee-error *ngIf="bankAccountFormService.accountNumberFormControl?.dirty">
      <div *ngIf="bankAccountFormService.accountNumberFormControl.hasError('required')">
        A mező kitöltése kötelező!
      </div>
      <div *ngIf="bankAccountFormService.accountNumberFormControl.hasError('pattern')
          && bankAccountFormService.accountNumberFormControl.value">
        Helytelen formátum!
      </div>
    </ng-container>
  </payee-form-field>
</div>
